(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BookingDialogController', BookingDialogController);

    BookingDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$translate', '$uibModalInstance', '$uibModal',
    'Booking', 'OpsShipper', 'OpsPorts', 'entity', 'DateUtils', 'OpsVender', 'DataUtils', 
    'Guide', 'GuideCtns', 'OpsReport'];

    function BookingDialogController($timeout, $scope, $rootScope, $translate, $uibModalInstance, $uibModal,
        Booking, OpsShipper, OpsPorts, entity, DateUtils, OpsVender, DataUtils,
       Guide, GuideCtns, OpsReport) {
        var vm = this;
        vm.delHbl = delHbl;
        $scope.isSaving = false;

        $scope.guide = angular.copy(entity.guide);
        var bookingCopy = angular.copy($scope.guide.booking);
        var guideCtnsCopy = angular.copy($scope.guide.guideCtns);
        $scope.bookings = [];
        $scope.guideCtns = [];

        var bBookingCopy, indexCopy;
        $timeout(function (){
            bookingCopy.id = null;
            bookingCopy.guide = null;
            bookingCopy.guideId = null;
            bookingCopy.bookingCustom = null;
            bookingCopy.bookingTrucks = null;
            bookingCopy.bookingCtns = null;
            bookingCopy.bookingPrices = null;
            bookingCopy.isLocked = false;
            bookingCopy.attachCtns = false;
            bookingCopy.attachMark = false;
            bookingCopy.attachDescription = false;
            bookingCopy.rating = null;
            bookingCopy.palletSize = null;
            bookingCopy.createdBy = null;
            bookingCopy.cusUserId = null;       
            bookingCopy.asWmsIn = null;     
            bookingCopy.asWmsOut = null;   
            bookingCopy.bookingToName = $scope.guide.sayCtns;
            bookingCopy.beneficiary = $scope.guide.woodenStatement;
            bookingCopy.refGuideId = $scope.guide.id;
            bookingCopy.hblNum = $scope.guide.hblNum;
            bookingCopy.bookingNum = $scope.guide.jobNum;
            bookingCopy.cargoValue = $scope.guide.payableAt;
            bookingCopy.importerName = $scope.guide.awbCharges;
            bookingCopy.shipperName = $scope.guide.issuePlace;
            bookingCopy.cneeName = $scope.guide.vsl;
            bookingCopy.notifyName = $scope.guide.voy;
            bookingCopy.remarks = $scope.guide.blAttachment;
            bookingCopy.remarkTwo = $scope.guide.goodsDiscription;
            bookingCopy.loadRemark = $scope.guide.mark;
            bookingCopy.bookingToEmail = $scope.guide.guideNotifyBl;
            bookingCopy.awbCharges = $scope.guide.deliveryBl;
            bookingCopy.comments = $scope.guide.guideShipperBl;           
            bookingCopy.bookingToMob = $scope.guide.porId;
            bookingCopy.bookingToAttn = $scope.guide.porName;      
            bookingCopy.bookingToTel = $scope.guide.deliverytoId;     
            bookingCopy.bookingToFax = $scope.guide.deliveryTo;     
            bookingCopy.raNum = $scope.guide.polId;
            bookingCopy.fbaRef = $scope.guide.polName;           
            bookingCopy.kcNum = $scope.guide.podId;
            bookingCopy.freeTime = $scope.guide.podName;      
            bookingCopy.ventilation = $scope.guide.destId;            
            bookingCopy.tradeTerm = $scope.guide.destName;   
            bookingCopy.soNum= $scope.guide.soNum;   
            bookingCopy.etd= $scope.guide.etd;   
            bookingCopy.shipmentType = $scope.guide.shipmentType;
            if(bookingCopy.shipmentType == "FBAAIR" || bookingCopy.shipmentType == "EXPRESS"){
                bookingCopy.shipmentType = "AIR";
            }
            else if(bookingCopy.shipmentType == "RAIL" || bookingCopy.shipmentType == "ITCT"){
                bookingCopy.shipmentType = "FCL";
            }
            else if(bookingCopy.shipmentType == "BULK" || bookingCopy.shipmentType == "WMS" || bookingCopy.shipmentType == "LOCAL" 
                || bookingCopy.shipmentType == "FBASEA" || bookingCopy.shipmentType == "FBARAIL" || bookingCopy.shipmentType == "LOCALKG"){
                bookingCopy.shipmentType = "LCL";
            }
            angular.forEach(guideCtnsCopy, function(ctn){
                ctn.id = null;
                ctn.guideId = null;
                ctn.truckerId = null;
                ctn.remarks = null;
                ctn.pickAddress = null;
                ctn.returnAddress = null;
                ctn.truckNum = null;
                ctn.driverName = null;
                ctn.driverMob = null;
                ctn.returnTime = null;
                ctn.weighWeight = null;
                ctn.smsSent = null;
                ctn.locked = null;
                ctn.vgm = null;
                ctn.companyId = null;
                ctn.createdBy = null;
                ctn.isTemplate = null;
                ctn.bookingNum = null;
            });
            if($rootScope.account && $rootScope.account.id){
                Booking.getHbls({refGuideId: $scope.guide.id}, function(result){
                    if (result && result.length > 0){
                        $scope.bookings = result;
                        bBookingCopy = angular.copy(result[0]);
                        initBookings();
                    }
                    else {
                        var booking = angular.copy(bookingCopy);
                        booking.guideCtns = angular.copy(guideCtnsCopy);
                        $scope.bookings.push(booking);
                        bBookingCopy = angular.copy(bookingCopy);
                        initBookings();
                    }
                })
            }
            else {
                Guide.getHbls({refGuideId: $scope.guide.id}, function(result){
                    if (result && result.length > 0){
                        $scope.bookings = result;
                        bBookingCopy = angular.copy(result[0]);
                        initBookings();
                    }
                    else {
                        var booking = angular.copy(bookingCopy);
                        booking.guideCtns = angular.copy(guideCtnsCopy);
                        $scope.bookings.push(booking);
                        bBookingCopy = angular.copy(bookingCopy);
                        initBookings();
                    }
                })
            }
            vm.activeTab = 0;
            indexCopy = 0;
        });

        var initGuideReportDto = function(booking, isSwb){
            booking = angular.copy(booking);
            
            if(isSwb){                
                booking.notifyBl = booking.alsoNotify;
                booking.cneeBl = booking.bookingToAddress;
                booking.shipperBl = booking.hsCode;
            }
            else {
                booking.alsoNotify = null;
                booking.bookingToAddress = null;
                booking.hsCode = null;
            }
            booking.hsCode = bookingCopy.hsCode;
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.chargeWeight = booking.chargeWeight;
            guideReportDTO.guideCtns = booking.guideCtns;
            guideReportDTO.booking = booking;
            guideReportDTO.hblNum = booking.hblNum;
            guideReportDTO.sayCtns = booking.bookingToName;
            guideReportDTO.woodenStatement = booking.beneficiary;
            guideReportDTO.etd = booking.etd;
            guideReportDTO.payableAt = booking.cargoValue;
            guideReportDTO.awbCharges = booking.importerName;
            guideReportDTO.issuePlace = booking.shipperName;
            guideReportDTO.serviceTerm = booking.serviceTerm;
            guideReportDTO.vsl = booking.cneeName;
            guideReportDTO.voy = booking.notifyName;
            guideReportDTO.blAttachment = booking.remarks;
            guideReportDTO.goodsDiscription = booking.remarkTwo;
            guideReportDTO.mark = booking.loadRemark;
            guideReportDTO.guideNotifyBl = booking.bookingToEmail;
            guideReportDTO.deliveryBl = booking.awbCharges;
            guideReportDTO.guideShipperBl = booking.comments;
            guideReportDTO.porId = booking.bookingToMob;
            guideReportDTO.porName = booking.bookingToAttn;
            guideReportDTO.polId = booking.raNum;
            guideReportDTO.polName = booking.fbaRef;
            guideReportDTO.podId = booking.kcNum;
            guideReportDTO.podName = booking.freeTime;
            guideReportDTO.destId = booking.ventilation;
            guideReportDTO.destName = booking.tradeTerm;
            booking.tradeTerm = null;
            guideReportDTO.deliverytoId = booking.bookingToTel;
            guideReportDTO.deliveryTo = booking.bookingToFax;
            guideReportDTO.pkgsAndType = booking.pkgsAndType;
            guideReportDTO.gwVol = booking.gwVol;
            guideReportDTO.gw = booking.gw;
            guideReportDTO.vol = booking.vol;
            guideReportDTO.pkgNum = booking.pkgNum;
            guideReportDTO.shipperId = booking.createdBy;
            guideReportDTO.shipperName = booking.palletSize;
            guideReportDTO.soNum = booking.soNum;
            guideReportDTO.poNum = booking.poNum;

            guideReportDTO.guideFclCtns = [];

            var guideCtns = angular.copy(booking.guideCtns);
            var ctnTypes = new Set();
            angular.forEach(guideCtns, function(data){
                if(data.ctnType){
                    ctnTypes.add(data.ctnType);
                }
            });
            var ctnCount = 0;
            ctnTypes.forEach(function(ctnType){
                ctnCount = 0;
                angular.forEach(guideCtns, function(data){
                    if(data.ctnType == ctnType){
                        ctnCount++;
                    }
                });
                var bookingCtn = {};
                bookingCtn.num = ctnCount;
                bookingCtn.ctnType = ctnType;
                guideReportDTO.guideFclCtns.push(bookingCtn);
            })
            return guideReportDTO;
        }

        var initBookings = function (){
            angular.forEach($scope.bookings, function(data){
                if (!data.bookingNum){
                   data.bookingNum = $scope.guide.jobNum;
                }
                if (!data.cargoValue){
                   data.cargoValue = $scope.guide.payableAt;
                }
                if (!data.shipperName){
                   data.shipperName = $scope.guide.issuePlace;
                }
                if (!data.cneeName){
                   data.cneeName = $scope.guide.vsl;
                }
                if (!data.notifyName){
                   data.notifyName = $scope.guide.voy;
                }
            });
            $scope.initGuideCtns();
        }
        
        $scope.initGuideCtns = function (){
            $scope.guideCtns = [];
            var ctnNums = new Set();
            angular.forEach(guideCtnsCopy, function(data){
                if(data.ctnNum){
                    ctnNums.add(data.ctnNum);
                }
            });
            var pkgs, vol, gw;
            ctnNums.forEach(function(ctnNum){
                pkgs = vol = gw = 0;
                angular.forEach($scope.bookings, function(booking) {
                    angular.forEach(booking.guideCtns, function(ctn) {
                        if(ctnNum == ctn.ctnNum){
                            if(ctn.pkgs){
                                pkgs += ctn.pkgs;
                            }
                            if(ctn.vol){
                                vol += ctn.vol;
                            }
                            if(ctn.gw){
                                gw += ctn.gw;
                            }
                        }
                    })
                });

                angular.forEach(guideCtnsCopy, function(data){
                    if(data.ctnNum == ctnNum){
                        if((data.pkgs && pkgs < data.pkgs) || (data.gw && gw < data.gw) || (data.vol && vol < data.vol)){
                            var newCtn = angular.copy(data);
                            if(data.pkgs){
                                newCtn.pkgs = data.pkgs - pkgs;
                            }
                            if(data.vol){
                                newCtn.vol = data.vol - vol;
                                newCtn.vol = newCtn.vol.toFixed(DataUtils.getVolPrecision($scope.guide)) - 0;
                            }
                            if(data.gw){
                                newCtn.gw = data.gw - gw;
                                newCtn.gw = newCtn.gw.toFixed(DataUtils.getGwPrecision($scope.guide)) - 0;
                            }
                            $scope.guideCtns.push(newCtn);
                        }
                        else {
                            var newCtn = angular.copy(data);
                            newCtn.pkgs = null;
                            newCtn.vol = null;
                            newCtn.gw = null;
                            $scope.guideCtns.push(newCtn);
                        }
                    }
                });
            })
        }        

        $scope.setActiveTab = function ($index, booking) {
            if (bBookingCopy && !angular.equals($scope.bookings[indexCopy], bBookingCopy)) {
                layer.msg($translate.instant('global.saveAlert'));
                vm.activeTab = indexCopy;
                return;
            };  
            vm.activeTab = $index;
            indexCopy = $index;
            bBookingCopy = angular.copy($scope.bookings[indexCopy]);
            $scope.opsShippers = [];
        }
        $scope.checkHblNum = function (booking, indexIn) {
            if (booking.hblNum == $scope.guide.hblNum){
               $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.hblNumRepeated'));
            }
            else {
                angular.forEach($scope.bookings, function(data, index){
                    if (booking.hblNum == data.hblNum && index != indexIn){
                       $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.hblNumRepeated'));
                    }
                });
            }
        }

        $scope.guideCtnAmtChange = function(booking) {
            if(booking.plConfirmed){
                return;
            }
            if (booking.guideCtns && booking.guideCtns.length > 0) {
                var newVolTotal = 0;
                for (var i = 0; i < booking.guideCtns.length; i++) {
                    if (booking.guideCtns[i].vol) {
                        newVolTotal = newVolTotal + booking.guideCtns[i].vol;
                    }
                }
                booking.vol = newVolTotal.toFixed(3) - 0;
                var newGwTotal = 0;
                for (var i = 0; i < booking.guideCtns.length; i++) {
                    if (booking.guideCtns[i].gw) {
                        newGwTotal = newGwTotal + booking.guideCtns[i].gw;
                    }
                }
                booking.gw = newGwTotal.toFixed(2) - 0;

                var newPkgsTotal = 0;
                for (var i = 0; i < booking.guideCtns.length; i++) {
                    if (booking.guideCtns[i].pkgs) {
                        newPkgsTotal = newPkgsTotal + booking.guideCtns[i].pkgs;
                    }
                }
                booking.pkgNum = newPkgsTotal;

                var pkgType = null;
                var isSamePkg = true;
                angular.forEach(booking.guideCtns, function(data) {
                    if(data.pkgType){
                        if(!pkgType){
                            pkgType = data.pkgType;
                        }
                        else if (pkgType != data.pkgType){
                            isSamePkg = false;
                        }
                    }
                });
                if (isSamePkg) {
                    booking.pkgType = pkgType;
                } else {
                    booking.pkgType = "PKGS";
                }
                $scope.updateGwVol(booking);
            }
        }

        $scope.addHbl = function () {
            if ($scope.guide.isClosed) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            $scope.initGuideCtns();
            var booking = angular.copy(bookingCopy);
            booking.guideCtns = angular.copy($scope.guideCtns);
            $scope.guideCtnAmtChange(booking);
            $scope.bookings.push(booking);
        }

        $scope.clear = function () {
            if (!angular.equals($scope.bookings[indexCopy], bBookingCopy)) {
                layer.confirm($translate.instant('global.notSavedAlert'), {
                    btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
                }, function(index) {
                    $uibModalInstance.dismiss();
                    layer.close(index);
                }, function() {});
                return;
            };  
            $uibModalInstance.dismiss($scope.bookings);            
        }

        $scope.save = function(booking) {
            if ($scope.guide.isClosed) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if(booking.bookingToEmail && booking.bookingToEmail.length > 255){
                booking.bookingToEmail = booking.bookingToEmail.substring(0, 255);
            }
            if(booking.ventilation && booking.ventilation.length > 6){
                booking.ventilation = booking.ventilation.substring(0, 6);
            }

            $scope.isSaving = true; 
            if($rootScope.account && $rootScope.account.id){
                Booking.save(booking, function(result) {
                    booking.id = result.id;
                    booking.guideCtns = result.guideCtns;
                    bBookingCopy = angular.copy(booking);
                    $scope.isSaving = false;
                    layer.msg($translate.instant('global.messages.submitted'));
                }, onSaveError);
            }   
            else {
                Guide.saveBooking(booking, function(result) {
                    booking.id = result.id;
                    booking.guideCtns = result.guideCtns;
                    bBookingCopy = angular.copy(booking);
                    $scope.isSaving = false;
                    layer.msg($translate.instant('global.messages.submitted'));
                }, onSaveError);
            }
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.messages.submittedfailed'));
        };

        if($rootScope.account && $rootScope.account.id){
            $rootScope.LOAD_REPORT_TEMPLATES();
        }
        else {
            if ($rootScope.REPORT_TEMPLATES || !$scope.guide.companyId) {
                return;
            }
            Guide.getAllReportTemplate({
                langKey: $translate.instant('global.enCn'),
                companyId: $scope.guide.companyId
            }, function(result) {
                $rootScope.REPORT_TEMPLATES = result;
            }, function(response) {
            });
        }       

        $scope.exportExcelPdf = function(format, code, fileName, booking, isSwb) {
            var guideReportDTO = initGuideReportDto(booking, isSwb);
            guideReportDTO.reportType = code;
            guideReportDTO.opType = 1;            
            guideReportDTO.fileType = format;
            guideReportDTO.fileName = fileName;
            if($rootScope.account && $rootScope.account.id){
                OpsReport.generateGuide({}, guideReportDTO, function(result) {
                    $rootScope.EXPORTDOWNLOAD(result, format, fileName);
                });
            }
            else {
                Guide.guideReport({}, guideReportDTO, function(result) {
                    $rootScope.EXPORTDOWNLOAD(result, format, fileName);
                });
            }
        }
        $scope.previewReport = function(code, booking, isSwb) {
            var guideReportDTO = initGuideReportDto(booking, isSwb);
            guideReportDTO.reportType = code;
            guideReportDTO.opType = 1;            
            guideReportDTO.fileType = 1;
            if($rootScope.account && $rootScope.account.id){
                OpsReport.generateGuide({}, guideReportDTO, function(result) {
                    $rootScope.PRINTBYTES(result.data);
                });
            }
            else {
                Guide.guideReport({}, guideReportDTO, function(result) {
                    $rootScope.PRINTBYTES(result.data);
                });
            }
        }
        $scope.datePickerForEtd = {};
        $scope.datePickerForEtd.status = {
            opened: false
        };
        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        
        $scope.portList = new Array();
        $scope.loadPorts = function() {
            if ($rootScope.OPSPORTS.airports && $rootScope.OPSPORTS.airports.length > 0){
                if ($scope.guide && ($scope.guide.shipmentType == "AIR" || $scope.guide.shipmentType == "EXPRESS" || $scope.guide.shipmentType == "FBAAIR")) {
                    $scope.portList = $rootScope.OPSPORTS.airports;         
                } else {
                    $scope.portList = $rootScope.OPSPORTS.seaports;
                }
                return;
            }
            if($rootScope.account && $rootScope.account.id){
                OpsPorts.getAll(function(result){
                    $rootScope.OPSPORTS.airports = [];
                    $rootScope.OPSPORTS.seaports = [];
                    angular.forEach(result, function(data){
                        if(data.type == 1){
                            $rootScope.OPSPORTS.seaports.push(data);
                        }
                        else {
                            $rootScope.OPSPORTS.airports.push(data);
                        }
                    })
                    if ($scope.guide && ($scope.guide.shipmentType == "AIR" || $scope.guide.shipmentType == "EXPRESS" || $scope.guide.shipmentType == "FBAAIR")) {
                        $scope.portList = $rootScope.OPSPORTS.airports;         
                    } else {
                        $scope.portList = $rootScope.OPSPORTS.seaports;
                    }
                })
            }
            else {
                Guide.getOpsPorts({
                    companyId: $scope.guide.companyId
                }, function(result){
                    $rootScope.OPSPORTS.airports = [];
                    $rootScope.OPSPORTS.seaports = [];
                    angular.forEach(result, function(data){
                        if(data.type == 1){
                            $rootScope.OPSPORTS.seaports.push(data);
                        }
                        else {
                            $rootScope.OPSPORTS.airports.push(data);
                        }
                    })
                    if ($scope.guide && ($scope.guide.shipmentType == "AIR" || $scope.guide.shipmentType == "EXPRESS" || $scope.guide.shipmentType == "FBAAIR")) {
                        $scope.portList = $rootScope.OPSPORTS.airports;         
                    } else {
                        $scope.portList = $rootScope.OPSPORTS.seaports;
                    }
                })
            }
        };
        $scope._selected = function($item, which, booking) {
            booking[which] = $item.code;
        }
        $scope.opsShippers = [];
        $scope.loadOpsShippers = function() {
            if ($scope.opsShippers && $scope.opsShippers.length > 0) {
                return;
            }
            if($rootScope.account && $rootScope.account.companyId){
                var venderIds = [];            
                if ($scope.bookings[indexCopy] && $scope.bookings[indexCopy].createdBy){
                    venderIds.push($scope.bookings[indexCopy].createdBy);
                }
                if ($scope.guide.shipperId){
                    venderIds.push($scope.guide.shipperId);
                }
                if ($scope.guide.customerId){
                    venderIds.push($scope.guide.customerId);
                }
                if ($scope.guide.deliveryId){
                    venderIds.push($scope.guide.deliveryId);
                }
                if ($scope.guide.bookingToId){
                    venderIds.push($scope.guide.bookingToId);
                }
                OpsShipper.getAllByClientOrAgentId(venderIds, function(result) {
                    $scope.opsShippers = result;
                    for (var i = $scope.opsShippers.length -1; i >=0; i--) {
                        if (!$scope.opsShippers[i].companyName){
                            $scope.opsShippers.splice(i, 1);
                        }
                    }
                })
            }
            else if($scope.guide.shipperId){
                Guide.getOpsShippers({
                    venderId: $scope.guide.shipperId
                }, function(result) {
                    $scope.opsShippers = result;
                    for (var i = $scope.opsShippers.length -1; i >=0; i--) {
                        if (!$scope.opsShippers[i].companyName){
                            $scope.opsShippers.splice(i, 1);
                        }
                    }
                })            
            }
        };
        $scope.onShipperSelected = function(data, chooseTarget, booking) {
            booking[chooseTarget] = data.blShow;
        }

        $scope.generatePkgsAndType = function(booking) {
            if (!booking.pkgNum || !booking.pkgType || booking.pkgsAndType) {
                booking.pkgsAndType = angular.uppercase(booking.pkgsAndType);
                return;
            }
            booking.pkgsAndType = booking.pkgNum.toString() + " " + booking.pkgType;
        }
        $scope.generateGwVol = function(booking) {
            if (booking.gwVol) {
                booking.gwVol = angular.uppercase(booking.gwVol);
                return;
            }
            if (booking.gw && booking.vol) {
                booking.gwVol = booking.gw.toFixed(DataUtils.getGwPrecision($scope.guide)) + " KGS" + "\n" + booking.vol.toFixed(DataUtils.getVolPrecision($scope.guide))  + " CBM";
            } else if (booking.vol) {
                booking.gwVol = booking.vol.toFixed(DataUtils.getVolPrecision($scope.guide))  + " CBM";
            } else if (booking.gw) {
                booking.gwVol = booking.gw.toFixed(DataUtils.getGwPrecision($scope.guide))  + " KGS";
            };
        };

        function delHbl(booking) {
            if ($scope.guide.isClosed) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if (booking.id) {
                $scope.isSaving = true;
                if($rootScope.account && $rootScope.account.id){
                    Booking.delHbl({
                        id: booking.id
                    }, function(){
                        $scope.isSaving = false;
                    });
                }
                else {
                    Guide.delHbl({
                        id: booking.id
                    }, function(){
                        $scope.isSaving = false;
                    });
                }
            };
            var index = $scope.bookings.indexOf(booking);
            if (index > -1) {
                $scope.bookings.splice(index, 1);
                if($scope.bookings.length == 0){
                    $scope.clear();
                }
            };
            bBookingCopy = null;
        };

        $scope.updateGwVol = function(booking) {
            if (booking.gw && booking.vol) {
                booking.gwVol = booking.gw.toFixed(DataUtils.getGwPrecision($scope.guide)) + "KGS" + "\n" + booking.vol.toFixed(DataUtils.getVolPrecision($scope.guide)) + "CBM";
            } else if (booking.vol) {
                booking.gwVol = booking.vol.toFixed(DataUtils.getVolPrecision($scope.guide)) + "CBM";
            } else if (booking.gw) {
                booking.gwVol = booking.gw.toFixed(DataUtils.getGwPrecision($scope.guide)) + "KGS";
            }

            if (booking.divVolBy){
                booking.chargeWeight = booking.vol * 1000000 / booking.divVolBy;
            }

            if(booking.chargeWeight < booking.gw){
                booking.chargeWeight = booking.gw;
            }

            if (booking.chargeWeight){
                booking.chargeWeight = booking.chargeWeight.toFixed(2) - 0;
            }               
            if (booking.pkgNum && booking.pkgType) {
                booking.pkgsAndType = booking.pkgNum.toString() + " " + booking.pkgType;             
            }
            $translate.refresh();
        };

        $scope.updatePkgsAndType = function(booking) {
            if (booking.pkgNum && booking.pkgType) {
                booking.pkgsAndType = booking.pkgNum.toString() + " " + booking.pkgType;             
            }
        }
        $scope.blAttRemark = function(booking) {
            booking.goodsDiscription = booking.goodsDiscription + "\n\n" + "MARKS AND DESCRIPTION AS PER ATTACHED LIST";
        }
        $scope.mblAttRemark = function(booking) {
            booking.remarkTwo = booking.remarkTwo + "\n\n" + "MARKS AND DESCRIPTION AS PER ATTACHED LIST";
        }
        $scope.textPop = function(booking, bfd) {
            $uibModal.open({
                templateUrl: 'app/entities/ops-change-log/ops-change-log-delete-dialog.html',
                controller: 'OpsChangeLogDeleteController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return booking[bfd];
                    }]
                }
            }).result.then(function(result) {
                booking[bfd] = result;
            }, function() {
            });
        }
        var lclSayCtns = true;
        $scope.generateSayCtns = function(booking) {
            if (!lclSayCtns){
                var bookingCtns = [];
                var ctnTypes = new Set();
                angular.forEach(booking.guideCtns, function(gCtn){
                    if(gCtn.ctnType){
                        ctnTypes.add(gCtn.ctnType);
                    }  
                })
                ctnTypes.forEach(function(ctnType){
                    var ttl = 0;
                    angular.forEach(booking.guideCtns, function(gCtn){
                        if (gCtn.ctnType == ctnType){
                            ttl++;
                        }
                     })
                    if (ttl){
                        var bkCtn = {};
                        bkCtn.num = ttl;
                        bkCtn.ctnType = ctnType;
                        bookingCtns.push(bkCtn);
                    }
                });

                if (bookingCtns.length > 0){
                    var sayCtns = "SAY ";
                    var isFirst = true;
                    angular.forEach(bookingCtns, function(data){
                        if (data.num){
                            if (!isFirst){
                                sayCtns = sayCtns + " AND ";
                            }
                            sayCtns = sayCtns + DataUtils.translate(data.num.toString()) + " (" + data.num + ") " + data.ctnType;
                            isFirst = false;
                        }
                    })
                    booking.bookingToName = sayCtns + " CONTAINER(S) ONLY.";
                    lclSayCtns = !lclSayCtns;
                    return;
                }                
            }
            if (!booking.pkgNum) {
                return;
            }
            booking.bookingToName = "SAY " + DataUtils.translate(booking.pkgNum.toString()) + " (" + booking.pkgNum + ") " + booking.pkgType + " ONLY.";
            lclSayCtns = !lclSayCtns;
        }
        
        var isWood = false;
        $scope.woodenTxt = function(booking) {
            if (isWood) {
                booking.beneficiary = "THIS SHIPMENT HAS BEEN FUMIGATED AND HEATED.";
            } else {
                booking.beneficiary = "THIS SHIPMENT CONTAINS NO SOLID WOODEN PACKING MATERIALS.";
            }
            isWood = !isWood;
        }

        $scope.blSend = function(booking, type, code, isSwb) {
            var email = {};
            email.attachments = [];
            email.head = booking.palletSize;
            var subjectNum = booking.hblNum;
            if (!subjectNum) {
                subjectNum = booking.soNum;
            }
            email.subject = subjectNum + "--" + $translate.instant('entity.email.blSendSubject');
            email.content = $translate.instant('entity.email.blSendContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }

            var guideReportDTO = initGuideReportDto(booking, isSwb);
            var entity = {};
            entity.email = email;
            entity.guide = guideReportDTO;
            entity.emailType = type;
            entity.code = code;
            entity.files = null;
            entity.bookingTruck = null;
            entity.venderId = booking.createdBy;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        $scope.checkSi = function(booking, type, reportType) {
            var email = {};
            email.attachments = [];
            email.head = booking.palletSize;
            var subjectNum = booking.hblNum;
            if (!subjectNum) {
                subjectNum = booking.soNum;
            }
            email.subject = subjectNum + "--" + $translate.instant('entity.email.siCheckSubject');
            email.content = "SO#: " + $scope.guide.soNum + ", " + $translate.instant('entity.email.siCheckContent') + DateUtils.convertDateTimeToString($scope.guide.siCutTime) + ". \n\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var guideReportDTO = initGuideReportDto(booking);
            var entity = {};
            entity.email = email;
            entity.guide = guideReportDTO;
            entity.emailType = type;
            entity.code = reportType;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = booking.createdBy;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        $scope.submitSI = function(type, booking, reportType) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.bookingToName;
            email.subject = $scope.guide.soNum + "--" + $translate.instant('entity.email.siSendSubject');
            email.content = $translate.instant('entity.email.siSendContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var guideReportDTO = initGuideReportDto(booking);
            var entity = {};
            entity.email = email;
            entity.guide = guideReportDTO;
            entity.emailType = type;
            entity.code = reportType;          
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.bookingToId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }
        $scope.trConfirmation = function(type, booking) {
            var email = {};
            email.attachments = [];
            email.head = booking.palletSize;
            var subjectNum = booking.hblNum;
            email.subject = "HBL#: " + subjectNum + "--" + $translate.instant('entity.email.trConfirmationSubject');

            email.content = $translate.instant('entity.email.trConfirmationContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var guideReportDTO = initGuideReportDto(booking);
            var entity = {};
            entity.email = email;
            entity.guide = guideReportDTO;
            entity.emailType = type;
            entity.code = 70;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = booking.createdBy;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        $scope.shipperTr = function(type, code, booking) {
            var email = {};
            email.attachments = [];
            email.head = booking.palletSize;
            var subjectNum = booking.hblNum;
            email.subject = "HBL#: " + subjectNum + "--" + $translate.instant('entity.email.shipperTrSubject');
            email.content = $translate.instant('entity.email.shipperTrContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }

            var guideReportDTO = initGuideReportDto(booking);
            var entity = {};
            entity.email = email;
            entity.guide = guideReportDTO;
            entity.emailType = type;
            entity.code = code;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = booking.createdBy;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        $scope.isfSend = function(type, booking) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.deliveryName;
            var subjectNum = $scope.guide.mblNum;
            if (!subjectNum) {
                subjectNum = $scope.guide.jobNum;
            }
            email.subject = subjectNum + "--ISF Sheet";
            email.content = "MBL#: " + $scope.guide.mblNum + ", Attached ISF Sheet FYI. \n\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var guideReportDTO = initGuideReportDto(booking);
            var entity = {};
            entity.email = email;
            entity.guide = guideReportDTO;
            entity.emailType = type;
            entity.code = 80;          
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.deliveryId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }
        var sendEmailPop = function(entity) {
            $uibModal.open({
                templateUrl: 'app/entities/email/email-dialog.html',
                controller: 'EmailDialogController',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
            }, function(result) {});
        }
        $scope.addGCtn = function(booking) {
            if ($scope.guide.isClosed) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            booking.guideCtns.push({
            });
        };        

        $scope.deleteGCtn = function(booking, ctn) {
            if ($scope.guide.isClosed) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if (ctn.id) {
                if(!$rootScope.account || !$rootScope.account.id){
                    layer.msg($translate.instant('global.forbidden'));
                    return;
                }
                GuideCtns.delete({
                    id: ctn.id
                });
            };
            var index = booking.guideCtns.indexOf(ctn);
            if (index > -1) {
                booking.guideCtns.splice(index, 1);
            };
            $scope.guideCtnAmtChange(booking);
        };

        $scope.delAll = function(booking) {
            if ($scope.guide.isClosed) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if(!$rootScope.account || !$rootScope.account.id){
                layer.msg($translate.instant('global.forbidden'));
                return;
            }
            angular.forEach(booking.guideCtns, function(ctn){
                $scope.deleteGCtn(booking, ctn);
            })
        };

        $scope.checkCtnRepeat = function(booking, ctn) {
            if (!ctn.ctnNum) {
                return;
            }
            angular.forEach(booking.guideCtns, function(data) {
                if (data != ctn && data.ctnNum == ctn.ctnNum) {
                    $rootScope.OPS_ALERT(data.ctnNum + ": " + $translate.instant('autopsApp.guide.home.ctnRepeatedAlert'));
                }
            })
        }

        $scope._ctnselected = function(ctn, bCtn, booking) {
            bCtn.ctnType = ctn.ctnType;      
            bCtn.ctnNum = ctn.ctnNum;    
            bCtn.sealNum = ctn.sealNum;    
            bCtn.pkgs = ctn.pkgs;    
            bCtn.pkgType = ctn.pkgType;    
            bCtn.gw = ctn.gw;    
            bCtn.vol = ctn.vol;    
            $scope.guideCtnAmtChange(booking);            
        }


        $scope.splitCtn = function(booking, ctn) {
            if ($scope.guide.isClosed) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/booking/splitctn-dialog.html',
                controller: 'SplitCtnController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return ctn;
                    }]
                }
            }).result.then(function(result) {
                $scope.deleteGCtn(booking, ctn);
                booking.guideCtns.push(result.newCtn);
                $translate.refresh();
            }, function() {
            });
        }
        $scope._onClientSelected = function(booking, data) {
            booking.palletSize = data.companyName;
            booking.createdBy = data.id;
        }

        $scope.sendFmsShipment = function(booking, type) {
            if(!$rootScope.account || !$rootScope.account.companyId){
                return;
            }
            var guide = initGuideReportDto(booking);
            $rootScope.SEND_FMS_SHIPMENT(guide, 'goFreight');
        }
        $scope.getFmsRequestBody = function(booking, type) {
            if(!$rootScope.account || !$rootScope.account.companyId){
                return;
            }
            var guide = initGuideReportDto(booking);
            $rootScope.GET_FMS_REQUEST_BODY(guide, 'goFreight');
        }
    }
})();