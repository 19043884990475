(function () {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CustomNodeDialogFieldController', CustomNodeDialogFieldController);

        CustomNodeDialogFieldController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', '$translate', '$uibModal',
        'entity', 'CustomNode', 'CustomCondition', 'Dictionary', 'DataUtils'];

    function CustomNodeDialogFieldController($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, $translate, $uibModal,
        entity, CustomNode, CustomCondition, Dictionary, DataUtils) {
        var vm = this;

        vm.customNode = entity;
        vm.clear = clear;
        vm.save = save;
        vm.addCondition = addCondition;
        vm.delCondition = delCondition;
        vm.shipmentTypeChange = shipmentTypeChange;
        vm.getFdType = getFdType;
        vm.operatorChange = operatorChange;
        vm.conditionTypeChange = conditionTypeChange;
        vm.fieldNameChange = fieldNameChange;
        vm.bindFdNameChange = bindFdNameChange;
        vm.conditionsCombineChange = conditionsCombineChange;
        vm.getConditions = getConditions;
        vm.hdTxtInput = hdTxtInput;
        vm.autoWfFields = autoWfFields;
        vm.targetValueChange = targetValueChange;
        vm.afOrderNodeAlert  = afOrderNodeAlert;

        function targetValueChange(data) {
            // alert(data.targetValue);
        }
        function afOrderNodeAlert() {
            $rootScope.OPS_ALERT($translate.instant('autopsApp.customNode.afOrderNodeAlert'));
        }

        vm.alertFd = "tips." + vm.customNode.type;

        $timeout(function () {
            // angular.element('.form-group:eq(1)>input').focus();
            shipmentTypeChange(vm.customNode.shipmentType);
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            if(vm.customNode.type == 'AF'){
                if(!vm.customNode.bindFdName){
                    vm.customNode.bindFd = null;            
                }
                else {
                    vm.customNode.ndName = vm.customNode.bindFdName;
                }

                if(!vm.customNode.bindFd && !vm.customNode.ndName){
                    layer.msg($translate.instant('autopsApp.customNode.customNodesTip'));
                    return;
                }
            }
            vm.isSaving = true;
            if (vm.customNode.id !== null) {
                CustomNode.update(vm.customNode, onSaveSuccess, $rootScope.ALERT_ERROR);
            } else {
                CustomNode.save(vm.customNode, onSaveSuccess, $rootScope.ALERT_ERROR);
            }
            vm.isSaving = false;
        }

        function onSaveSuccess(result) {
            $scope.$emit('autopsApp:customNodeUpdate', result);
            // $uibModalInstance.close(result);
            vm.customNode = result;
            // 接口响应的“目标值”总是字符串，如果“目标值”字符串中是数字，就转换为数字类型，这样在界面才能显示出来
            if(result && result.customConditions){
                angular.forEach(result.customConditions, function(customCondition){
                    // 签收方式的数字不需要转换为数字类型，不然前台已选选项会显示不出
                    if (customCondition.fieldName == 'dcType') {
                        return;
                    }
                    if(customCondition.targetValue && !isNaN(customCondition.targetValue)){
                        customCondition.targetValue = Number(customCondition.targetValue);
                    }
                })
            }
            vm.isSaving = false;
            shipmentTypeChange(vm.customNode.shipmentType);
            layer.msg($translate.instant('global.save_success'));
        }

        function addCondition() {
            if (!vm.customNode.customConditions) {
                vm.customNode.customConditions = [];
            }
            vm.customNode.customConditions.push({});
        }

        function delCondition(customCondition) {
            if (customCondition.id) {
                CustomCondition.delete({
                    id: customCondition.id
                });
            };
            var index = vm.customNode.customConditions.indexOf(customCondition);
            if (index > -1) {
                vm.customNode.customConditions.splice(index, 1);
            };
        }
        function checkField(fdArray) {
            angular.forEach(vm.customNode.customConditions, function (data) {
                if(data.conditionType == 'condition' && fdArray.indexOf(data.fieldName) == -1){
                    data.fieldName = null;
                    data.operator = null;
                    data.targetValue = null;
                }
            })
            if(fdArray.indexOf(vm.customNode.bindFd) == -1){
                vm.customNode.bindFdName = null;
                vm.customNode.bindFd = null;
            }
        }

        var dateArray = ["lfd", "eta", "etd", "feederEtd", "deliveryDate", "transferDate", "billDate", "opDate", "atd", "revDeadLine", "cargoReadyDate", "bookingEtd", "BKetd", "BKissueDate"];
        var timeArray = ["ctnReturnTime", "createdTime", "cls", "lastModifiedTime", "bookingTime", "soReleaseTime", "truckArrangeTime", "customArrangeTime", "siSubmitedTime", "hblConfirmedTime", "mblConfirmedTime", "dnSentTime", "saSentTime", "hblReleasedTime", "mblReleasedTime", "invoiceIssuedTime", "invoiceReceivedTime", "paymentPaidTime", "paymentReceivedTime", "siCutTime", "localTimeOne", "localTimeTwo", "otherTimeOne", "otherTimeTwo", "otherTimeThree", "vgmTime", "vgm", "ctnsLoadedTime", "customReleaseTime", "deliveryTime", "plConfirmTime", "customClearTime", "ataTime", "deliveryAppointmentTime", "soExpectedTime", "applyRlsTime", "deliveryGuideTime", "closeTime", "ctnAvailableTime", "BKcreatedTime", "BKlastModifiedTime", "BKsiReceivedTime", "offShelfTime", "costInputTime"];
        var numArray = ["wmsGw", "wmsVol", "wmsCw", "id", "shipperId", "createdBy", "checkById", "deliveryId", "bookingToId", "warehouseVid", "fumigationVid", "traderVid", "insuranceVid", "refGuideId", "salesId", "opId", "docId", "finId", "companyId", "branchGuideId", "agentUserId", "customerId", "masterId", "cusId", "awbWeight", "awbPrice", "awbChargesTtl", "costId", "bookingById", "truckById", "customById", "timeStamp", "agentGuideId", "expressLabelId", "docStamp", "overseasCsId", "customerContactId", "shipperContactId", "bookingToContactId", "deliveryContactId", "warehouseContactId", "fumigationContactId", "insuranceContactId", "traderContactId", "medCompanyId", "medVenderId", "gw", "vol", "pkgNum", "shipperUserId", "whId", "wmsActAmt", "customerUserId", "bookingId", "chargeWeight", "pieces", "palletNum", "vw", "shipFromId", "shipToId", "parcelId", "thirdpartyPlatformId", "clearanceAgentId", "clearanceAgentContactId", "BKid", "BKbookingToId", "BKcneeId", "BKgw", "BKvol", "BKpkgNum", "BKguideId", "BKdivVolBy", "BKtempFrom", "BKtempTo", "BKrefGuideId", "BKrating", "BKsellingId", "BKnoOfCopies", "BKvolumeShare", "BKcreatedBy", "BKchargeWeight", "BKcompanyId", "divVolBy", "lbs", "collect", "sellAmt", "saleFreight", "platformFee", "otherFee"];
        var booleanArray = ["cbMark", "guideCtnsExist", "cwLoked", "isWood", "isExport", "isTemplate", "ratesLocked", "isLocked", "isApplied", "isClosed", "issueFeederHbl", "etdLocked", "mblOnly", "deliverySecret", "trackingFinished", "hidenCost", "isVoid", "spx", "hidenSelling", "allBilled", "truckRequired", "customRequired", "vat", "incomeTax", "isDelivering", "feederEtdConfirmed", "salesChecked", "submited", "dontSynTtl", "chargesAsArranged", "priceAsArranged", "attachMark", "attachDescription", "attachCtns", "isVirtual", "asWmsIn", "asWmsOut", "plConfirmed", "parcelMaster", "chargeByCbm", "isPallet", "ownImporter", "autoTracking", "consolidated", "isRemote", "onHold", "customsInspection", "labelVirtual", "autoSendPlCheck", "isResidential", "headTrackFinished", "orderPushTag", "directDelivery", "assistChargesConfirmed", "insuranceRequired", "fumigationRequired", "warehouseRequired", "coRequired", "BKisTemplate", "BKisLocked", "BKetdConfirmed", "BKattachCtns", "BKmagneticInspection", "BKidentification", "BKotherService", "BKhblPrinted", "BKattachMark", "BKinclTax", "BKattachDescription", "BKplConfirmed", "cargoIsOut", "inchOz", "irregularPacking", "isSuperRemote", "addressModify"];
        function getFdType(fd){
            if(dateArray.indexOf(fd) != -1){
                return "date";
            }
            if(timeArray.indexOf(fd) != -1){
                return "time";
            }
            if(numArray.indexOf(fd) != -1){
                return "num";
            }
            if(booleanArray.indexOf(fd) != -1){
                return "boolean";
            }
            return "str";
        }

        function operatorChange(customCondition) {
            if(customCondition.operator == 'isNull' || customCondition.operator == 'notNull'){
                customCondition.targetValue = null;
            }
        }

        function conditionTypeChange(customCondition) {
            customCondition.fieldName = null;
            customCondition.targetValue = null;
        }

        function fieldNameChange(customCondition, item) {
            customCondition.fieldName = item.name;
            customCondition.fdName = item.langName;
            customCondition.targetValue = null;
            customCondition.operator = null;
            if(getFdType(customCondition.fieldName) == 'boolean'){
                customCondition.operator = 'equals';
            }
            $translate.refresh();
        }

        function bindFdNameChange(item) {
            vm.customNode.bindFd = item.name;
            vm.customNode.bindFdName = item.langName;            
            if(vm.customNode.type == 'AF'){
                vm.customNode.ndName = null;
            }
            else {
                vm.customNode.ndName = item.langName;
            }
        }

        function conditionsCombineChange(conditionsCombine) {
            if(conditionsCombine){
                vm.customNode.role = null;
                vm.customNode.viewScope = null;
            }
        }

        vm.conditions;
        function getConditions() {
            CustomNode.getConditions({userId: $rootScope.account.id, type: vm.customNode.type, shipmentType: vm.customNode.shipmentType}, function (result) {
                vm.conditions = result;
            });
        }

        var comNodes = ["guideCtnsExist", "createdTime", "deliveryGuideTime", "bookingTime", "soReleaseTime", "truckArrangeTime", "otherTimeOne", "otherTimeTwo", "customArrangeTime",
            "customReleaseTime", "atd", "hblReleasedTime",  "mblReleasedTime", "ataTime", "customsInspection", "customClearTime", "ctnAvailableTime", "otherTimeThree", "deliveryAppointmentTime",
            "deliveryTime", "closeTime", "dnSentTime", "invoiceIssuedTime", "invoiceReceivedTime", "paymentPaidTime", "paymentReceivedTime", "localTimeTwo", "isApplied", "isClosed", "consolidated", "BKisLocked"];

        var fmsNodes = ["etd", "eta", "ctnsLoadedTime", "vgmTime", "BKsiReceivedTime", "siSubmitedTime", "hblConfirmedTime", "mblConfirmedTime",
            "saSentTime", "localTimeOne", "ctnReturnTime"];

        var airNodes = ["etd", "eta", "BKsiReceivedTime", "plConfirmTime", "siSubmitedTime", "hblConfirmedTime", "mblConfirmedTime",
            "saSentTime"];

        var fbaNodes = ["etd", "eta", "plConfirmTime", "ctnsLoadedTime", "ctnReturnTime"];

        var afExNodes = ["guideCtnsExist", "dnSentTime", "invoiceIssuedTime", "invoiceReceivedTime", "paymentPaidTime", "paymentReceivedTime"];
        var afNodes = ["uploadFileType"];

        var headBtnNodes = ["ediCenterBtn", "cloudFilesBtn", "labelBtn", "othersBtn", "ratesBtn",
            "refNumTitle", "productChannelTitle", "cargoVolumeTitle", "vslScheduleTitle", "contactInfoTitle",
            "cargoDetailsTitle", "intracompanyTitle", "routeBilTitle", "fastLabelPanel", "packingListPanel", "tcPanel", "ctnsPanel", "nodesPanel"];

        function addComNodes(outFds) {
            angular.forEach(comNodes, function(data){
                if(outFds.indexOf(data) == -1){
                    outFds.push(data);
                }
            })
        }
        var parcelTmsTypes = ["PARCELALL", "PARCEL", "LINE", "RETURN", "EP", "TMSALL", "TMS", "FTL", "LTL", "EXD", "CPU", "EPWMS"];
        var tmsTypes = ["TMSALL", "TMS", "FTL", "LTL", "EXD", "CPU", "EPWMS"];

        function getAllFdsByShipmentType(shipmentType){
            if(shipmentType == "AIR") {
                var outFds = angular.copy(Dictionary.airRqs);
                addComNodes(outFds);
                angular.forEach(airNodes, function(data){
                    if(outFds.indexOf(data) == -1){
                        outFds.push(data);
                    }
                })
                return outFds;
            }
            else if (parcelTmsTypes.indexOf(shipmentType) != -1) {
                var outFds = angular.copy(Dictionary.parcelRqs);
                return outFds;
            }
            else if($rootScope.ISFBA(shipmentType) || $rootScope.ISWMS(shipmentType) || shipmentType == "FBAALL" || shipmentType == "WMSALL") {
                var outFds = angular.copy(Dictionary.fbaRqs);
                addComNodes(outFds);
                angular.forEach(fbaNodes, function(data){
                    if(outFds.indexOf(data) == -1){
                        outFds.push(data);
                    }
                })
                return outFds;
            }
            else {
                var outFds = angular.copy(Dictionary.fmsRqs);
                addComNodes(outFds);
                angular.forEach(fmsNodes, function(data){
                    if(outFds.indexOf(data) == -1){
                        outFds.push(data);
                    }
                })
                return outFds;
            }
        }

        vm.fdList = [];
        vm.bindFdList = [];
        function shipmentTypeChange(shipmentType) {
            if(!shipmentType){
                return;
            }
            vm.fdList = [];
            vm.bindFdList = [];
            var allFds = getAllFdsByShipmentType(shipmentType);

            if(shipmentType == "AIR") {
                angular.forEach(allFds, function(data){
                    addToBindFdList(airNodes, data);
                })
            }
            else if($rootScope.ISFBA(shipmentType) || $rootScope.ISWMS(shipmentType)) {
                angular.forEach(allFds, function(data){
                    addToBindFdList(fbaNodes, data);
                })
            }
            else if(parcelTmsTypes.indexOf(shipmentType) != -1) {
                angular.forEach(allFds, function(data){
                    var fd = {name: data};
                    DataUtils.getLangNameExpressParel(fd, tmsTypes.indexOf(shipmentType) != -1);
                    vm.fdList.push(fd);
                    vm.bindFdList.push(fd);
                })
            }
            else {
                angular.forEach(allFds, function(data){
                    addToBindFdList(fmsNodes, data);
                })
            }
            getConditions();

            //头部节点增加
            if(vm.customNode.type == "WF"){
                if(shipmentType == "AIR") {
                    angular.forEach(Dictionary.airFds, function(data){
                        if(allFds.indexOf(data) == -1){
                            allFds.push(data);
                            var fd = {name: data};
                            DataUtils.getLangNameFba(fd);
                            vm.bindFdList.push(fd);
                        }
                    })
                }
                else if($rootScope.ISFBA(shipmentType) || $rootScope.ISWMS(shipmentType)) {
                    angular.forEach(Dictionary.fbaFds, function(data){
                        if(allFds.indexOf(data) == -1){
                            allFds.push(data);
                            var fd = {name: data};
                            DataUtils.getLangNameFba(fd);
                            vm.bindFdList.push(fd);
                        }
                    })
                }
                else {
                    angular.forEach(Dictionary.fmsFds, function(data){
                        if(allFds.indexOf(data) == -1){
                            allFds.push(data);
                            var fd = {name: data};
                            DataUtils.getLangNameFba(fd);
                            vm.bindFdList.push(fd);
                        }
                    })
                }
                angular.forEach(headBtnNodes, function(data){
                    allFds.push(data);
                    var fd = {name: data};
                    DataUtils.getLangNameFba(fd);
                    vm.bindFdList.splice(0, 0, fd);

                })
            }
            if(vm.customNode.type == "AF"){
                var fd = {name: "uploadFileType"};
                DataUtils.getLangNameFba(fd);
                vm.fdList.push(fd);
                allFds.push("uploadFileType");
            }
            checkField(allFds);
            updateFdName();
        }

        function addToBindFdList(shipmentTypeNodes, data) {
            var fd = {name: data};
            DataUtils.getLangNameFba(fd);
            vm.fdList.push(fd);

            //自动填充 过滤一些节点
            if(vm.customNode.type == "AF"){
                if((comNodes.indexOf(fd.name) != -1 || shipmentTypeNodes.indexOf(fd.name) != -1) && afExNodes.indexOf(fd.name) == -1){
                    vm.bindFdList.push(fd);
                }
            }
            else {
                vm.bindFdList.push(fd);
            }
        }

        function updateFdName() {
            if(vm.customNode.bindFd){
                angular.forEach(vm.bindFdList, function(fd){
                    if(fd.name == vm.customNode.bindFd){
                        vm.customNode.bindFdName = fd.langName;
                        if(vm.customNode.type != "AF"){
                            vm.customNode.ndName = fd.langName;
                        }           
                        else {
                            vm.customNode.ndName = null;
                        }             
                    }
                })
            }
            angular.forEach(vm.customNode.customConditions, function (data) {
                angular.forEach(vm.fdList, function(fd){
                    if(fd.name == data.fieldName){
                        data.fdName = fd.langName;
                    }
                })
                if(data.targetValue == "true"){
                    data.targetValue = true;
                }
                else if(data.targetValue == "false"){
                    data.targetValue = false;
                }
            })
            $translate.refresh();
        }
        var selectTxtFds = ["bizType", "internalType", "cargoType", "serviceTerm", "releaseWay",
            "freightTerm", "hblRlsWay", "BKfreightTerm", "BKserviceTerm", "checkStatus", "uploadFileType", "dutyPayParty", "dcType", "payParty"];
        function hdTxtInput(fd) {
            return selectTxtFds.indexOf(fd) !== -1;
        }


        function autoWfFields() {
            if(!vm.customNode.role || !vm.customNode.shipmentType){
                layer.msg($translate.instant('autopsApp.customNode.autoWfFieldsAlert'));
                return;
            }
            var entity = {
                role: vm.customNode.role,
                shipmentType: vm.customNode.shipmentType,
                fbArray: vm.bindFdList
            }
            $uibModal.open({
                templateUrl: 'app/entities/custom-condition/custom-condition-dialog.html',
                controller: 'CustomConditionDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }

        vm.fileTypes = [
            { value: "", label: "" },
            { value: 'BK-', label: $translate.instant('autopsApp.guide.fileType.bkFile')},
            { value: 'SO-', label: $translate.instant('autopsApp.guide.fileType.soFile')},
            { value: 'WH-', label: $translate.instant('autopsApp.guide.fileType.wmsFile')},
            { value: 'SI-', label: $translate.instant('autopsApp.guide.fileType.siFile')},
            { value: 'POD-', label: $translate.instant('autopsApp.guide.fileType.podFile')},
            { value: 'TK-', label: $translate.instant('autopsApp.guide.fileType.tkFile')},
            { value: 'ISA-', label: $translate.instant('autopsApp.guide.fileType.isaFile')},
            { value: 'DCD-', label: $translate.instant('autopsApp.guide.fileType.dcdFile')},
            { value: 'CRN-', label: $translate.instant('autopsApp.guide.fileType.crnFile')},
            { value: 'CDS-', label: $translate.instant('autopsApp.guide.fileType.cdsFile')},
            { value: 'RBS-', label: $translate.instant('autopsApp.guide.fileType.rbsFile')},
            { value: 'PBS-', label: $translate.instant('autopsApp.guide.fileType.pbsFile')},
            { value: 'SO-', label: $translate.instant('autopsApp.guide.fileType.soFile')},
            { value: 'MB-', label: $translate.instant('autopsApp.guide.fileType.mblFile')},
            { value: 'BL-', label: $translate.instant('autopsApp.guide.fileType.blFile')},
            { value: 'DO-', label: $translate.instant('autopsApp.guide.fileType.doFile')},
            { value: 'DO-', label: $translate.instant('autopsApp.guide.fileType.doFile')}
        ];


    }
})();
