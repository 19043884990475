(function () {
    'use strict';

    angular
        .module('autopsApp')
        .factory('User', User);

    User.$inject = ['$resource'];

    function User ($resource) {
        var service = $resource('api/users/:login', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'search': {
                method:'GET',
                url:'api/_search/users/:query',
                isArray:true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getBranchs': {
                method:'GET',
                url:'api/users/branchs',
                isArray:true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getDeps': {
                method:'GET',
                url:'api/users/deps',
                isArray:true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getSubordinates': {
                method:'GET',
                url:'api/users/subordinates',
                isArray:true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getAuths': {
                method:'GET',
                url:'api/users/getAuths',
                isArray:true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getAuthStrs': {
                method:'GET',
                url:'api/users/getAuthStrs',
                isArray:true
            },
            'getCompanyclerks': {
                method:'GET',
                url:'api/users/getCompanyclerks',
                isArray:true
            },
            'getOpsVenderClerks': {
                method:'GET',
                url:'api/users/getOpsVenderClerks',
                isArray:true
            },
            'getConfigurationWithLabel': {
                method:'GET',
                url:'api/users/getConfigurationWithLabel'
            },
            'updateUsersAuths': {
                method:'PUT',
                url:'api/users/updateUsersAuths',
                isArray:true
            },
            'copyAuth': {
                method:'GET',
                url:'api/users/copyAuth',
                isArray:true
            },
            'updateAllSubSysUser': {
                method:'GET',
                url:'api/users/updateAllSubSysUser'
            },
            'getUserName': {
                method:'GET',
                url:'api/users/getUserName'
            },
            'getAuthStrAllData': {
                method:'GET',
                url:'api/users/getAuthStrAllData',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'delete':{ method:'DELETE'},
            'exportUsers': {
                method: "POST",
                url: 'api/users/exportUsers',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            }
        });

        return service;
    }
})();
