(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CommodityDatabaseController', CommodityDatabaseController);

    CommodityDatabaseController.$inject = ['$scope', '$state', '$translate', '$rootScope', '$timeout', '$http', '$uibModal',
        'CommodityDatabase', 'CommodityDatabaseSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'WinAES', 'GridState', 'uiGridConstants'];

    function CommodityDatabaseController ($scope, $state, $translate, $rootScope, $timeout, $http, $uibModal,
        CommodityDatabase, CommodityDatabaseSearch, ParseLinks, AlertService, pagingParams, paginationConstants, WinAES, GridState, uiGridConstants) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        loadAll();
        var gridCopy;
        var pageLoaded = false;
        var filterTimer;
        function loadAll () {
            if (pagingParams.search) {
                CommodityDatabaseSearch.query({
                    query: pagingParams.search,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                var filter = {};
                if(gridCopy){
                    angular.forEach(gridCopy.columns, function(column){
                        angular.forEach(column.filters, function(cf){
                            if(cf.term != null && angular.isDefined(cf.term)){
                                if(cf.condition){
                                    var fd = column.field;
                                    if(fd.endWith("Str")){
                                        fd = fd.replace("Str", "");
                                    }
                                    if(cf.condition == 128 || cf.condition == 256){
                                        filter[fd + "TO"] = cf.term;
                                    }
                                    else if(cf.condition == 32 || cf.condition == 64){
                                        filter[fd + "FM"] = cf.term;
                                    }
                                }
                                else {
                                    filter[column.field] = cf.term;
                                }
                            }
                        })
                    })
                }

                CommodityDatabase.getFilterPage({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, filter, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                // if (vm.predicate !== 'id') {
                //     result.push('id');
                // }
                return result;
            }
            function onSuccess(data, headers) {
                if(headers('X-Total-Count')){
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                }
                else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                angular.forEach($scope.gridOptions.data, function(data){
                    data.encodeId = WinAES.encode(data.id.toString());
                })
                resize();
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        window.onresize = function(){
            resize();
        }

        function resize() {
            updateByIsAdvanceSearch ();
            var tabHeight = $scope.gridOptions.data.length * 30 + 190;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 300){
                tabHeight = 300;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');



        }
        function updateByIsAdvanceSearch () {
            $scope.gridOptions.useExternalSorting = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.useExternalFiltering = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.showColumnFooter = vm.isAdvanceSearch;
            // $scope.gridOptions.enableSelectAll = vm.isAdvanceSearch;
            // $scope.gridOptions.multiSelect = vm.isAdvanceSearch;

            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = true;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.isAdvanceSearch = false;
            vm.transition();
            loadAll();
        }

        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: true,

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: false,//每列footer

            enableFiltering: true,//高级过滤
            useExternalFiltering: true,

            enableHorizontalScrollbar : 1,//表格的水平滚动条
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)
            enableRowHeaderSelection : true,  //行选中
            enableRowSelection: true, //行选中
            enableSelectAll: true, //全部选中
            enableFullRowSelection : false, //行任意位置选中
            multiSelect: true,//多行选中

            exporterMenuCsv : false,
            exporterMenuPdf : false,
            exporterMenuLabel : "Export",
            exporterExcelFilename: $translate.instant('autopsApp.commodityDatabase.home.title') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],

            paginationPageSizes: [20, 35, 50, 100, 200],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 70,
            rowHeight: '50',
            columnDefs: [
                { field: 'id',
                    minWidth: 130,
                    displayName: "",
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<div class="btn-group flex-btn-group-container mr-5"><button type="button" ui-sref="commodity-database.edit({id: row.entity.encodeId})" class="btn btn-primary btn-grid"><span class="glyphicon glyphicon-edit"></span><span class="hidden-xs hidden-sm" translate="entity.action.edit"></span></button><button type="button" ui-sref="commodity-database.delete({id:row.entity.encodeId})" class="btn btn-danger btn-grid"><span class="glyphicon glyphicon-remove-circle"></span><span class="hidden-xs hidden-sm" translate="entity.action.delete"></span></button></div>'},
                { field: 'idCode',
                    displayName: $translate.instant('autopsApp.commodityDatabase.idCode'),
                    minWidth:120,
                    cellTemplate: '<a class="ml-5" ui-sref="commodity-database.edit({id: row.entity.encodeId})">{{row.entity.idCode}}</a>'},
                { field: 'venderName',
                    displayName: $translate.instant('autopsApp.commodityDatabase.venderName'),
                    minWidth:180},
                { field: 'commodityCountrys',
                    width:300,
                    enableFiltering: false,
                    enableSorting: false,
                    displayName: $translate.instant('autopsApp.commodityDatabase.commodityCountrys'),
                    cellTemplate: '<div class="table-text" ng-repeat="cargo in row.entity.commodityCountrys track by $index"><div class="row"><div class="col-md-8"><span>{{cargo.hsCode}}</span><small class="ml-5 text-muted" ng-show="cargo.importCountryCode" data-toggle="tooltip" data-placement="top" title="{{cargo.country}}">{{cargo.importCountryCode}}</small></div><div class="col-md-4"><strong data-toggle="tooltip" data-placement="top" title="Duty">{{cargo.dutyRate}}%</strong><strong data-toggle="tooltip" data-placement="top" title="Antidump Rate" ng-show="cargo.antiDumpRate && cargo.antiDumpRequired"> + {{cargo.antiDumpRate}}%</strong></div></div></div>'},
                { field: 'sku',
                    displayName: $translate.instant('autopsApp.commodityDatabase.sku'),
                    minWidth:150},
                { field: 'cargoName',
                    displayName: $translate.instant('autopsApp.commodityDatabase.cargoName'),
                    minWidth:150},
                { field: 'cargoNameEn',
                    displayName: $translate.instant('autopsApp.commodityDatabase.cargoNameEn'),
                    minWidth:150},
                { field: 'hsCode',
                    displayName: $translate.instant('autopsApp.commodityDatabase.hsCode'),
                    minWidth:150},
                { field: 'taxRebateRate',
                    displayName: $translate.instant('autopsApp.commodityDatabase.taxRebateRate'),
                    minWidth:200,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    type:'number',
                    cellTemplate: '<strong class="ml-5 text-primary" ng-if="row.entity.taxRebateRate">{{row.entity.taxRebateRate}}%</strong>'},
                { field: 'commodityInspectionRequired',
                    displayName: $translate.instant('autopsApp.commodityDatabase.commodityInspectionRequired'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span ng-class="row.entity.commodityInspectionRequired?\'label-primary\':\' label-danger\'"translate="{{row.entity.commodityInspectionRequired?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'invoiceUnit',
                    displayName: $translate.instant('autopsApp.commodityDatabase.invoiceUnit'),
                    minWidth:150},
                { field: 'customsUnit',
                    displayName: $translate.instant('autopsApp.commodityDatabase.customsUnit'),
                    minWidth:150},
                { field: 'customsSuperviseCondition',
                    displayName: $translate.instant('autopsApp.commodityDatabase.customsSuperviseCondition'),
                    minWidth:150},
                { field: 'inspectionCategory',
                    displayName: $translate.instant('autopsApp.commodityDatabase.inspectionCategory'),
                    minWidth:150},
                { field: 'modelRequired',
                    displayName: $translate.instant('autopsApp.commodityDatabase.modelRequired'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span ng-class="row.entity.modelRequired?\'label-primary\':\' label-danger\'"translate="{{row.entity.modelRequired?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'model',
                    displayName: $translate.instant('autopsApp.commodityDatabase.model'),
                    minWidth:150},
                { field: 'brand',
                    displayName: $translate.instant('autopsApp.commodityDatabase.brand'),
                    minWidth:150},
                { field: 'material',
                    displayName: $translate.instant('autopsApp.commodityDatabase.material'),
                    minWidth:150},
                { field: 'materialEn',
                    displayName: $translate.instant('autopsApp.commodityDatabase.materialEn'),
                    minWidth:150},
                { field: 'useAge',
                    displayName: $translate.instant('autopsApp.commodityDatabase.useAge'),
                    minWidth:150},
                { field: 'useAgeEn',
                    displayName: $translate.instant('autopsApp.commodityDatabase.useAgeEn'),
                    minWidth:150},
                { field: 'productUrl',
                    displayName: $translate.instant('autopsApp.commodityDatabase.productUrl'),
                    minWidth:150},
                { field: 'declareElement',
                    displayName: $translate.instant('autopsApp.commodityDatabase.declareElement'),
                    minWidth:150},
                { field: 'unitPrice',
                    displayName: $translate.instant('autopsApp.commodityDatabase.unitPrice'),
                    minWidth:200,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    type:'number'},
                { field: 'certificateType',
                    displayName: $translate.instant('autopsApp.commodityDatabase.certificateType'),
                    minWidth:150},
                { field: 'creater',
                    displayName: $translate.instant('autopsApp.commodityDatabase.creater'),
                    minWidth:150},
                { field: 'auditor',
                    displayName: $translate.instant('autopsApp.commodityDatabase.auditor'),
                    minWidth:150},
                { field: 'createTime',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:200,
                    displayName: $translate.instant('autopsApp.commodityDatabase.createTime'),
                    cellTemplate: '<span class="ml-5">{{row.entity.createTime | date:\'medium\'}}</span>'},
                { field: 'updateTime',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:200,
                    displayName: $translate.instant('autopsApp.commodityDatabase.updateTime'),
                    cellTemplate: '<span class="ml-5">{{row.entity.updateTime | date:\'medium\'}}</span>'}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 118;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if(!$scope.gridOptions.useExternalSorting){
                        if(sortColumns[sortColumns.length - 1]){
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if(sortColumns[sortColumns.length - 1]){
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.reverse = 'asc' == sortColumns[sortColumns.length - 1].sort.direction;
                    }
                    if(pageLoaded){
                        loadAll();
                    }

                });
                gridApi.core.on.filterChanged($scope, function() {
                    gridCopy = this.grid;
                    if(pageLoaded && $scope.gridOptions.useExternalFiltering){
                        clearTimeout(filterTimer);
                        filterTimer = setTimeout(function(){
                            loadAll();
                        }, 1000);//查询延时时间，单位ms
                    }
                });
                gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    vm.page = newPage;
                    vm.itemsPerPage = pageSize;
                    if(pageLoaded){
                        loadAll();
                    }
                });
                gridApi.selection.on.rowSelectionChanged($scope, function(row){

                });
            }
        }

        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            for(var i = $scope.gridOptions.columnDefs.length - 1; i >= 0; i--){
                if(!$rootScope.account.companyId && $scope.gridOptions.columnDefs[i].field == 'venderName'){
                    $scope.gridOptions.columnDefs.splice(i, 1);
                }
            }
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 118
            }, function(result){
                gridStateObj = result;
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    pageLoaded = true;
                    return;
                }
                if(gridStateObj.gridState){
                    $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                }
                pageLoaded = true;
                if(gridCopy){
                    var filterRequired = false;
                    angular.forEach(gridCopy.columns, function(column){
                        angular.forEach(column.filters, function(cf){
                            if(cf.term || cf.term == false){
                                filterRequired = true;
                            }
                        })
                    })
                    if(filterRequired){
                        layer.msg($translate.instant('global.filterDetectedAlert'));
                        loadAll();
                    }
                }
            })
        });

        var unsubscribe = $rootScope.$on('autopsApp:commodityDatabaseUpdate', function(event, result) {
            var isNew = true;
            angular.forEach($scope.gridOptions.data, function(data){
                if(data.id && data.id == result.id){
                    angular.copy(result, data);
                    data.encodeId = WinAES.encode(data.id.toString());
                    isNew = false;
                }
            })
            if(isNew){
                loadAll();
            }
        });

        $scope.batchImportByExcel = function(file) {
            if (!file) { return; }
            layer.load(1, { shade: 0.3 });
            var fd = new FormData();
            fd.append('file', file);
            $http.post(
                'api/commodity-databases/importByExcel', fd, {
                    transformRequest: angular.identity,
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                layer.closeAll();
                var result = new Object();
                result['data'] = data;
                $rootScope.DOWNLOADTXT(result, "importResult.txt");
            }).error(function(error) {
                layer.closeAll();
                $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
            });
        }

        $scope.searchRequestPop = function () {
            var searchParams = {};
            $uibModal.open({
                templateUrl: 'app/entities/commodity-database/commodity-databases-search.html',
                controller: 'CommodityDatabasesSearchController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function () {
                        return searchParams;
                    }]
                }
            }).result.then(function (results) {
                $scope.gridOptions.data = results;
                angular.forEach($scope.gridOptions.data, function(data){
                    data.encodeId = WinAES.encode(data.id.toString());
                })
                vm.isAdvanceSearch = vm.currentSearch = true;
                $scope.gridOptions.totalItems = results.length;
                $timeout(function (){
                    resize();
                });

            }, function () {
            });
        }

        $scope.batchDelete = function() {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if (selectedRows.length == 0) {
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            layer.confirm($translate.instant('global.delSelectedAlert', {ttlAmt: selectedRows.length}), {
                icon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    CommodityDatabase.batchDelete(selectedRows, function(){
                        layer.msg($translate.instant('global.delete_success'));
                        angular.forEach(selectedRows, function(item) {
                            $scope.gridOptions.data.splice($scope.gridOptions.data.indexOf(item), 1);
                        })
                    }, function() {
                        layer.msg($translate.instant('global.delete_fail'));
                    })
                    layer.close(index);
                }, function() {
            });
        }

        $scope.exportExcel = function(resultList) {
            if(!resultList || resultList.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            // 如有选中的行，只导出选中的行，否则导出全部筛选结果
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if (selectedRows.length > 0){
                resultList = selectedRows;
            }
            var fileName = "commodity_database.xlsx";
            var loadIndex = layer.load(1, {shade: 0.3});
            CommodityDatabase.exportExcel({
                enCn: $translate.instant('global.enCn')
            }, resultList, function(result) {
                layer.close(loadIndex);
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }

        $scope.batchAudit = function() {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if (selectedRows.length == 0) {
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            CommodityDatabase.batchAudit(selectedRows, function(){
                layer.msg($translate.instant('global.messages.submitted'));
                angular.forEach(selectedRows, function(item) {
                    if(!item.auditor){
                        item.auditor = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                    }
                })
            }, function() {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            })
        }


    }
})();
