(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('edi-insurance', {
            parent: 'entity',
            url: '/edi-insurance?page&sort&search',
            data: {
                authorities: ['AU_EDI_INSURANCE'],
                pageTitle: 'autopsApp.ediInsurance.home.title.cargo'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/edi-insurance/edi-insurances.html',
                    controller: 'EdiInsuranceController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('ediInsurance');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('edi-insurance-fba', {
            parent: 'entity',
            url: '/edi-insurance-fba?page&sort&search',
            data: {
                authorities: ['AU_EDI_INSURANCE'],
                pageTitle: 'autopsApp.ediInsurance.home.title.fba'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/edi-insurance/edi-insurances-fba.html',
                    controller: 'EdiInsuranceFbaController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('ediInsurance');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('edi-insurance-cargo', {
            parent: 'entity',
            url: '/edi-insurance-cargo?page&sort&search',
            data: {
                authorities: ['AU_EDI_INSURANCE'],
                pageTitle: 'autopsApp.ediInsurance.home.title.cargo'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/edi-insurance/edi-insurances-cargo.html',
                    controller: 'EdiInsuranceCargoController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('ediInsurance');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })        
        .state('edi-insurance.new', {
            parent: 'edi-insurance',
            url: '/new',
            data: {
                authorities: ['AU_EDI_INSURANCE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/edi-insurance/edi-insurance-dialog.html',
                    controller: 'EdiInsuranceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xxl',
                    resolve: {
                        entity: function () {
                            return {
                                comCode: null,
                                transportId: null,
                                userId: null,
                                tradeNo: null,
                                applicantName: null,
                                applicantCertificateType: null,
                                applicantCertificateNo: null,
                                applicantLinkManName: null,
                                applicantMobileTelephone: null,
                                insurantName: null,
                                goodsType: null,
                                bigPack: null,
                                smallPack: null,
                                goodsDesc: null,
                                goodsNum: null,
                                waybillNumber: null,
                                invoiceNumber: null,
                                jzx: null,
                                chuanName: null,
                                transportInfo: null,
                                startPortBig: null,
                                startPortSmall: null,
                                startPortName: null,
                                terminiPortBig: null,
                                terminiPortSmall: null,
                                terminiPortName: null,
                                startDate: null,
                                printFormat: null,
                                oppoPortName: null,
                                serveyCode: null,
                                payPlace: null,
                                jcl: null,
                                invoiceAmount: null,
                                currencyCode: null,
                                hasCredit: null,
                                planInfoList: null,
                                guideId: null,
                                companyId: null,
                                createTime: null,
                                submitter: null,
                                submitterName: null,
                                insuranceCost: null,
                                status: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('edi-insurance', null, { reload: 'edi-insurance' });
                }, function() {
                    $state.go('edi-insurance');
                });
            }]
        })
        .state('edi-insurance.edit', {
            parent: 'edi-insurance',
            url: '/{id}/edit',
            data: {
                authorities: ['AU_EDI_INSURANCE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/edi-insurance/edi-insurance-dialog.html',
                    controller: 'EdiInsuranceDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xxl',
                    resolve: {
                        entity: ['EdiInsurance', 'WinAES', function(EdiInsurance, WinAES) {
                            return EdiInsurance.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('edi-insurance', null, { reload: 'edi-insurance' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('edi-insurance.delete', {
            parent: 'edi-insurance',
            url: '/{id}/delete',
            data: {
                authorities: ['AU_EDI_INSURANCE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/edi-insurance/edi-insurance-delete-dialog.html',
                    controller: 'EdiInsuranceDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['EdiInsurance', 'WinAES', function(EdiInsurance, WinAES) {
                            return EdiInsurance.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('edi-insurance', null, { reload: 'edi-insurance' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
