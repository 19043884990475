(function () {
    'use strict';

    angular
        .module('autopsApp')
        .controller('SynOrderDialogController', SynOrderDialogController);

    SynOrderDialogController.$inject = ['$rootScope', '$scope', '$uibModalInstance', 'entity', 'GridState', '$timeout', '$translate', 'OpsFiles', 'DataUtils'];

    function SynOrderDialogController($rootScope, $scope, $uibModalInstance, entity, GridState, $timeout, $translate, OpsFiles, DataUtils) {
        var vm = this;

        $scope.gridState = entity;
        vm.clear = clear;
        vm.save = save;
        vm.addHead = addHead;
        vm.delHead = delHead;
        vm.changeType = changeType;
        vm.headFields = [];

        function initGridState() {
            if ($scope.gridState.gridState) {
                vm.data = angular.fromJson($scope.gridState.gridState);
            }
            if (!vm.data) {
                vm.data = [];
            }
            $scope.gridState.synDirection = $scope.gridState.synDirection + "";
            changeType();
        }

        $timeout(function () {
            initGridState();
        })

        function reloadGridState(gridStateId) {
            if (!gridStateId) return;
            GridState.getOneSynBranch({ configurationId: gridStateId }, function (result) {
                $scope.gridState = result;
                initGridState();
            }, $rootScope.ALERT_ERROR)
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
        function save() {
            vm.data = [];
            angular.forEach(vm.headFields, function(item) {
                if (item.check) {
                    vm.data.push(item.name);
                }
            })
            if(!$scope.gridState.venderName){
                $scope.gridState.venderId = null;
            }
            $scope.gridState.gridState = angular.toJson(vm.data);
            GridState.updateCustomExcelExport($scope.gridState, function (result) {
                layer.msg($translate.instant('global.save_success'));
                reloadGridState(result.id);
            }, $rootScope.ALERT_ERROR)
        }
        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function (date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        function addHead() {
            vm.data.heads.push({
                field: null,
                location: ""
            })
        }

        function delHead(head) {
            var index = vm.data.heads.indexOf(head);
            if (index > -1) {
                vm.data.heads.splice(index, 1);
            };
        }

        // 需要自定义名称的字段。名称填写在i18n autopsApp.gridState.allField 下
        var guideOtherField = ["vgm", "hblRlsWay", "voy", "BKdivVolBy", "BKvw", "carrier", "polName", "podName", "destName", "ataTime", "ctnAvailableTime", 
        "bookingTrucks", "bookingCtns", "bookingCustoms", "guideCtns", "guideFclCtns", "mblNum", "warehousingDatas"];

        var userIdSet = ["salesId", "opId", "docId", "finId", "cusId", "overseasCsId", "checkById"];
        var bkRoutePorts = ["BKporName", "BKpolName", "BKpodName", "BKdestName", "BKdeliveryTo", "BKfreightTerm", "BKserviceTerm", "hblRlsWay"];
        function changeType() {
            vm.headFields = [];
            vm.headFieldValue = ["shipmentType", "poNum", "fbaRef", "shipmentId", "soNum", "BKsoNum", "hblNum", "mblNum", "etd", "atd", "cargoReadyDate", "eta", "vsl", "voy",
                    "carrier", "productName", "deliveryWay", "deliveryChannel", "BKcarrierFullName", "tkState", "cargoType", "pkgNum", "BKpkgType", "gw", "vol", "chargeWeight", "BKdivVolBy",
                    "vw", "BKvolumeShare", "BKpkgNum", "BKgw", "BKvol", "whName", "chargeByCbm", "customsType", "remarks", "BKremarks", "bizType", "internalType", "customerName",
                    "shipperName", "deliveryName", "clearanceAgent", "cargoName", "BKhsCode", "pieces", "BKtradeTerm", "cargoValue", "siCutTime", "cls", "billDate", "bookingEtd",
                    "deliveryDate", "freightTerm", "serviceTerm", "sayCtns", "BKporName", "BKpolName", "BKpodName", "BKdestName", "BKdeliveryTo",
                    "BKimporterName", "BKimporterTaxId", "BKimporterEori", "BKimporterAddress", "BKshipperBl", "BKcneeBl", "BKnotifyBl", "BKdeliveryBl", "BKmark", "BKgoodsDiscription",
                    "deliveryBl", "shipfromCode", "serviceDescription", "shiptoCode", "BKbookingToAttn", "BKhblNum", "destCountryCode", "shiptoPostcode", "BKbookingToEmail", "BKbookingToFax",
                    "BKbookingToAddress", "BKbookingToMob", "BKbookingToTel", 'customRequired', 'truckRequired', 'insuranceRequired', 'fumigationRequired', 'warehouseRequired', 'coRequired', 
                    "status", "checkStatus", "submited", "plConfirmed", "cwLoked",
                    "bookingBy", "bookingTime", "soReleaseTime", "truckArrangeTime", "customArrangeTime", "siSubmitedTime",
                    "hblConfirmedTime", "mblConfirmedTime", "dnSentTime", "saSentTime", "hblReleasedTime", "mblReleasedTime", "invoiceIssuedTime", "invoiceReceivedTime", "paymentPaidTime",
                    "paymentReceivedTime", "localTimeTwo", "otherTimeOne", "otherTimeTwo", "otherTimeThree", "plConfirmTime", "vgmTime", "ctnsLoadedTime", "customReleaseTime",
                    "deliveryTime", "customClearTime", "ataTime", "deliveryAppointmentTime", "directDelivery",
                    "ctnAvailableTime", "ctnReturnTime", "wmsActAmt", "wmsGw", "wmsVol", "wmsCw", 
                    "salesId", "opId", "docId", "finId", "cusId", "overseasCsId", "checkById", "lfd"];
            if ($scope.gridState.gridId < 47) {
                vm.headFieldValue.push("lane", "soExpectedTime", "BKwmsNum", "dock", "polRegion", "podRegion", "vgm", "BKserviceTerm", "hblRlsWay", "BKfreightTerm", "awbCharges",
                    "porName", "polName", "podName", "destName", "deliveryTo", "releaseWay");
            }
            // List数据，放在最后
            vm.headFieldValue.push("bookingTrucks", "bookingCtns", "bookingCustoms", "guideCtns", "guideFclCtns", "warehousingDatas", "cusFields", "orderNodes", "chargesExp", "chargesRev");

            angular.forEach(vm.headFieldValue, function (item) {
                var data = {};
                data.name = item;
                if (item == 'templateName') {
                    data.langName = $translate.instant('autopsApp.gridState.allField.templateName');
                }
                else if (item == 'chargeByCbm') {
                    data.langName = $translate.instant('autopsApp.guide.chargeByCbm');
                }
                else if (item == 'overseasCsId') {
                    data.langName = $translate.instant('autopsApp.guide.overseasCs');
                }
                else if (item == 'finId') {
                    data.langName = $translate.instant('autopsApp.guide.account');
                }
                else if (userIdSet.indexOf(item) !== -1) {
                    data.langName = $translate.instant('autopsApp.guide.' + item.replace("Id", "Name"));
                }
                else if (guideOtherField.indexOf(data.name) != -1) {
                    data.langName = $translate.instant('autopsApp.gridState.allField.guide.' + data.name);
                } else {
                    DataUtils.getLangNameFba(data);
                }
                if ($scope.gridState.gridId < 47 && bkRoutePorts.indexOf(item) !== -1) {
                    data.langName = data.langName + ($scope.gridState.gridId == 42?"(HAWL)":"(HBL)");
                }
                if(vm.data.indexOf(data.name) != -1) {
                    data.check = true;
                } else {
                    data.check = false;
                }
                vm.headFields.push(data);
            })
        }

        vm.synDirections = [
            { value: null, label: $translate.instant('autopsApp.gridState.synDirection.both') },
            { value: true, label: $translate.instant('autopsApp.gridState.synDirection.fmTo') },
            { value: false, label: $translate.instant('autopsApp.gridState.synDirection.toFm') }
        ];

    }
})();
