(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsCompanyDetailController', OpsCompanyDetailController);

    OpsCompanyDetailController.$inject = ['$scope', '$rootScope', '$uibModal', '$translate', '$state', '$location', '$http',
        'OpsPorts', 'OpsCompany', 'fileReader', 'OpsExchangeRate', 'JhiSignature', 'HfBill', 'JhiLanguageService', 'AccountTitle',
        'AccountBind', 'DataUtils'
    ];

    function OpsCompanyDetailController($scope, $rootScope, $uibModal, $translate, $state, $location, $http,
        OpsPorts, OpsCompany, fileReader, OpsExchangeRate, JhiSignature, HfBill, JhiLanguageService, AccountTitle,
        AccountBind, DataUtils) {
        $scope.opsCompany = {};
        var companyCopy = {};
        $scope.clientFiles = [];
        var loadCompany = function() {
            OpsCompany.getSetDTO({
                companyId: $rootScope.id
            }, function(result) {
                $scope.opsCompany = result;
                $scope.opsCompany.deposit = DataUtils.round($scope.opsCompany.deposit, 2);
                $scope.opsCompany.vasDeposite = DataUtils.round($scope.opsCompany.vasDeposite, 2);
                if(!$scope.opsCompany.deposit){
                    $scope.opsCompany.deposit = 0.00;
                }
                if(!$scope.opsCompany.vasDeposite){
                    $scope.opsCompany.vasDeposite = 0.00;
                }
                companyCopy = angular.copy($scope.opsCompany);
            });
        };
        loadCompany();

        $scope.setSubsysView = function() {
            $rootScope.setSubsysView = true;
        }

        var onSaveSuccess = function(result) {
            layer.msg($translate.instant('global.messages.submitted'));
            $scope.isSaving = false;
            companyCopy = angular.copy($scope.opsCompany);
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
        };

        $scope.save = function() {
            if (angular.equals($scope.opsCompany, companyCopy)) {
                layer.msg($translate.instant('global.no_amendmend'));
                return;
            }
            $scope.isSaving = true;
            if ($scope.opsCompany.jobNumLength > 10) {
                $scope.opsCompany.jobNumLength = 10;
            }
            if ($scope.opsCompany.jobNumLength < 3) {
                $scope.opsCompany.jobNumLength = 3;
            }
            if ($scope.opsCompany.blPrefix) {
                $scope.opsCompany.blPrefix = $scope.opsCompany.blPrefix.replace(/\s+/g, "");
            }
            OpsCompany.updateSetDTO($scope.opsCompany, onSaveSuccess, onSaveError);
        };

        $scope.exchangeRate = function() {
            OpsExchangeRate.getEffectiveExchangeRates(function(result) {
                $uibModal.open({
                    templateUrl: 'app/entities/ops-exchange-rate/ops-exchange-rates-dialog.html',
                    controller: 'OpsExchangeRatesDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'md',
                    resolve: {
                        entity: [function() {
                            return result;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('opsExchangeRate');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function(results) {

                }, function() {

                });
            })
        }

        $scope.generate = function() {
            $scope.opsCompany.blShow = angular.uppercase($scope.opsCompany.enName) + "\n" +
                angular.uppercase($scope.opsCompany.addressEn) + "\n" +
                "TEL: " + angular.uppercase($scope.opsCompany.tel) + "\n" +
                "FAX: " + angular.uppercase($scope.opsCompany.fax);
        };

        $scope.uppercaseBlShow = function() {
            $scope.opsCompany.blShow = angular.uppercase($scope.opsCompany.blShow);
        }


        $scope.uploadLogo = function() {
            $scope.logoBox = layer.open({
                type: 1,
                area: ['400px', '400px'],
                maxmin: true,
                title: $translate.instant('global.upload_logo'),
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#logoTemplate")
            });
        }

        $scope.logoFlag = false;
        $scope.logo = "";
        $scope.upload = function() {
            if ($scope.logo == "") {
                console.log("logo is null");
                return;
            }
            var fd = new FormData();
            fd.append('logo', $scope.logo);
            $http.post('api/account/uploadCompanyLogo/' + $scope.opsCompany.id, fd, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined },
            }).success(function(data) {
                if (angular.isDefined(data.filepath)) {
                    layer.msg($translate.instant('global.uploadSuccess'));
                }
                $scope.logoTemp = null;
                $("#previewPic").attr("src", "");
                $scope.logoFlag = true;
                layer.close($scope.logoBox);
                if (data.fileurl) {
                    $rootScope.account.companyLogo = data.fileurl;
                }
            }).error(function(error) {
                layer.msg($translate.instant('global.uploadFail'));
                $scope.logoFlag = true;
                console.log("上传文件失败");
            });
        };

        $scope.changeLogo = function() {
            $("input[name=logo]").trigger('click');
        }
        $scope.cancelLogo = function() {
            layer.close($scope.logoBox);
        }
        $scope.previewLogo = function(file) {
            fileReader.readAsDataUrl(file, $scope)
                .then(function(result) {
                    $scope.logoTemp = result;
                });
        }
        $scope.portList = [];

        var initPorts = function() {
            $scope.portList = $rootScope.OPSPORTS.seaports;
            angular.forEach($rootScope.OPSPORTS.airports, function(data) {
                $scope.portList.push(data);
            })
        }

        initPorts();
        $scope.loadPorts = function() {
            if ($rootScope.OPSPORTS.airports && $rootScope.OPSPORTS.airports.length > 0) {
                return;
            }
            OpsPorts.getAll(function(result) {
                $rootScope.OPSPORTS.airports = [];
                $rootScope.OPSPORTS.seaports = [];
                angular.forEach(result, function(data) {
                    if (data.type == 1) {
                        $rootScope.OPSPORTS.seaports.push(data);
                    } else {
                        $rootScope.OPSPORTS.airports.push(data);
                    }
                })
                initPorts();
            })
        };
        $scope.bankAccounts = function() {
            $uibModal.open({
                templateUrl: 'app/entities/jhiSignature/jhiSignature-dialog.html',
                controller: 'JhiSignatureDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return JhiSignature.query({ companyId: $scope.opsCompany.id }).$promise
                    }]
                }
            }).result.then(function(results) {

            }, function() {

            });
        }

        $scope.hfBills = function(type) {
            var entity = {};
            entity.serviceType = type;
            entity.opsCompany = $scope.opsCompany;
            $uibModal.open({
                templateUrl: 'app/entities/hf-bill/hf-bill-delete-dialog.html',
                controller: 'HfBillDeleteController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsCompany');
                        $translatePartialLoader.addPart('hfBill');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {

            }, function() {

            });
        }

        $scope.portSelected = function(port) {
            $scope.opsCompany.defaultPolId = port.code;
        }
        var lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            lang = language;
        });


        $scope.subLedgers = [];
        $scope.loadSubLedgers = function() {
            if ($scope.subLedgers && $scope.subLedgers.length > 0) {
                return;
            }
            AccountTitle.getAll({
                type: "part"
            }, function(result) {
                $scope.subLedgers = result;
                $scope.subLedgers = DataUtils.generateShowLedger($scope.subLedgers, true, lang);
            });
        }


        $scope._onItemSelected = function(data) {
            $scope.opsCompany.overdueItemId = data.id;
        }

        function contains(file) {
            file = angular.lowercase(file);
            var regx = new Array('jpg', 'png', 'jpeg', 'bmp', 'tiff');
            for (var m = 0; m < regx.length; m++) {
                if (file == regx[m]) {
                    return true;
                }
            }
            return false;
        }

        $scope.uploadCompanyPic = function(file) {
            if (!file) {
                return;
            }
            var fileSplit = file.name.split(".");
            if (!contains(fileSplit[fileSplit.length - 1])) {
                layer.msg($translate.instant('global.logo_warn1'));
                return;
            }
            $rootScope.UPLOAD_FILE_WITH_NAME(file, "/companyPic/", [], $rootScope.account.companyId, "companyPic");
        };

        $scope.deleteCompanyPic = function() {
            OpsCompany.deleteCompanyPic({ companyId: $scope.opsCompany.id }, function(data) {
                layer.msg($translate.instant('global.delete_success'));
            })
        }

        $scope.amendNum = function(fd) {
            layer.prompt({
                formType: 0,
                value: null,
                title: $translate.instant('autopsApp.opsCompany.amendNum'),
                area: ['280px', '160px']
            }, function(value, index, elem) {
                if (isNaN(value) || parseInt(value) != value || parseInt(value) < 0) {
                    layer.msg($translate.instant('global.inputNumOnlyAlert'));
                    return;
                }
                OpsCompany.amendNum({
                    companyId: $scope.opsCompany.id,
                    jobNum: fd == 'jobNum' ? value : null,
                    hblNum: fd == 'hblNum' ? value : null
                }, function(result) {
                    $scope.opsCompany[fd] = value - 0;
                    layer.msg($translate.instant('global.save_success'));
                }, function(result) {
                    layer.msg($translate.instant('global.save_fail'));
                })
                layer.close(index);
            });
        }

        $scope.functionSettings = function() {
            if(!$scope.opsCompany.id){
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/company/opsCompany/opsCompany-function-settings-dialog.html',
                controller: 'OpsCompanyFunctionSettingsDialogController',
                controllerAs: 'vm',
                backdrop: "static",
                size: 'md',
                resolve: {
                    entity: [function() {
                        return $scope.opsCompany;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsCompany');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {

            }, function() {

            }); 
        }
        
        $scope.analyzeLogFile = function(file) {
            if (!file) {
                return;
            }
            var fd = new FormData();
            fd.append('file', file);
            $http.post(
                'api/ops-files/analyzeLogFile', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    var fileName = file.name.lastIndexOf(".") == -1 ? file.name : file.name.substring(0, file.name.lastIndexOf(".")) + "_result.txt";
                    $rootScope.DOWNLOAD_TXT(data, fileName); 
                    layer.msg($translate.instant('global.uploadSuccess'));
                }).error(function(error) {
                    layer.msg($translate.instant('global.uploadFail'));
            });
        }


    }
})();
