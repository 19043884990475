(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('ops-carrier', {
            parent: 'entity',
            url: '/ops-carrier?page&sort&search',
            data: {
                authorities: ['FUN_CARRIER'],
                pageTitle: 'autopsApp.opsCarrier.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/ops-carrier/ops-carriers.html',
                    controller: 'OpsCarrierController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opsCarrier');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('commodityDatabase');
                    return $translate.refresh();
                }]
            }
        })
        .state('product-channel', {
            parent: 'entity',
            url: '/ops-channel?page&sort&search',
            data: {
                authorities: ['FUN_CARRIER'],
                pageTitle: 'autopsApp.opsCarrier.home.product-channel'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/ops-carrier/ops-products.html',
                    controller: 'OpsProductController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opsCarrier');
                    $translatePartialLoader.addPart('opsCost');                    
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('jsExpression');    
                    $translatePartialLoader.addPart('commodityDatabase');      
                    return $translate.refresh();
                }]
            }
        })
        .state('ops-carrier.new', {
            parent: 'ops-carrier',
            url: '/new',
            data: {
                authorities: ['FUN_CARRIER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ops-carrier/ops-carrier-dialog.html',
                    controller: 'OpsCarrierDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'sm',
                    resolve: {
                        entity: function () {
                            return {
                                type: null,
                                name: null,
                                code: null,
                                remarks: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('ops-carrier', null, { reload: 'ops-carrier' });
                }, function() {
                    $state.go('ops-carrier');
                });
            }]
        })
        .state('ops-carrier.edit', {
            parent: 'ops-carrier',
            url: '/{id}/edit',
            data: {
                authorities: ['FUN_CARRIER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ops-carrier/ops-carrier-dialog.html',
                    controller: 'OpsCarrierDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'sm',
                    resolve: {
                        entity: ['OpsCarrier', 'WinAES', function(OpsCarrier, WinAES) {
                            return OpsCarrier.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('ops-carrier', null, { reload: 'ops-carrier' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('product-channel.new', {
            parent: 'product-channel',
            url: '/new',
            data: {
                authorities: ['FUN_CARRIER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ops-carrier/ops-carrier-detail.html',
                    controller: 'OpsCarrierDetailController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xl',
                    resolve: {
                        entity: function () {
                            return {
                                type: 0
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('product-channel', null, { reload: 'product-channel' });
                }, function() {
                    $state.go('product-channel');
                });
            }]
        })
        .state('product-channel.edit', {
            parent: 'product-channel',
            url: '/{id}/edit',
            data: {
                authorities: ['FUN_CARRIER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ops-carrier/ops-carrier-detail.html',
                    controller: 'OpsCarrierDetailController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xl',
                    resolve: {
                        entity: ['OpsCarrier', 'WinAES', function(OpsCarrier, WinAES) {
                            return OpsCarrier.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('product-channel', null, { reload: 'product-channel' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('product-channel.copy', {
            parent: 'product-channel',
            url: '/{id}/copy',
            data: {
                authorities: ['FUN_CARRIER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ops-carrier/ops-carrier-detail.html',
                    controller: 'OpsCarrierDetailController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xl',
                    resolve: {
                        entity: ['OpsCarrier', 'WinAES', function(OpsCarrier, WinAES) {
                            return OpsCarrier.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('product-channel', null, { reload: 'product-channel' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('ops-carrier.delete', {
            parent: 'ops-carrier',
            url: '/{id}/delete',
            data: {
                authorities: ['FUN_CARRIER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ops-carrier/ops-carrier-delete-dialog.html',
                    controller: 'OpsCarrierDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['OpsCarrier', 'WinAES', function(OpsCarrier, WinAES) {
                            return OpsCarrier.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('ops-carrier', null, { reload: 'ops-carrier' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('product-channel.delete', {
            parent: 'product-channel',
            url: '/{id}/delete',
            data: {
                authorities: ['FUN_CARRIER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ops-carrier/ops-carrier-delete-dialog.html',
                    controller: 'OpsCarrierDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['OpsCarrier', 'WinAES', function(OpsCarrier, WinAES) {
                            return OpsCarrier.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('product-channel', null, { reload: 'product-channel' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
