(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AssistChargeDialogController', AssistChargeDialogController);

    AssistChargeDialogController.$inject = ['$timeout', '$rootScope', '$scope', '$stateParams', '$uibModalInstance', '$translate', 
    'entity', 'AssistCharge', 'GuideRates', 'DataUtils', 'OpsVender', 'OpsExchangeRate', 'DateUtils', 'Dictionary', 'Guide'];

    function AssistChargeDialogController ($timeout, $rootScope, $scope, $stateParams, $uibModalInstance, $translate, 
        entity, AssistCharge, GuideRates, DataUtils, OpsVender, OpsExchangeRate, DateUtils, Dictionary, Guide) {
        var vm = this;        
        vm.clear = clear;
        vm.save = save;
        vm.getShipment = getShipment;
        vm.autoInput = autoInput;

        $scope.guide;
        $scope.shipmentType = entity.type;

        var exchangeRates;
        var getExchangeRatesMap = function() {
            if(exchangeRates && exchangeRates.length > 0){
                updateTotal();
                return;
            }
            if(!$scope.guide){
                return;
            }
            $rootScope.loadCurrencys();
            var dateCopy = $scope.guide.etd;
            if (!dateCopy) {
                dateCopy = new Date();
            }
            OpsExchangeRate.getExchangeRatesMap({
                date: DateUtils.convertLocalDateToServer(dateCopy)
            }, function(result) {
                exchangeRates = result;
                updateTotal();
            })
        }
        var guideRatesCopy;
        $timeout(function (){
           getShipment();           
        });
        function getShipment () {
            AssistCharge.getShipment({
                id : entity.id,
                type: entity.type
            }, function(result){
                $scope.guide = result;
                guideRatesCopy = angular.copy($scope.guide.guideRates);
                getExchangeRatesMap();
                $translate.refresh();
            });            
        }
        function autoInput() {
            if(!guideRatesCopy || guideRatesCopy.length == 0){
                $scope.addOne(true);
            }
            if (!$scope.guide.assistCharges) {
                $scope.guide.assistCharges = [];
            }
            var items = new Set();
            angular.forEach($scope.guide.assistCharges, function(data){
                if(data.item && !items.has(data.item)){
                    items.add(data.item);
                }
            })
            angular.forEach(guideRatesCopy, function(data){
                if(data.item && !items.has(data.item)){
                    items.add(data.item);
                    var rate = {
                        "item": data.item,
                        "unit": data.unit,
                        "count": data.count,
                        "payCurrency": data.payCurrency,
                        "isPay": true
                    };
                    if (rate.isPay) {
                        rate.venderId = $scope.guide.bookingToId;
                        rate.payer = $scope.guide.bookingToName == null ? "" : $scope.guide.bookingToName;
                    } else {
                        rate.venderId = $scope.shipmentType == "wms" ? $scope.guide.venderId : $scope.guide.shipperId;
                        rate.payer = $scope.shipmentType == "wms" ? $scope.guide.clientName : $scope.guide.shipperName;
                        if($scope.guide.isVirtual && $scope.guide.medCompanyId != $rootScope.account.companyId){
                            rate.venderId = $scope.guide.medVenderId;
                            rate.payer = $scope.guide.medCompany;
                        }
                    }
                    $scope.guide.assistCharges.push(rate);
                    $translate.refresh();
                }
            })
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        vm.isSaving = false;  
        function save () {
            if (vm.isSaving) {
                return;        
            }
            if ($rootScope.HAVENOAUTH('AU_ASSIST_CHARGE')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_ASSIST_CHARGE');
                return;        
            }
            vm.isSaving = true;            
            AssistCharge.saveShipment({
                id : entity.id,
                type: entity.type
            }, $scope.guide.assistCharges, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $scope.guide = result;
            $scope.guide.guideRates = guideRatesCopy;
            getExchangeRatesMap();
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submitted'));
        }

        function onSaveError () {
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submittedfailed'));
        }
        var isReverse = true;
        $scope.sortRates = function(fd) {
            isReverse = !isReverse;
            $scope.guide.assistCharges.sort(function(a, b) {
                if (isReverse) {
                    if(!a[fd]){
                        return 1;
                    }
                    return a[fd] < b[fd] ? 1 : -1;
                } else {
                    if(!b[fd]){
                        return 1;
                    }
                    return a[fd] > b[fd] ? 1 : -1;
                }
            });
        }
        $scope.addOne = function(isPay) {
            if (!$scope.guide.assistCharges) {
                $scope.guide.assistCharges = [];
            }
            var rate = {
                "payCurrency": $rootScope.account.baseCurrency,
                "unit": "BL",
                "count": 1,
                "isPay": isPay
            };
            if (isPay) {
                rate.venderId = $scope.guide.bookingToId;
                rate.payer = $scope.guide.bookingToName == null ? "" : $scope.guide.bookingToName;
            } else {
                rate.venderId = $scope.shipmentType == "wms" ? $scope.guide.venderId : $scope.guide.shipperId;
                rate.payer = $scope.shipmentType == "wms" ? $scope.guide.clientName : $scope.guide.shipperName;
                if($scope.guide.isVirtual && $scope.guide.medCompanyId != $rootScope.account.companyId){
                    rate.venderId = $scope.guide.medVenderId;
                    rate.payer = $scope.guide.medCompany;
                }
            }
            $scope.guide.assistCharges.push(rate);
            $translate.refresh();
        };
        var units = Dictionary.autoPriceUnits;
        $scope.rateUnitChange = function(rate) {
            if(units.indexOf(rate.unit) === -1){
                rate.autoUpdate = false;
            }
            if(rate.unit != "SHIPMENT"){
                rate.refType = null;
            }
            if(rate.unit == "CTNTYPE VAR" && $scope.guide.booking && $scope.guide.guideFclCtns){
                ctnTypeVarInput(rate);
            }
            else {
                rate.count = DataUtils.round(DataUtils.getUnitCount(rate, $scope.shipmentType, $scope.guide), 4);
                $scope.amountChange(rate);
            }
        }
        
        var ctnTypeVarInput = function(rate) {
            var ctnTypeSet = new Set();
            angular.forEach($scope.guide.guideFclCtns, function(data) {
                if (data.ctnType) {
                    ctnTypeSet.add(data.ctnType);
                }
            })
            var isFirst = true;
            ctnTypeSet.forEach(function(ctnType){ 
                if(isFirst){
                    rate.unit = ctnType;
                    rate.count = DataUtils.round(DataUtils.getUnitCount(rate, $scope.shipmentType, $scope.guide), 4);
                    $scope.amountChange(rate);
                    isFirst = false;
                }
                else {
                    var typeExist = false;
                    angular.forEach($scope.guide.assistCharges, function(data) {
                        if (data.isPay == rate.isPay 
                                && data.unit == ctnType 
                                && data.itemId == rate.itemId 
                                && data.venderId == rate.venderId) {
                            typeExist = true;
                        }
                    })
                    if(!typeExist){
                        var tempRate = angular.copy(rate);
                        tempRate.id = null;
                        tempRate.actual = null;
                        tempRate.inputBy = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                        tempRate.invoiceNum = null;
                        tempRate.dnNum = null;
                        tempRate.exchangeRate = null;
                        tempRate.remarks = null;
                        tempRate.locked = null;
                        tempRate.voucherId = null;
                        tempRate.isHidden = false;
                        tempRate.writeOffCode = null;
                        tempRate.inputTime = null;
                        tempRate.lastModifiedBy = null;
                        tempRate.audited = null;
                        tempRate.lastModifiedTime = null;
                        tempRate.billNum = null;
                        tempRate.sharedOn = null;
                        tempRate.accountDate = null;
                        tempRate.unit = ctnType;
                        tempRate.count = DataUtils.round(DataUtils.getUnitCount(tempRate, $scope.shipmentType, $scope.guide), 4);
                        $scope.amountChange(tempRate);
                        $scope.guide.assistCharges.push(tempRate);
                    }
                }                    
            })
        }
        $scope.amountChange = function(rate) {
            if(rate.price == null){
                rate.payAmount = null;
                return;
            }
            rate.price = DataUtils.round(rate.price, 4);
            if (null == rate.count || rate.count < 0) {
                rate.count = 0.00;
            }
            rate.payAmount = DataUtils.round(rate.count * rate.price, 2);
            updateTotal();
        }
        var updateTotal = function() {            
            if(!exchangeRates && exchangeRates.length == 0){
                getExchangeRatesMap();
                return;
            }

            $scope.costTotalList = [];
            $scope.sellingTotalList = [];
            $scope.profitList = [];
            $scope.profit = {currency: $rootScope.account.baseCurrency};

            var chargesCopy = angular.copy($scope.guide.assistCharges);
            angular.forEach($scope.guide.guideRates, function(data){
                if(!data.isPay){
                    chargesCopy.push(data);
                }
            })

            var currencys = [];
            angular.forEach(chargesCopy, function(data) {
                if (data.payCurrency && currencys.indexOf(data.payCurrency) === -1) {
                    currencys.push(data.payCurrency);
                }
            })
            var cost = 0;
            var selling = 0;
            var profit = 0;
            var roeAmt = 1;
            var profitTtl = 0;
            angular.forEach(currencys, function(currency) {
                cost = 0;
                selling = 0;
                profit = 0;
                roeAmt = exchangeRates[currency];
                angular.forEach(chargesCopy, function(rate) {
                    if (rate.payCurrency == currency && rate.payAmount) {
                        if (rate.isPay) {
                            cost = cost + rate.payAmount;
                            if(rate.exchangeRate){
                                profitTtl = profitTtl - (rate.payAmount * rate.exchangeRate);
                            }
                            else {
                                profitTtl = profitTtl - (rate.payAmount * roeAmt);
                            }
                            
                        } else {
                            selling = selling + rate.payAmount;
                            if(rate.exchangeRate){
                                profitTtl = profitTtl + (rate.payAmount * rate.exchangeRate);
                            }
                            else{
                                profitTtl = profitTtl + (rate.payAmount * roeAmt);
                            }
                            
                        }                        
                    }
                })
                $scope.costTotalList.push({
                    currency: currency,
                    total: DataUtils.round(cost, 2)
                });

                $scope.sellingTotalList.push({
                    currency: currency,
                    total: DataUtils.round(selling, 2)
                });
                profit = selling - cost;

                $scope.profitList.push({
                    currency: currency,
                    total: DataUtils.round(profit, 2)
                });

                $scope.profit.ammount = DataUtils.round(profitTtl, 2);
            })
        }
        $scope.payAmountCheck = function(rate) {
            updateTotal();
        }
        // $scope._onPayerSelected = function(data, rate) {
        //     rate.venderId = data.id;
        // }
        // $scope.loadDefultPayer = function(id, index, rate) {
        //     if (id && index != null) {
        //         $("#" + id + index).select();
        //     }
        //     if (rate && !rate.payer) {
        //         rate.payer = "";
        //     }
        //     if (!$scope.payers || $scope.payers.length == 0) {
        //         GuideRates.getDefaultPayer({
        //             id: $scope.guide.id,
        //             companyId: $rootScope.account.companyId,
        //             type: $scope.shipmentType
        //         }, function(result){
        //             $scope.payers = result;
        //         })
        //     }
        // };
        // $scope.loadAllPayer = function() {
        //     OpsVender.query(function(result) {
        //         $rootScope.OPSVENDERS = result;
        //         $scope.payers = result;
        //         layer.msg($translate.instant('entity.action.loadSuccess'));
        //     });
        // }
        $scope.deleteRate = function(rate) {
            if (!rate.id) {
                var index = $scope.guide.assistCharges.indexOf(rate);
                if (index > -1) {
                    $scope.guide.assistCharges.splice(index, 1);
                };
                updateTotal();
                $translate.refresh();
                return;
            };
            if ($rootScope.HAVENOAUTH('AU_ASSIST_CHARGE')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_ASSIST_CHARGE');
                return;        
            }
            var content = $translate.instant('autopsApp.booking.bookingPrice.delete.question');
            layer.confirm(content, {
                icon: 3,
                title: $translate.instant('autopsApp.booking.bookingPrice.delete.title'),
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                if (rate.id) {
                    AssistCharge.delete({
                        id: rate.id
                    });
                };
                var index = $scope.guide.assistCharges.indexOf(rate);
                if (index > -1) {
                    $scope.guide.assistCharges.splice(index, 1);
                };
                updateTotal();
                $translate.refresh();
            }, function() {
                return;
            });
        };



        $scope._onItemSelected = function(data, rate) {
            if ($translate.instant("autopsApp.booking.opsItem.cnen") == "nameEn") {
                rate.item = data.nameEn;
            } else {
                rate.item = data.nameCn;
            };
            if (data.currency) {
                rate.currency = data.currency;
                rate.payCurrency = data.currency;
            }
            if (data.unit) {
                rate.unit = data.unit;
            }
            if(units.indexOf(rate.unit) === -1){
                rate.autoUpdate = false;
            }
            if(data.price){
                rate.price = data.price;
            }
            if(rate.unit == "CTNTYPE VAR"){
                if($scope.guide.booking && $scope.guide.guideFclCtns){
                    DataUtils.ctnTypeVarInput(rate, data, $scope.guide, $scope.shipmentType);
                }    
            }
            else {
                rate.count = DataUtils.round(DataUtils.getUnitCount(rate, $scope.shipmentType, $scope.guide), 4);
                $scope.amountChange(rate);
            }
        }
        $scope.assistChargesConfirmed = function() {
            // if($rootScope.HAVENOAUTH('AU_ASSIST_CHARGES_CONFIRM')){
            //     $rootScope.FORBIDDEN_BY_ALERT('AU_ASSIST_CHARGES_CONFIRM');
            //     return;
            // }
            Guide.assistChargesConfirmed({
                guideId: $scope.guide.id
            }, function(result) {
                $scope.guide.assistChargesConfirmed = result.assistChargesConfirmed;
                $scope.guide.assistChargeReviewer = result.assistChargeReviewer;
                layer.msg($translate.instant('global.messages.submitted'));
            }, $rootScope.ALERT_ERROR);
        }

        

    }
})();
