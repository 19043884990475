(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('sea-import', {
                parent: 'ops',
                url: '/sea-import?page&sort&search',
                data: {
                    authorities: ['ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_SALES', 'ROLE_DOCUMENT', 'ROLE_CS', 'ROLE_MARKET'],
                    pageTitle: 'global.menu.entities.seaImport'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/import/sea.html',
                        controller: 'SeaImportController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            })     
            .state('air-import', {
                parent: 'ops',
                url: '/air-import?page&sort&search',
                data: {
                    authorities: ['ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_SALES', 'ROLE_DOCUMENT', 'ROLE_CS', 'ROLE_MARKET'],
                    pageTitle: 'global.menu.entities.airImport'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/import/air.html',
                        controller: 'AirImportController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            })     
            .state('all-import', {
                parent: 'ops',
                url: '/all-import',
                data: {
                    authorities: ['ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_SALES', 'ROLE_DOCUMENT', 'ROLE_CS', 'ROLE_MARKET'],
                    pageTitle: 'global.menu.entities.allImport'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/import/all.html',
                        controller: 'AllImportController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            })     
            .state('sea-import.detail', {
                parent: 'sea-import',
                url: '/dialog/{guideId}',
                data: {
                    authorities: ['ROLE_SALES', 'ROLE_CS', 'ROLE_MARKET', 'ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_DOCUMENT', 'ROLE_BOSS', 'ROLE_BRANCH']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/import/import-dialog.html',
                        controller: 'ImportDialogController',
                        backdrop: false,
                        size: 'xxxl',
                        resolve: {
                            entity: ['WinAES', 'Guide', function(WinAES, Guide) {
                                return Guide.get({id : WinAES.decode($stateParams.guideId)}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('guide');
                                $translatePartialLoader.addPart('booking');
                                $translatePartialLoader.addPart('wmsIn');
                                $translatePartialLoader.addPart('expressParcel');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^');
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('air-import.detail', {
                parent: 'air-import',
                url: '/dialog/{guideId}',
                data: {
                    authorities: ['ROLE_SALES', 'ROLE_CS', 'ROLE_MARKET', 'ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_DOCUMENT', 'ROLE_BOSS', 'ROLE_BRANCH']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/import/import-dialog.html',
                        controller: 'ImportDialogController',
                        backdrop: false,
                        size: 'xxxl',
                        resolve: {
                            entity: ['WinAES', 'Guide', function(WinAES, Guide) {
                                return Guide.get({id : WinAES.decode($stateParams.guideId)}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('guide');
                                $translatePartialLoader.addPart('booking');
                                $translatePartialLoader.addPart('wmsIn');
                                $translatePartialLoader.addPart('expressParcel');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^');
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('all-import.detail', {
                parent: 'all-import',
                url: '/dialog/{guideId}',
                data: {
                    authorities: ['ROLE_SALES', 'ROLE_CS', 'ROLE_MARKET', 'ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_DOCUMENT', 'ROLE_BOSS', 'ROLE_BRANCH']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/import/import-dialog.html',
                        controller: 'ImportDialogController',
                        backdrop: false,
                        size: 'xxxl',
                        resolve: {
                            entity: ['WinAES', 'Guide', function(WinAES, Guide) {
                                return Guide.get({id : WinAES.decode($stateParams.guideId)}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('guide');
                                $translatePartialLoader.addPart('booking');
                                $translatePartialLoader.addPart('wmsIn');
                                $translatePartialLoader.addPart('expressParcel');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^');
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('all-import.fba', {
                parent: 'all-import',
                url: '/fba-dialog/{guideId}',
                data: {
                    authorities: ['ROLE_SALES', 'ROLE_CS', 'ROLE_MARKET', 'ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_DOCUMENT', 'ROLE_BOSS', 'ROLE_BRANCH']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', '$rootScope', function($stateParams, $state, $uibModal, $rootScope) {
                    $uibModal.open({
                        templateUrl: ($rootScope.HAVEAUTH && $rootScope.HAVEAUTH("FB_WF_VIEW"))?'app/entities/guide/fba-role-dialog.html':'app/entities/guide/fba-dialog.html',
                        controller: 'FbaDialogController',
                        backdrop: false,
                        size: 'xxxl',
                        resolve: {
                            entity: ['WinAES', 'Guide', function(WinAES, Guide) {
                                return Guide.get({id : WinAES.decode($stateParams.guideId)}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('guide');
                                $translatePartialLoader.addPart('booking');
                                $translatePartialLoader.addPart('wmsIn');
                                $translatePartialLoader.addPart('expressParcel');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^');
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('all-import.wms', {
                parent: 'all-import',
                url: '/wms-dialog/{guideId}',
                data: {
                    authorities: ['ROLE_SALES', 'ROLE_CS', 'ROLE_MARKET', 'ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_DOCUMENT', 'ROLE_BOSS', 'ROLE_BRANCH']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', '$rootScope', function($stateParams, $state, $uibModal, $rootScope) {
                    $uibModal.open({
                        templateUrl: ($rootScope.HAVEAUTH && $rootScope.HAVEAUTH("FB_WF_VIEW"))?'app/entities/guide/fba-role-dialog.html':'app/entities/guide/fba-dialog.html',
                        controller: 'FbaDialogController',
                        backdrop: false,
                        size: 'xxxl',
                        resolve: {
                            entity: ['WinAES', 'Guide', function(WinAES, Guide) {
                                return Guide.get({id : WinAES.decode($stateParams.guideId)}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('guide');
                                $translatePartialLoader.addPart('booking');
                                $translatePartialLoader.addPart('wmsIn');
                                $translatePartialLoader.addPart('expressParcel');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^');
                    }, function() {
                        $state.go('^');
                    });
                }]
            });

    }
})();
