(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('AccountBind', AccountBind);

    AccountBind.$inject = ['$resource'];

    function AccountBind ($resource) {
        var resourceUrl =  'api/account-binds/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },                     
            'getAuopsKey': {
                method: 'GET',
                url:'api/account-binds/getAuopsKey',
                isArray: true
            },
            'getEdiAccounts': {
                method: 'GET',
                url:'api/account-binds/getEdiAccounts',
                isArray:true
            },
            'enableEdi': {
                method: 'PUT',
                url:'api/account-binds/enable'
            },
            'getEdiWarehouses': {
                method: 'POST',
                url:'api/api-wms/getEdiWarehouses',
                isArray:true
            },
            'getEdiChannels': {
                method: 'POST',
                url:'api/api-wms/getEdiChannels',
                isArray:true
            },
            'getAmazonRefreshToken': {
                method: 'POST',
                url:'api/account-binds/get-amazon-refresh-token'
            },
            'genAuthUrl': {
                method: 'POST',
                url:'api/account-binds/genAuthUrl'
            },     
            'tokenInfoPush': {
                method: 'GET',
                url:'api/account-binds/tokenInfoPush'
            },
            'getChukou1Token': {
                method: 'PUT',
                url:'api/account-binds/getChukou1Token'
            },
            'getAll': {
                method:'GET',
                url:'api/account-binds/getAll',
                isArray:true
            },
            'update': { method:'PUT' },
            'getTikTokToken': {
                method: 'POST',
                url:'api/account-binds/getTikTokToken'
            }
        });
    }
})();
