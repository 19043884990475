(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CommodityDatabaseDialogController', CommodityDatabaseDialogController);

    CommodityDatabaseDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', '$translate', 
    'entity', 'CommodityDatabase', 'CommodityCountry', 'Dictionary', 'OpsVender'];

    function CommodityDatabaseDialogController ($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, $translate, 
        entity, CommodityDatabase, CommodityCountry, Dictionary, OpsVender) {
        var vm = this;

        vm.commodityDatabase = entity;
        vm.clear = clear;
        vm.save = save;
        vm.addCommodityCountry = addCommodityCountry;
        vm.delCommodityCountry = delCommodityCountry;
        vm._onCargoSelected = _onCargoSelected;
        vm.getCustomsSuperviseCondition = getCustomsSuperviseCondition;
        vm.getInspectionCategory = getInspectionCategory;
        vm.findOneByHscode = findOneByHscode;
        vm.onCountrySelected = onCountrySelected;
        vm.auditCommDatabase = auditCommDatabase;
        vm.files = [];

        $timeout(function (){
            angular.element('#field_commodity').focus();
            initCommodityDatabase();
        });

        function clear () {
            $uibModalInstance.close(vm.commodityDatabase);
        }

        function save () {            
            if ($rootScope.account.companyId && $rootScope.HAVENOAUTH('EDIT_COMM_DATABASE')) {
                $rootScope.FORBIDDEN_BY_ALERT('EDIT_COMM_DATABASE');
                return;        
            }
            if(!vm.commodityDatabase.taxRefund){
                vm.commodityDatabase.taxRefund = false;
            }
            if(!vm.commodityDatabase.venderName){
                vm.commodityDatabase.venderId = null;
            }
            vm.isSaving = true;
            if (vm.commodityDatabase.id !== null) {
                CommodityDatabase.update(vm.commodityDatabase, onSaveSuccess, onSaveError);
            } else {
                CommodityDatabase.save(vm.commodityDatabase, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $scope.$emit('autopsApp:commodityDatabaseUpdate', result);
            // $uibModalInstance.close(result);
            vm.commodityDatabase = result;
            initCommodityDatabase();            
            layer.msg($translate.instant('global.save_success'));
        }
        function initCommodityDatabase () {
            if(!vm.commodityDatabase.commodityCountrys){
                vm.commodityDatabase.commodityCountrys = [];
            }
            if(vm.commodityDatabase.commodityCountrys.length == 0){
                vm.commodityDatabase.commodityCountrys.push({});
            }
            if(!vm.commodityDatabase.id && vm.commodityDatabase.idCode && vm.commodityDatabase.venderId){
                CommodityDatabase.findOneByVenderIdAndIdCode({
                    venderId: vm.commodityDatabase.venderId,
                    idCode: vm.commodityDatabase.idCode
                }, function (result) {
                    if(result && result.id){
                        vm.commodityDatabase = result;
                    }
                    else {
                        getVenderName();
                    }
                })
            }
            else {
                getVenderName();
            }
        }

        function getVenderName(){
            if(vm.commodityDatabase.venderId && !vm.commodityDatabase.venderName){
                OpsVender.getOpsVenderDTO({
                    id: vm.commodityDatabase.venderId
                }, function(result) {
                    vm.commodityDatabase.venderName = result.companyName;
                });                
            }
            if(!vm.commodityDatabase.id){
                vm.commodityDatabase.idCode = null;
            }
        }

        function onSaveError () {
            vm.isSaving = false;
            layer.msg($translate.instant('global.save_fail'));
        }

        function addCommodityCountry() {
            if(!vm.commodityDatabase.commodityCountrys){
                vm.commodityDatabase.commodityCountrys = [];
            }
            vm.commodityDatabase.commodityCountrys.push({});
            $translate.refresh();
        };

        function delCommodityCountry (commodityCountry) {
            if(!commodityCountry.id){
                var index = vm.commodityDatabase.commodityCountrys.indexOf(commodityCountry);
                if (index > -1) {
                    vm.commodityDatabase.commodityCountrys.splice(index, 1);
                };
                return;
            }            
            layer.confirm($translate.instant('global.confirmAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                layer.load(1, {shade: 0.3});
                CommodityCountry.delete({
                    id: commodityCountry.id
                }, function(){
                    layer.closeAll();  
                    CommodityDatabase.get({id : vm.commodityDatabase.id}, function(result){
                        vm.commodityDatabase = result;
                    });
                }, function(result){
                    layer.close(loadIndex);  
                    layer.msg($translate.instant('global.delete_fail'));
                });
            }, function() {
                return;
            });


        };
        function _onCargoSelected (item, data) {
            data.hsCode = item.hsCode;
            data.cargoName = item.cargoName;
            data.cargoNameEn = item.cargoNameEn;
            data.customsUnit = item.customsUnit;
            data.invoiceUnit = item.invoiceUnit;
            data.customsSuperviseCondition = item.customsSuperviseCondition;
            data.inspectionCategory = item.inspectionCategory;
            data.commodityInspectionRequired = (item.inspectionCategory && item.inspectionCategory != "无");
            data.declareElement = item.declareElement;
            data.taxRebateRate = item.taxRebateRate;
            data.exportDutyRate = item.exportDutyRate;
            data.otherInfo = item.otherInfo;           

            // data.certificateType = null;
            // data.material = null;
            // data.materialEn = null;
            // data.useAge = null;
            // data.useAgeEn = null;
        }
        vm.popoverContent = "N/A";
        function getCustomsSuperviseCondition () {
            vm.popoverContent = "";
            var str = vm.commodityDatabase.customsSuperviseCondition;
            angular.forEach(Dictionary.customsSuperviseConditions, function(data){
                if(str.indexOf(data.code) !== -1){
                    vm.popoverContent = vm.popoverContent +  "(" + data.code + ")" + data.name + "\n";
                }
            })
            $translate.refresh();
        }
        function getInspectionCategory () {
            vm.popoverContent = "";
            var str = vm.commodityDatabase.inspectionCategory;
            angular.forEach(Dictionary.inspectionCategorys, function(data){
                if(str.indexOf(data.code) !== -1){
                    vm.popoverContent = vm.popoverContent +  "(" + data.code + ")" + data.name + "\n";
                }
            })
            $translate.refresh();
        }

        function findOneByHscode (item) {
            CommodityDatabase.findOneByHscode({hscode: item.key}, function(result){
                _onCargoSelected(result, vm.commodityDatabase);
                if(!vm.commodityDatabase.hsCode){
                    vm.commodityDatabase.hsCode = item.key;
                    $rootScope.OPS_ALERT(item.key + ": Is Not Exist!");
                }
            });
        }
        function onCountrySelected (item, data) {
            data.importCountryCode = item.code;
            data.country = item.cnName;
        }
        function auditCommDatabase () {
            if ($rootScope.HAVENOAUTH('AUDI_COMM_DATABASE')) {
                $rootScope.FORBIDDEN_BY_ALERT('AUDI_COMM_DATABASE');
                return;        
            }
            vm.commodityDatabase.auditor = vm.commodityDatabase.auditor?null:($rootScope.account.firstName + ' ' + $rootScope.account.lastName);
            save();
        }
        
    }
})();
