(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('AddressBook', AddressBook);

    AddressBook.$inject = ['$resource'];

    function AddressBook ($resource) {
        var resourceUrl =  'api/address-books/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },            
            'getAll': {
                method:'GET',
                url:'api/address-books/getAll',
                isArray:true
            }, 
            'getApiAddressBook': {
                method:'GET',
                url:'https://huodai.qdstsy.cn/api/edi/address-books/getApiAddressBook',
                isArray:true
            }, 
            'getAuthAccounts': {
                method:'GET',
                url:'api/address-books/getAuthAccounts',
                isArray:true
            }, 
            'getAddressByCode': {
                method:'GET',
                url:'api/address-books/getAddressByCode'
            },             
            'getChargeChannel': {
                method:'GET',
                url:'api/address-books/getChargeChannel'
            }, 
            'getLabelAccountByCode': {
                method:'GET',
                url:'api/address-books/getLabelAccountByCode'
            },             
            'groupShareOne': {
                method:'GET',
                url:'api/address-books/groupShareOne'
            }, 
            'labelRateTest': {
                method:'POST',
                url:'api/address-books/labelRateTest'
            },       
            'addressCheck': {
                method:'POST',
                url:'api/address-books/addressCheck'
            },   
            'getZipCode': {
                method:'POST',
                url:'api/address-books/getZipCode'
            },   
            'getCityState': {
                method:'POST',
                url:'api/address-books/getCityState'
            },          
            'getAllAccounts': {
                method:'GET',
                url:'api/address-books/getAllAccounts',
                isArray:true
            },   
            'delAddressBooks': {
                method:'DELETE',
                url:'api/address-books/delAddressBooks'
            },
            'getEnabledAccounts': {
                method:'GET',
                url:'api/address-books/getEnabledAccounts',
                isArray:true
            },              
            'getFbaAddress': {
                method:'GET',
                url:'api/address-books/getFbaAddress'
            },      
            'update': { method:'PUT' },
            'exportExcel': {
                method: 'POST',
                url:'api/excel/exportAddressBook',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'getServicesList': {
                method:'PUT',
                url:'api/address-books/getServicesList',
                isArray: true
            },         
            'getUPSToken': {
                method: 'POST',
                url:'api/address-books/getUPSToken'
            },         
            'fillAddresssBook': {
                method: 'PUT',
                url:'api/address-books/fillAddresssBook'
            },
            'getAmazonRefreshToken': {
                method: 'POST',
                url:'api/address-books/get-amazon-refresh-token'
            }            
        });
    }
})();
