(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsLogDetailController', WmsLogDetailController);

    WmsLogDetailController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', '$translate',
        'entity', 'WmsLog', 'uiGridConstants', 'WmsWarehouse', 'WmsCargo', 'GridState'
    ];

    function WmsLogDetailController($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, $translate,
        entity, WmsLog, uiGridConstants, WmsWarehouse, WmsCargo, GridState) {
        var vm = this;
        vm.searchEntity = entity;
        vm.clear = clear;
        vm.getAllWarehouses = getAllWarehouses;
        vm.itemSelected = itemSelected;
        vm.getWmsLogs = getWmsLogs;
        vm.getAllLocations = getAllLocations;

        function getAllLocations () {
            if (vm.locations && vm.locations.length > 0){
                return;
            }
            WmsWarehouse.getAllLocations({companyId: $rootScope.account.companyId}, function (data) {
                vm.locations = data;
            });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
        $scope.gridOptions = {
            enableSorting: false, //排序
            useExternalSorting: false,

            enableGridMenu: true, //表格 菜单  
            showGridFooter: false, //表格的footer  
            showColumnFooter: true, //每列footer

            enableFiltering: true, //高级过滤            

            enableHorizontalScrollbar: 1, //表格的水平滚动条  
            enableVerticalScrollbar: 1, //表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            exporterMenuCsv: false,
            exporterMenuPdf: false,
            exporterMenuLabel: "Export",
            exporterExcelFilename: $translate.instant('autopsApp.wmsCargo.detail.logs') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns: ['id'],
            minimumColumnSize: 70,
            columnDefs: [{
                    field: 'wmsInNum',
                    displayName: $translate.instant('autopsApp.wmsCargo.wmsInNum'),
                    minWidth: 200
                },
                {
                    field: 'availableChange',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsCargo.availableChange'),
                    cellTemplate: '<strong class="grid-text text-primary">{{row.entity.availableChange}}</strong>',
                    minWidth: 100
                },
                {
                    field: 'inventoryChange',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsCargo.inventoryChange'),
                    cellTemplate: '<strong class="grid-text text-primary">{{row.entity.inventoryChange}}</strong>',
                    minWidth: 100
                },
                {
                    field: 'sku',
                    displayName: "SKU",
                    minWidth: 200
                },
                {
                    field: 'logAvailableBalance',
                    displayName: $translate.instant('autopsApp.wmsCargo.logAvailableBalance'),
                    cellTemplate: '<strong class="grid-text text-primary">{{row.entity.logAvailableBalance}}</strong>',
                    minWidth: 100
                },
                {
                    field: 'logInventoryBalance',
                    displayName: $translate.instant('autopsApp.wmsCargo.logInventoryBalance'),
                    cellTemplate: '<strong class="grid-text text-primary">{{row.entity.logInventoryBalance}}</strong>',
                    minWidth: 100
                },
                {
                    field: 'actTime',
                    displayName: $translate.instant('autopsApp.wmsCargo.actTime'),
                    minWidth: 200,
                    cellTemplate: '<span class="grid-text">{{row.entity.actTime | date:\'medium\'}}</span>'
                },
                {
                    field: 'actType',
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.WMS_ACTION_TYPES
                    },
                    displayName: $translate.instant('autopsApp.wmsCargo.actionType'),
                    cellTemplate: '<span class="grid-text" translate="autopsApp.wmsCargo.action.{{row.entity.actType}}" ng-if="row.entity.actType"></span>'
                },
                {
                    field: 'location',
                    displayName: $translate.instant('autopsApp.wmsCargo.location'),
                    minWidth: 100
                },                
                {
                    field: 'wmsOutNum',
                    displayName: $translate.instant('autopsApp.wmsCargo.refNum'),
                    minWidth: 100
                },
                {
                    field: 'cargoCode',
                    displayName: $translate.instant('autopsApp.wmsCargo.code'),
                    aggregationType: uiGridConstants.aggregationTypes.count
                },
                {
                    field: 'register',
                    displayName: $translate.instant('autopsApp.wmsCargo.register'),
                    minWidth: 100
                },
                {
                    field: 'availableChangeGw',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsCargo.availableChange') + "(KGS)",
                    minWidth: 100
                },
                {
                    field: 'availableChangeVol',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsCargo.availableChange') + "(M3)",
                    minWidth: 100
                },
                {
                    field: 'inventoryChangeGw',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsCargo.inventoryChange') + "(KGS)",
                    minWidth: 100
                },
                {
                    field: 'inventoryChangeVol',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsCargo.inventoryChange') + "(M3)",
                    minWidth: 100
                },
                {
                    field: 'availableBalanceGw',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsCargo.logAvailableBalance') + "(KGS)",
                    minWidth: 100
                },
                {
                    field: 'availableBalanceVol',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsCargo.logAvailableBalance') + "(M3)",
                    minWidth: 100
                },
                {
                    field: 'inventoryBalanceGw',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsCargo.logInventoryBalance')+ "(KGS)",
                    minWidth: 100
                },
                {
                    field: 'inventoryBalanceVol',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsCargo.logInventoryBalance') + "(M3)",
                    minWidth: 100
                },
                {
                    field: 'ttlPkgs',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsCargo.ttlPkgs'),
                    minWidth: 100
                },
                {
                    field: 'ttlGw',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsCargo.ttlGw'),
                    minWidth: 100
                },
                {
                    field: 'ttlVol',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsCargo.ttlVol'),
                    minWidth: 100
                },
                { field: 'clientName', 
                    minWidth:150,
                    visible: $rootScope.account.companyId != null,
                    displayName: $translate.instant('autopsApp.wmsIn.clientName')},
                { field: 'cargoName', 
                    displayName: $translate.instant('autopsApp.wmsIn.cargoName'), 
                    minWidth:100},
                { field: 'cargoNameEn', 
                    displayName: $translate.instant('autopsApp.wmsIn.cargoNameEn'), 
                    minWidth:100},
                { field: 'mark', 
                    displayName: $translate.instant('autopsApp.wmsIn.mark'), 
                    minWidth:100},
                { field: 'status', 
                    displayName: $translate.instant('autopsApp.wmsIn.status'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [ 
                        { value: 'OK', label: 'OK'}, 
                        { value: 'NO', label: 'NO'}, 
                        { value: 'HD', label: 'HD'}]
                    },
                    minWidth:100},  
                { field: 'gateInTime', 
                    displayName: $translate.instant('autopsApp.wmsIn.gateInTime'), 
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.gateInTime | date:\'medium\'}}">{{row.entity.gateInTime | date:\'mediumDate\'}}</span>',
                    minWidth:130},                  
                { field: 'code', 
                    displayName: $translate.instant('autopsApp.wmsIn.code'), 
                    minWidth:100},
                { field: 'palletizedAt', 
                    displayName: $translate.instant('autopsApp.wmsIn.BoxNo'), 
                    minWidth:100},                         
                { field: 'fnSku', 
                    displayName: "FnSku", 
                    minWidth:100},            
                { field: 'asin', 
                    displayName: "Asin", 
                    minWidth:100},          
                { field: 'upc', 
                    displayName: "upc", 
                    minWidth:100},
                { field: 'packageType', 
                    displayName: $translate.instant('autopsApp.wmsCargo.pkgsType'), 
                    minWidth:100},
                { field: 'warehouseName', 
                    displayName: $translate.instant('autopsApp.wmsIn.warehouseName'), 
                    minWidth:100},    
                { field: 'location', 
                    displayName: $translate.instant('autopsApp.wmsIn.location'), 
                    minWidth:100},                    
                { field: 'truckNum', 
                    displayName: $translate.instant('autopsApp.wmsIn.truckNum'), 
                    minWidth:100},
                { field: 'ctnNum', 
                    displayName: $translate.instant('autopsApp.wmsIn.ctnNum'), 
                    minWidth:100},
                { field: 'poNum', 
                    displayName: $translate.instant('autopsApp.wmsIn.poNum'), 
                    minWidth:100},
                { field: 'podName', 
                    displayName: $translate.instant('autopsApp.wmsIn.podName'), 
                    minWidth:100},                
                { field: 'batchOne', 
                    displayName: $translate.instant('autopsApp.wmsIn.batch') + 1, 
                    minWidth:100},
                { field: 'batchTwo', 
                    displayName: $translate.instant('autopsApp.wmsIn.batch') + 2, 
                    minWidth:100},
                { field: 'batchThree', 
                    displayName: $translate.instant('autopsApp.wmsIn.batch') + 3, 
                    minWidth:100},
                { field: 'batchFour', 
                    displayName: $translate.instant('autopsApp.wmsIn.batch') + 4, 
                    minWidth:100},
                { field: 'length', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.length'), 
                    minWidth:100},
                { field: 'width', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.width'), 
                    minWidth:100},
                { field: 'height', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.height'), 
                    minWidth:100},
                { field: 'piece', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.piece'), 
                    minWidth:100},
                { field: 'billDate', 
                    displayName: $translate.instant('autopsApp.wmsCargo.billDate'), 
                    minWidth:100},
                { field: 'keeper', 
                    displayName: $translate.instant('autopsApp.wmsCargo.keeper'), 
                    minWidth:100},
                { field: 'goodsValue', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.goodsValue'), 
                    minWidth:100},
                { field: 'declareValue', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.declareValuePh'), 
                    minWidth:100},                 
                { field: 'chargeType', 
                    displayName: $translate.instant('autopsApp.wmsIn.chargeType'), 
                    minWidth:100},
                { field: 'chargePrice', 
                    type:'number',
                    displayName: $translate.instant('autopsApp.wmsIn.chargePrice'), 
                    minWidth:100},
                { field: 'remarks', 
                    displayName: $translate.instant('autopsApp.wmsCargo.remarks'), 
                    minWidth:100},
                { field: 'handleType', 
                    displayName: $translate.instant('autopsApp.wmsIn.handleTypeName'), 
                    minWidth:100},
                { field: 'orderRemarks', 
                    displayName: $translate.instant('autopsApp.wmsIn.orderRemarks'), 
                    minWidth:100}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 136;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }       
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);                   
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "wmsLog";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);           
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "wmsLog";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function(gridApi) {
                $scope.gridApi = gridApi;
            }
        }
        vm.isBrief = false;
        function getWmsLogs () {
            vm.isBrief = false;
            if(!vm.searchEntity.actTimeFM){
                layer.msg($translate.instant('autopsApp.wmsCargo.filterNullAlert'));
                return;
            }
            var loadIndex = layer.load(1, {shade: 0.3});  
            WmsLog.getWmsLogs(vm.searchEntity, function(data){
                $scope.gridOptions.data = data;
                resize();
                layer.close(loadIndex);  
            }, function(){
                layer.close(loadIndex);  
            });
        }
        function resize() {
            var tabHeight = $scope.gridOptions.data.length * 30 + 200;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 300){
                tabHeight = 300;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function() {
            vm.searchEntity.filterType = "sku";
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 136
            }, function(result){
                gridStateObj = result;
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }   
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
            })
        });

        vm.warehouses = [];
        function getAllWarehouses () {
            if (vm.warehouses && vm.warehouses.length > 0){
                return;
            }
            WmsWarehouse.getAll({
                includeOwn: true,
                includeVirtual: false,
                includeGroup: false
            }, function (result) {
                if(!result || result.length == 0){
                    layer.msg($translate.instant('global.messages.resultNull'));
                }
                vm.warehouses = result;
            });
        }

        function itemSelected (item, fd) {
            vm.searchEntity[fd] = item.id;
        }

    }
})();
