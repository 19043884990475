(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsInRegisterController', WmsInRegisterController);

    WmsInRegisterController.$inject = ['$scope', '$state', '$rootScope', '$uibModalInstance', '$timeout', '$http', '$translate', '$uibModal',
    'WmsIn', 'Principal', 'WmsWarehouse', 'WmsCargo', 'OpsFiles', 'DataUtils', 'OpsItem', 'entity', 'WmsWorkLog', 'DateUtils'];

    function WmsInRegisterController($scope, $state, $rootScope, $uibModalInstance, $timeout, $http, $translate, $uibModal,
        WmsIn, Principal, WmsWarehouse, WmsCargo, OpsFiles,DataUtils, OpsItem, entity, WmsWorkLog, DateUtils) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        var vm = this;

        vm.wmsIn = entity;
        vm.save = save;
        vm.splitCargo = splitCargo;
        vm._onLocationSelected = _onLocationSelected;
        vm.getLocations = getLocations;
        vm.getLastLocation = getLastLocation;
        vm.locations = [];

        vm.printIn = printIn;
        vm.getBarcode = getBarcode;
        vm.isPalletChange = isPalletChange;
        vm.updateShipmentPkgAmt = updateShipmentPkgAmt;
        vm.checkShipmentPkgAmt = checkShipmentPkgAmt;
        vm.countByBoxNo = countByBoxNo;
        vm.gwTtlChange = gwTtlChange;
        vm.volTtlChange = volTtlChange;
        vm.updateCargoTtl = updateCargoTtl;

        vm.scanCount = scanCount;
        vm.pkgsCountChange = pkgsCountChange;
        vm.scanedCode = "";
        vm.getPkgs = getPkgs;

        vm.editWmsIn = editWmsIn;
        vm.receivingNote = receivingNote;

        vm.onGateInTimeSelected = onGateInTimeSelected;
        vm.allInSelect = allInSelect;
        vm.updateTtl = updateTtl;
        vm.updateVol = updateVol;
        vm.manualBarCodeBlur = manualBarCodeBlur;

        vm.getAllWmsService = getAllWmsService;
        vm.opsItems = [];

        vm.searchParam = null;
        vm.loadWmsIn = loadWmsIn;
        vm.autoFillLocation = autoFillLocation;
        vm.files = [];
        vm.editIdx = null;

        $scope.clear = function () {
            $uibModalInstance.dismiss();
        }
        function loadWmsIn () {
            if(!vm.searchParam){
                return;
            }
            WmsIn.loadWmsIn({
                searchNum: vm.searchParam
            }, function(result) {
                if(!result || !result.id){
                    layer.msg($translate.instant('global.messages.resultNull'));
                    return;
                }
                vm.wmsIn = result;
                initWmsIn();
                updateGwVolTtl();
                $translate.refresh();
                vm.files = [];
                layer.msg($translate.instant('entity.action.loadSuccess'));
            }, function(error) {
                layer.msg($translate.instant('global.loadFailed'));
            });
        }
        function loadWmsInById () {
            if(!vm.wmsIn.id){
                return;
            }
            WmsIn.get({id : vm.wmsIn.id}, function(result){
                vm.wmsIn = result;
                initWmsIn();
                updateGwVolTtl();
                $translate.refresh();
            });
        }




        function updateGwVolTtl () {
            angular.forEach(vm.wmsIn.cargoList, function(data){
                if(data.pkgs){
                    if(data.nw){
                        data.nwTtl = DataUtils.round(data.nw * data.pkgs, 2);
                    }
                    if(data.gw){
                        data.gwTtl = DataUtils.round(data.gw * data.pkgs, 2);
                    }
                    if(data.vol){
                        data.volTtl = DataUtils.round(data.vol * data.pkgs, 3);
                    }
                    if(data.capacity){
                        data.capacityTtl = DataUtils.round(data.capacity * data.pkgs, 2);
                    }
                    if(data.piece){
                        data.pieceTtl = DataUtils.round(data.piece * data.pkgs, 2);
                    }
                }
            })
        }

        function gwTtlChange (cargo) {
            if(cargo.pkgs && cargo.gwTtl){
                cargo.gw = DataUtils.round(cargo.gwTtl/cargo.pkgs, 2);
                updateTtl();
            }
        }
        function volTtlChange (cargo) {
            if(cargo.pkgs && cargo.volTtl){
                cargo.vol = DataUtils.round(cargo.volTtl/cargo.pkgs, 3);
                updateTtl();
            }
        }

        function getAllWmsService(cargo){
            if(cargo && !cargo.wmsService){
                cargo.wmsService = "";
            }
            if(vm.opsItems && vm.opsItems.length > 0){
                return;
            }
            OpsItem.getAllWmsService({
                whId: vm.wmsIn.warehouseId,
                companyId: $rootScope.account.companyId
            }, function(result) {
                vm.opsItems = result;
                if(!vm.opsItems || vm.opsItems.length == 0){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.wmsServiceTips'));
                }
            }, function(error) {
                layer.msg($translate.instant('global.noRecord'));
            });
        }

        function updateVol(cargo) {
            if (cargo.length && cargo.width && cargo.height) {
                cargo.vol = cargo.length / 100 * cargo.width / 100 * cargo.height / 100;
                cargo.vol = cargo.vol.toFixed(4) - 0;
                updateCargoTtl(cargo);
            }
        }
        function updatedDataTtl(data) {
            if (data.pkgs) {
                if (data.nw) {
                    data.nwTtl = DataUtils.round(data.nw * data.pkgs, 4);
                }
                if (data.gw) {
                    data.gwTtl = DataUtils.round(data.gw * data.pkgs, 4);
                }
                if (data.vol) {
                    data.volTtl = DataUtils.round(data.vol * data.pkgs, 4);
                }
                if (data.capacity) {
                    data.capacityTtl = DataUtils.round(data.capacity * data.pkgs, 2);
                }
                if (data.piece) {
                    data.pieceTtl = DataUtils.round(data.piece * data.pkgs, 2);
                }
            }
        }
        function updateCargoTtl(data) {
            updatedDataTtl(data);
            updateTtl();
        }
        function updateTtl () {
            if (!vm.wmsIn.cargoList || vm.wmsIn.cargoList.length == 0){
                return;
            }
            vm.wmsIn.gw = 0;
            vm.wmsIn.vol = 0;
            vm.wmsIn.applicationNum = 0;

            for (var i = 0; i < vm.wmsIn.cargoList.length; i ++){
                if (vm.wmsIn.cargoList[i].gw && vm.wmsIn.cargoList[i].pkgs){
                    vm.wmsIn.gw = vm.wmsIn.gw + vm.wmsIn.cargoList[i].gw * vm.wmsIn.cargoList[i].pkgs;
                }
                if(!vm.wmsIn.cargoList[i].vol){
                    if (vm.wmsIn.cargoList[i].length && vm.wmsIn.cargoList[i].width && vm.wmsIn.cargoList[i].height){
                        vm.wmsIn.cargoList[i].vol = vm.wmsIn.cargoList[i].length/100 * vm.wmsIn.cargoList[i].width/100 * vm.wmsIn.cargoList[i].height/100;
                        vm.wmsIn.cargoList[i].vol = vm.wmsIn.cargoList[i].vol.toFixed(4) -0;
                    }
                }
                if (vm.wmsIn.cargoList[i].vol && vm.wmsIn.cargoList[i].pkgs){
                    vm.wmsIn.vol = vm.wmsIn.vol + vm.wmsIn.cargoList[i].vol * vm.wmsIn.cargoList[i].pkgs;
                }
                if (vm.wmsIn.cargoList[i].pkgs){
                    vm.wmsIn.applicationNum = vm.wmsIn.applicationNum + vm.wmsIn.cargoList[i].pkgs;
                }
                updateShipmentPkgAmt(vm.wmsIn.cargoList[i]);
            }
            if (vm.wmsIn.gw){
                vm.wmsIn.gw = vm.wmsIn.gw.toFixed(2) -0;
            }
            else {
                vm.wmsIn.gw = null;
            }

            if (vm.wmsIn.vol){
                vm.wmsIn.vol = vm.wmsIn.vol.toFixed(3) -0;
            }
            else {
                vm.wmsIn.vol = null;
            }

            if (vm.wmsIn.applicationNum){
                vm.wmsIn.applicationNum = vm.wmsIn.applicationNum.toFixed(0) -0;
            }
            else {
                vm.wmsIn.applicationNum = null;
            }
        }

        function editWmsIn () {
            var htmlPath = 'app/entities/wms-in/wms-in-detail.html';
            var controllerStr = 'WmsInDetailController';
            $uibModal.open({
                templateUrl: htmlPath,
                controller: controllerStr,
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        if(vm.wmsIn.status != 'allIn' && vm.wmsIn.status != 'partIn' && !vm.wmsIn.audited){
                            vm.wmsIn.checkWmsIn = true;
                        }
                        return WmsIn.get({id : vm.wmsIn.id}).$promise;
                    }]
                }
            }).result.then(function(result) {
                vm.wmsIn = result;
                updateGwVolTtl();
                angular.forEach(vm.wmsIn.cargoList, function (data) {
                    if (!data.outTime){
                        data.pkgsCount = 0;
                    }
                    else {
                        data.locationCopy = data.location;
                    }
                })
                angular.copy(vm.wmsIn, wmsInCopy);
            }, function() {
            });
        }
        function onGateInTimeSelected (cargo) {
            if(!cargo.gateInTime){return;}
            angular.forEach(vm.wmsIn.cargoList, function (data) {
                if (!data.gateInTime && !data.outTime){
                    data.gateInTime = cargo.gateInTime;
                }
            })
        }

        function allInSelect () {
            angular.forEach(vm.wmsIn.cargoList, function (data) {
                if (!data.outTime){
                    data.pkgsCount = data.pkgs;
                    pkgsCountChange(data);
                }
            })
        }

        function checkShipmentPkgAmt(cargo) {
            if (cargo.pkgs && cargo.palletCapacity && cargo.palletAmt == null){
                cargo.palletCapacity = null;
                layer.msg($translate.instant('autopsApp.wmsIn.palletAmtAlert'));
                return;
            }
            if (cargo.palletAmt && cargo.palletAmt >1){
                cargo.palletizedAt = null;
            }
        }

        function updateShipmentPkgAmt(cargo) {
            if (!cargo.pkgs || !cargo.palletCapacity){
                cargo.palletAmt = null;
                return;
            }
            cargo.palletAmt = DataUtils.getPalletAmt(cargo.pkgs, cargo.palletCapacity);
            updateTtlPlts();
        }
        function updateTtlPlts() {
            var pallets = 0;
            angular.forEach(vm.wmsIn.cargoList, function(data) {
                if (data.palletAmt) {
                    pallets += data.palletAmt;
                }
            })
            vm.wmsIn.pallets = pallets;
        }
        function getBarcode (cargo, type) {
            if (vm.isSaving || cargo.outTime
                || (type == "palletizedAt" && (!cargo.isPallet || cargo.palletAmt != 1))){
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-dialog.html',
                controller: 'WmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md'
            }).result.then(function(code) {
                if (type == "code"){
                    angular.forEach(vm.locations, function(data){
                        if(data.code == code){
                            _onLocationSelected(data, cargo);
                        }
                    });
                }
                else {
                    cargo[type] = code;
                }
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }
        vm.palletTtl = 0;

        $timeout(function() {
            if(vm.wmsIn.guideId == -400){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.bindedWithRef', {"ref": vm.wmsIn.warehousingNumber}));
                $uibModalInstance.dismiss();
                return;
            }
            initWmsIn();
            updateGwVolTtl();
            getLocations();
        })
        vm.page = 1;
        vm.itemsPerPage = 20;
        vm.totalItems = 0;
        var wmsInCopy = {};
        function initWmsIn () {
            var skuNull = false;
            vm.totalItems = vm.wmsIn.cargoList.length;
            angular.forEach(vm.wmsIn.cargoList, function (data) {
                if(!data.sku){
                    skuNull = true;
                }
                if(!data.status){
                    if(vm.wmsIn.isOut == null){
                        data.status = "NO";
                    }
                    else {
                        data.status = "OK";
                    }
                }
                if(data.outTime && !data.allOut && !data.gateInTime){
                    data.outTime = null;
                }
                if (!data.gateInTime){
                    if(data.outTime){
                        data.gateInTime = DateUtils.convertDateTimeFromServer(data.outTime);
                    }
                    else {
                        data.gateInTime = new Date();
                    }
                }
                if (!data.outTime){
                    data.pkgsCount = 0;
                    data.locationCopy = data.location;
                    data.warehouseIdCopy = data.warehouseId;
                    data.areaIdCopy = data.areaId;
                    data.locationIdCopy = data.locationId;
                    data.locationTypeCopy = data.locationType;
                }
                else {
                    data.locationCopy = data.location;
                }
                if(data.palletAmt){
                    vm.palletTtl += data.palletAmt;
                }
            })
            if(skuNull && vm.wmsIn.handleType == 'wmsService' && vm.wmsIn.isOut == false){
                layer.msg($translate.instant('autopsApp.wmsIn.skuNullAlert'));
                $uibModalInstance.dismiss();
            }
            angular.copy(vm.wmsIn, wmsInCopy);
        }

        function getLocations () {
            if(!vm.wmsIn.warehouseId){
                return;
            }
            if (!vm.locations || vm.locations.length == 0){
                WmsWarehouse.getLocations({warehouseId: vm.wmsIn.warehouseId}, function (data) {
                    vm.locations = data;
                    checkLocation();
                });
            }
            else {
                checkLocation();
            }
        }

        function checkLocation () {
            var locations = new Set();
            angular.forEach(vm.locations, function(location){
                locations.add(location.code);
            });
            angular.forEach(vm.wmsIn.cargoList, function (cargo) {
                if(cargo.allOut){

                }
                else if (cargo.locationCopy && !locations.has(cargo.locationCopy)){
                    cargo.locationCopy = null;
                    cargo.location = null;
                    cargo.warehouseId = null;
                    cargo.areaId = null;
                    cargo.locationId = null;
                    cargo.locationType = null;
                }
                else {
                    angular.forEach(vm.locations, function(location){
                        if(location.code == cargo.location){
                            _onLocationSelected(location, cargo);
                        }
                    });
                }
            });

        }

        function save() {
            if(vm.isSaving){
                return;
            }
            vm.isSaving = true;
            if (angular.equals(vm.wmsIn, wmsInCopy)){
                layer.msg($translate.instant('global.no_amendmend'));
                vm.isSaving = false;
                return;
            }
            for(var i = 0; i < vm.wmsIn.cargoList.length; i++){
                if (vm.wmsIn.cargoList[i].pkgsCount && !vm.wmsIn.cargoList[i].outTime && !vm.wmsIn.cargoList[i].locationCopy){
                    var msg = vm.wmsIn.cargoList[i].code + ": " + $translate.instant('global.choose_location_alert');
                    $rootScope.OPS_ALERT(msg);
                    vm.isSaving = false;
                    return;
                }
                else if (vm.wmsIn.cargoList[i].pkgsCount < 0 || vm.wmsIn.cargoList[i].pkgsCount > vm.wmsIn.cargoList[i].pkgs){
                    var msg = vm.wmsIn.cargoList[i].code + ": " + $translate.instant('autopsApp.wmsIn.notNumAlert');
                    $rootScope.OPS_ALERT(msg);
                    vm.isSaving = false;
                    return;
                }
            }
            angular.forEach(vm.wmsIn.cargoList, function (data) {
                if (!data.outTime && data.pkgsCount){
                    data.pkgs = data.pkgsCount;
                    data.location = data.locationCopy;
                    data.locationId = data.locationIdCopy;
                    data.locationType = data.locationTypeCopy;
                    data.areaId = data.areaIdCopy;
                    data.warehouseId = data.warehouseIdCopy;
                    data.keeper = data.keeperCopy;
                    data.keeperId = data.keeperIdCopy;
                    data.allOut = true;
                }
            })

            vm.wmsIn.registerBy = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            vm.wmsIn.registerId = $rootScope.account.id;
            if(vm.wmsIn.id){
                WmsIn.saveRegistIn(vm.wmsIn, onSaveSuccess, onSaveError);
            }
            else {
                WmsIn.saveTempRegistIn(vm.wmsIn, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submitted'));

            // WmsIn.get({id : result.id}, function(result){
                vm.wmsIn = result;
                initWmsIn();
                updateGwVolTtl();
                $scope.$emit('autopsApp:wmsInUpdate', result);
            // })

            // $uibModalInstance.close(result);
        }

        function onSaveError (result) {
            vm.isSaving = false;
            if (result.status == 409) {
                layer.msg($translate.instant('global.refreshAlert'));
            }
        }

        function pkgsCountChange (cargo) {
            if (cargo.pkgsCount < 0 || cargo.pkgsCount > cargo.pkgs){
                cargo.pkgsCount = cargo.pkgs;
            }
            var actualNum = 0;
            for (var i = 0; i < vm.wmsIn.cargoList.length; i ++){
                if (vm.wmsIn.cargoList[i].outTime || vm.wmsIn.cargoList[i].allOut){
                    actualNum = actualNum + vm.wmsIn.cargoList[i].pkgs;
                }
                else if (vm.wmsIn.cargoList[i].pkgsCount){
                    actualNum = actualNum + vm.wmsIn.cargoList[i].pkgsCount;
                }
            }
            vm.wmsIn.actualNum = actualNum -0;
        }

        //拆分货物
        function splitCargo (cargo, idx) {
            var pkgs = cargo.pkgs;
            layer.prompt({
              formType: 0,
              value: pkgs,
              title: $translate.instant('autopsApp.wmsIn.splitCargoTitle'),
              area: ['280px', '160px']
            }, function(value, index, elem){
                value = value -0;
                if (!value || value == cargo.pkgs){
                    layer.close(index);
                    return;
                }
                if (isNaN(value) || parseInt(value) != value || value > cargo.pkgs){
                    layer.msg($translate.instant('autopsApp.wmsIn.notNumAlert'));
                    return;
                }
                cargo.pkgs = value-0;
                cargo.palletAmt = DataUtils.getPalletAmt(cargo.pkgs, cargo.palletCapacity);
                WmsCargo.splitCargoCom(cargo, function(result) {
                    vm.editIdx = null;
                    loadWmsInById();
                    layer.msg($translate.instant('autopsApp.wmsIn.splitSuccessMsg'));
                    $translate.refresh();
                });
                layer.close(index);
                $translate.refresh();
            });
        }
        var locationCopy = null;
        function _onLocationSelected (item, cargo) {
            var notValidLocation = false;
            angular.forEach(vm.wmsIn.cargoList, function (data) {
                if(data.palletizedAt && data.palletizedAt == cargo.palletizedAt){
                    if ( data.location && data.location != item.code && data.id != cargo.id){
                        notValidLocation = true;
                    }
                }
            })
            if (notValidLocation){
                cargo.location = null;
                locationCopy = null;
                layer.msg($translate.instant('autopsApp.wmsIn.notSameLocationForSamePalletAlert'));
                return;
            }
            cargo.locationCopy = item.code;
            cargo.locationIdCopy = item.locationId;
            cargo.locationTypeCopy = item.locationType;
            cargo.areaIdCopy = item.areaId;
            cargo.warehouseIdCopy = item.warehouseId;
            cargo.keeperCopy = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            cargo.keeperIdCopy = $rootScope.account.id;

            locationCopy = {};
            locationCopy.id = item.locationId;
            locationCopy.locationType = item.locationType;
            locationCopy.code = item.code;
            locationCopy.areaId = item.areaId;
            locationCopy.warehouseId = item.warehouseId;
        }
        function autoFillLocation (cargo) {
            if(!cargo.locationCopy){
                return;
            }
            angular.forEach(vm.wmsIn.cargoList, function (data) {
                if(!data.locationCopy){
                    data.locationCopy = cargo.locationCopy;
                    data.locationIdCopy = cargo.locationIdCopy;
                    data.locationTypeCopy = cargo.locationTypeCopy;
                    data.areaIdCopy = cargo.areaIdCopy;
                    data.warehouseIdCopy = cargo.warehouseIdCopy;
                    data.keeperCopy = cargo.keeperCopy;
                    data.keeperIdCopy = cargo.keeperIdCopy;
                }
            })
        }

        function getLastLocation (cargo) {
            if (locationCopy && !cargo.locationCopy){
                cargo.locationCopy = locationCopy.code;
                cargo.locationIdCopy = locationCopy.id;
                cargo.locationTypeCopy = locationCopy.locationType;
                cargo.areaIdCopy = locationCopy.areaId;
                cargo.warehouseIdCopy = locationCopy.warehouseId;
                cargo.keeperCopy = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                cargo.keeperIdCopy = $rootScope.account.id;
            }
        }
        var cargoCopy = null;
        function isPalletChange (cargo) {
            if (!cargoCopy){
                cargoCopy = angular.copy(cargo);
            }
            if (cargo.isPallet){
                cargo.palletizedAt = cargoCopy.palletizedAt;
                cargo.palletCapacity = cargoCopy.pkgs;
                cargo.palletAmt = 1;

            }
            else {
                cargo.palletizedAt = null;
                cargo.palletCapacity = null;
                cargo.palletAmt = null;
            }
        }

        var isFistCount = true;
        function scanCount () {
            if(!vm.wmsIn.id){
                // if(!vm.wmsIn.poNum){
                //     $rootScope.OPS_ALERT("Shipment ID is required.")
                //     return;
                // }
                $uibModal.open({
                    templateUrl: 'app/entities/wms-cargo/wms-cargo-fba.html',
                    controller: 'WmsCargoFbaController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: [function() {
                            return vm.wmsIn;
                        }]
                    }
                }).result.then(function(correctCount) {
                    angular.forEach(vm.wmsIn.cargoList, function(data){
                        if (correctCount){
                            if(correctCount > data.pkgs){
                                data.pkgsCount = data.pkgs;
                                correctCount -= data.pkgs;
                            }
                            else {
                                data.pkgsCount = correctCount;
                                correctCount = 0;
                            }
                        }
                    })
                }, function() {
                });
            }
            else if(vm.isSaving){
                return;
            }
            else {
                vm.scanedCode = null;
                vm.scanedLocation = $translate.instant('autopsApp.wmsIn.scanAndCount');
                vm.isSaving = true;
                if(isFistCount){
                    angular.forEach(vm.wmsIn.cargoList, function(data){
                        if(!data.outTime){
                            data.pkgsCount = 0;
                        }
                    });
                }
                $timeout(function (){
                    $("#field_manualBarCode").focus();
                });
                isFistCount = false;
            }
        };

        function countByBoxNo (cargo) {
            if(!cargo.palletizedAt){
                return;
            }
            angular.forEach(vm.wmsIn.cargoList, function(data){
                if(!data.outTime && cargo.palletizedAt == data.palletizedAt){
                    data.pkgsCount = data.pkgs;
                    pkgsCountChange(data);
                    if(!data.locationCopy){
                        data.locationCopy = cargo.locationCopy;
                        data.locationIdCopy = cargo.locationIdCopy;
                        data.locationTypeCopy = cargo.locationTypeCopy;
                        data.areaIdCopy = cargo.areaIdCopy;
                        data.warehouseIdCopy = cargo.warehouseIdCopy;
                        data.keeperCopy = cargo.keeperCopy;
                        data.keeperIdCopy = cargo.keeperIdCopy;
                    }
                }
            })
        }
        function countNums () {
            var isCorrect = false;
            for(var i = 0; i < vm.wmsIn.cargoList.length; i++){
                if ((vm.scanedCode == vm.wmsIn.cargoList[i].code || vm.scanedCode == vm.wmsIn.cargoList[i].sku) && !vm.wmsIn.cargoList[i].outTime){
                    vm.scanedLocation = vm.wmsIn.cargoList[i].location;//匹配的库位
                    if(vm.wmsIn.cargoList[i].pkgsCount < vm.wmsIn.cargoList[i].pkgs){
                        vm.wmsIn.cargoList[i].pkgsCount++;
                        pkgsCountChange(vm.wmsIn.cargoList[i]);
                        isCorrect = true;
                        break;
                    }
                }
            }
            if (!isCorrect){
                angular.forEach(vm.wmsIn.cargoList, function(data){
                    if(!data.outTime && vm.scanedCode == data.palletizedAt){
                        vm.scanedLocation = data.location;//匹配的库位
                        data.pkgsCount = data.pkgs;
                        pkgsCountChange(data);
                        isCorrect = true;
                    }
                })
            }

            if (!isCorrect){
                layer.msg($translate.instant('autopsApp.wmsIn.noMatchCode'));
                vm.scanedLocation = $translate.instant('autopsApp.wmsIn.noMatchCode');
                $("#field_manualBarCode").focus();
            }
            vm.scanedCode = null;
            $translate.refresh();
            $("#field_manualBarCode").focus();
        }

        $(document).keydown(function(event){
            if (vm.isSaving){
                // $("#field_manualBarCode").focus();
                if(event.keyCode == 13){
                    countNums();
                }
            }
        });

        $("#field_manualBarCode").blur(function(){
            manualBarCodeBlur();
        });

        function manualBarCodeBlur () {
            vm.isSaving = false;
            vm.scanedLocation = null;
            vm.scanedCode = null;
            $translate.refresh();
        }


        $timeout(function (){
            $("#field_manualBarCode").focus();
            var type = "wms";
            var id = vm.wmsIn.id;

            if(vm.wmsIn.guideId){
                type = "guide";
                id = vm.wmsIn.guideId;
            }
            WmsWorkLog.getOpRequirment({
                type: type,
                id: id
            }, function(result){
                vm.wmsWorkLogs = result;
            });
        });

        var rateUnsubscribe = $rootScope.$on('autopsApp:wmsWorkLogUpdate', function(event, result) {
            if(result && result.id){
                var isNew = true;
                angular.forEach(vm.wmsWorkLogs, function(data){
                    if(data.id == result.id){
                        data.count = result.count;
                        data.work = result.work;
                        isNew = false;
                    }
                })
                if(isNew){
                    vm.wmsWorkLogs.push(result)
                }
            }
        });
        $scope.$on('$destroy', rateUnsubscribe);
        function getPkgs (cargo) {
            if (!cargo.pkgsCount){
                cargo.pkgsCount = cargo.pkgs;
            }
            pkgsCountChange(cargo);
        }

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function (date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $scope.wmsLabels = function(reportType) {
            $scope.format = 1;
            WmsIn.wmsLabels({
                reportType: reportType,
                fileType: $scope.format
            }, vm.wmsIn, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('autopsApp.accountTitle.viewAccountTitleFail'));
            });
        }
        function printIn(reportType){
            var wmsIn = angular.copy(vm.wmsIn);
            wmsIn.wmsWorkLogs = vm.wmsWorkLogs;
            OpsFiles.generateWmsInPaper({reportType: reportType},wmsIn,function(result){
                $rootScope.PRINTBYTES(result.data);
            },function(error){
                layer.msg($translate.instant('autopsApp.wmsIn.viewWmsInPaperFailed'));
            });
        }
        function receivingNote(reportType){
            var wmsInCopy = angular.copy(vm.wmsIn);
            angular.forEach(wmsInCopy.cargoList, function (data) {
                if (!data.outTime && data.pkgsCount){
                    data.pkgs = data.pkgsCount;
                    data.location = data.locationCopy;
                    data.locationId = data.locationIdCopy;
                    data.locationType = data.locationTypeCopy;
                    data.areaId = data.areaIdCopy;
                    data.warehouseId = data.warehouseIdCopy;
                    data.keeper = data.keeperCopy;
                    data.keeperId = data.keeperIdCopy;
                    data.allOut = true;
                }
            })
            OpsFiles.receivingNote({reportType: reportType}, wmsInCopy, function(result){
                $rootScope.PRINTBYTES(result.data);
            },function(error){
                layer.msg($translate.instant('autopsApp.wmsIn.viewWmsInPaperFailed'));
            });
        }

        $scope.textPop = function(cargo, fd) {
            $uibModal.open({
                templateUrl: 'app/entities/ops-change-log/ops-change-log-delete-dialog.html',
                controller: 'OpsChangeLogDeleteController',
                controllerAs: 'vm',
                // backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return cargo[fd];
                    }]
                }
            }).result.then(function(result) {
                cargo[fd] = result;
            }, function() {});
        }

        $scope.isPalletChange = function(cargo) {
            if(!cargo.isPallet){
                cargo.palletCapacity = null;
                cargo.palletAmt = null;
                cargo.palletizedAt = null;
            }
        }

        $scope.pkgChange = function(cargo) {
            if(!cargo.pkgMore){
                cargo.piece = null;
                cargo.pieceUnit = null;
                cargo.capacity = null;
                cargo.capacityUnit = null;
            }
        }

        $scope.batchChange = function(cargo) {
            if(!cargo.batchMore){
                cargo.batchOne = null;
                cargo.batchTwo = null;
                cargo.batchThree = null;
                cargo.batchFour = null;
            }
        }

        $scope.skuMoreChange = function(cargo) {
            cargo.skuMore = !cargo.skuMore;
            if(!cargo.skuMore){
                cargo.fnSku = null;
                cargo.upc = null;
                cargo.asin = null;
            }
        }

        $scope.synGateInTime = function(time) {
            angular.forEach(vm.wmsIn.cargoList, function(data){
                data.gateInTime = time;
            })

        }

        $scope.filter = null;
        $scope.pageFiler = function(cargo, idx){
            if($scope.filter){
                return true;
            }
            return idx >= (vm.itemsPerPage * (vm.page - 1)) && idx < (vm.page * vm.itemsPerPage) ;
        }
        $scope.wmsPltLabels = function(reportType) {
            $scope.format = 1;
            WmsIn.wmsPltLabels({
                reportType: reportType,
                fileType: $scope.format
            }, vm.wmsIn, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('autopsApp.wmsIn.viewWmsInPaperFailed'));
            });
        }

    }
})();
