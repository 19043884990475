(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('tms-virtual', {
            parent: 'ops',
            url: '/tms-virtual?page&sort&search',
            data: {
                authorities: ['ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_SALES', 'ROLE_DOCUMENT', 'ROLE_CS', 'ROLE_MARKET'],
                pageTitle: 'autopsApp.expressParcel.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/express-parcel/express-virtual.html',
                    controller: 'ExpressVirtualController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'createdTime,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('expressParcel');
                    $translatePartialLoader.addPart('guide');
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('booking');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('parcel-virtual', {
            parent: 'ops',
            url: '/parcel-virtual?page&sort&search',
            data: {
                authorities: ['ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_SALES', 'ROLE_DOCUMENT', 'ROLE_CS', 'ROLE_MARKET'],
                pageTitle: 'autopsApp.expressParcel.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/express-parcel/express-virtual.html',
                    controller: 'ExpressVirtualController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'createdTime,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('expressParcel');
                    $translatePartialLoader.addPart('guide');
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('booking');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('express-parcel', {
            parent: 'ops',
            url: '/express-parcel',
            data: {
                authorities: ['ROLE_CLIENT', 'ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_SALES', 'ROLE_DOCUMENT', 'ROLE_CS'],
                pageTitle: 'autopsApp.expressParcel.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/express-parcel/express-parcels.html',
                    controller: 'ExpressParcelController',
                    controllerAs: 'vm'
                }
            },
            params: {
                customNode: null
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('expressParcel');
                    $translatePartialLoader.addPart('guide');
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('booking');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('tms-rate-inquiry', {
            parent: 'ops',
            url: '/tms-rate-inquiry',
            data: {
                authorities: [],
                pageTitle: 'autopsApp.expressParcel.home.tmsRateInquiry'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/express-parcel/tms-rate-inquiry.html',
                    controller: 'TmsRateInquiryController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('expressParcel');
                    $translatePartialLoader.addPart('guide');
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('booking');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('wms-outbound', {
            parent: 'wms',
            url: '/wms-outbound',
            data: {
                authorities: ['ROLE_CLIENT', 'ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_SALES', 'ROLE_DOCUMENT', 'ROLE_CS'],
                pageTitle: 'autopsApp.expressParcel.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/express-parcel/express-parcels.html',
                    controller: 'ExpressParcelController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('expressParcel');
                    $translatePartialLoader.addPart('guide');
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('booking');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('parcel-biz', {
            parent: 'ops',
            url: '/parcel-biz',
            data: {
                authorities: ['ROLE_CLIENT', 'ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_SALES', 'ROLE_DOCUMENT', 'ROLE_CS'],
                pageTitle: 'autopsApp.expressParcel.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/express-parcel/express-parcels.html',
                    controller: 'ExpressParcelController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('expressParcel');
                    $translatePartialLoader.addPart('guide');
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('booking');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('express-biz', {
            parent: 'ops',
            url: '/express-biz',
            data: {
                authorities: ['ROLE_CLIENT', 'ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_SALES', 'ROLE_DOCUMENT', 'ROLE_CS'],
                pageTitle: 'autopsApp.expressParcel.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/express-parcel/express-parcels.html',
                    controller: 'ExpressParcelController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('expressParcel');
                    $translatePartialLoader.addPart('guide');
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('booking');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('tms-combine', {
            parent: 'ops',
            url: '/tms-combine',
            data: {
                authorities: ['TMS'],
                pageTitle: 'global.menu.entities.tms-combine'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/express-parcel/tms-combine.html',
                    controller: 'TmsCombineController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('guide');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('expressParcel');
                    $translatePartialLoader.addPart('wmsIn');
                    return $translate.refresh();
                }]
            }
        })
        .state('parcel-combine', {
            parent: 'ops',
            url: '/parcel-combine',
            data: {
                authorities: ['PARCEL'],
                pageTitle: 'global.menu.entities.parcel-combine'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/express-parcel/tms-combine.html',
                    controller: 'TmsCombineController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('guide');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('expressParcel');
                    $translatePartialLoader.addPart('wmsIn');
                    return $translate.refresh();
                }]
            }
        })
        .state('shipments-consol', {
            parent: 'ops',
            url: '/shipments-consol',
            data: {
                authorities: ['ROLE_OPERATOR','ROLE_DOCUMENT'],
                pageTitle: 'global.menu.entities.combineMBL'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/express-parcel/tms-combine.html',
                    controller: 'TmsCombineController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('guide');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('expressParcel');
                    $translatePartialLoader.addPart('wmsIn');
                    return $translate.refresh();
                }]
            }
        })
        .state('transportation-management', {
            parent: 'ops',
            url: '/transportation-management',
            data: {
                authorities: ['ROLE_CLIENT', 'ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_SALES', 'ROLE_DOCUMENT', 'ROLE_CS', 'ROLE_MARKET'],
                pageTitle: 'autopsApp.expressParcel.home.tmstitle'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/express-parcel/express-parcels.html',
                    controller: 'ExpressParcelController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('expressParcel');
                    $translatePartialLoader.addPart('guide');
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('booking');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('transportation-management.new', {
            parent: 'transportation-management',
            url: '/new',
            data: {
                authorities: ['TMS']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/express-parcel/express-parcel-dialog.html',
                    controller: 'ExpressParcelDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: function () {
                            var newBiz = {};
                            newBiz.status = 'new';
                            // newBiz.shiptoCountryCode = 'US';
                            newBiz.payParty = 'shipfrom';
                            newBiz.chargeType = 'prepay';
                            newBiz.shipmentType = "LTL";
                            newBiz.cargoIsOut = false;
                            newBiz.expressCargos = [];
                            return newBiz;
                        }
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^', null, { reload: true });
                });
            }]
        })
        .state('transportation-management.edit', {
            parent: 'transportation-management',
            url: '/{id}/edit',
            data: {
                authorities: ['TMS']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/express-parcel/express-parcel-dialog.html',
                    controller: 'ExpressParcelDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['ExpressParcel', 'WinAES', function(ExpressParcel, WinAES) {
                            return ExpressParcel.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^', null, { reload: false });
                });
            }]
        })
        .state('transportation-management.copy', {
            parent: 'transportation-management',
            url: '/{id}/copy',
            data: {
                authorities: ['TMS']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/express-parcel/express-parcel-dialog.html',
                    controller: 'ExpressParcelDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['ExpressParcel', 'WinAES', function(ExpressParcel, WinAES) {
                            return ExpressParcel.getCopyGuide({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    // $state.go('^');
                    $state.go('^', null, { reload: true });
                });
            }]
        })
        .state('express-parcel.new', {
            parent: 'express-parcel',
            url: '/new',
            data: {
                authorities: ['PARCEL']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/express-parcel/express-parcel-dialog.html',
                    controller: 'ExpressParcelDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: function () {
                            var newBiz = {};
                            newBiz.status = 'new';
                            newBiz.divVolBy = 6000;
                            newBiz.payParty = 'shipfrom';
                            newBiz.chargeType = 'prepay';
                            newBiz.shipmentType = "PARCEL";
                            newBiz.expressCargos = [];
                            return newBiz;
                        }
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^', null, { reload: true });
                });
            }]
        })
        .state('parcel-biz.new', {
            parent: 'parcel-biz',
            url: '/new',
            data: {
                authorities: ['PARCEL']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/express-parcel/express-parcel-dialog.html',
                    controller: 'ExpressParcelDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: function () {
                            var newBiz = {};
                            newBiz.status = 'new';
                            newBiz.divVolBy = 6000;
                            newBiz.payParty = 'shipfrom';
                            newBiz.chargeType = 'prepay';
                            newBiz.shipmentType = "LINE";
                            newBiz.expressCargos = [];
                            return newBiz;
                        }
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^', null, { reload: true });
                });
            }]
        })
        .state('express-biz.new', {
            parent: 'express-biz',
            url: '/new',
            data: {
                authorities: ['PARCEL']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/express-parcel/express-parcel-dialog.html',
                    controller: 'ExpressParcelDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: function () {
                            var newBiz = {};
                            newBiz.status = 'new';
                            newBiz.divVolBy = 6000;
                            newBiz.payParty = 'shipfrom';
                            newBiz.chargeType = 'prepay';
                            newBiz.shipmentType = "EXPRESS";
                            newBiz.expressCargos = [];
                            return newBiz;
                        }
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^', null, { reload: true });
                });
            }]
        })
        .state('wms-outbound.new', {
            parent: 'wms-outbound',
            url: '/new/{shipmentType}',
            data: {
                authorities: ['PARCEL']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                var shipmentType = $stateParams.shipmentType;
                if(!shipmentType){
                    shipmentType = "PARCEL";
                }
                $uibModal.open({
                    templateUrl: 'app/entities/express-parcel/express-parcel-dialog.html',
                    controller: 'ExpressParcelDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: function () {
                            var newBiz = {};
                            newBiz.status = 'new';
                            newBiz.divVolBy = 6000;
                            newBiz.payParty = 'shipfrom';
                            newBiz.chargeType = 'prepay';
                            newBiz.shipmentType = shipmentType;
                            newBiz.expressCargos = [];
                            return newBiz;
                        }
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^', null, { reload: true });
                });
            }]
        })
        .state('express-parcel.edit', {
            parent: 'express-parcel',
            url: '/{id}/edit',
            data: {
                authorities: ['PARCEL']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/express-parcel/express-parcel-dialog.html',
                    controller: 'ExpressParcelDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['ExpressParcel', 'WinAES', function(ExpressParcel, WinAES) {
                            return ExpressParcel.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^', null, { reload: false });
                });
            }]
        })
        .state('parcel-biz.edit', {
            parent: 'parcel-biz',
            url: '/{id}/edit',
            data: {
                authorities: ['PARCEL']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/express-parcel/express-parcel-dialog.html',
                    controller: 'ExpressParcelDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['ExpressParcel', 'WinAES', function(ExpressParcel, WinAES) {
                            return ExpressParcel.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^', null, { reload: false });
                });
            }]
        })
        .state('wms-outbound.edit', {
            parent: 'wms-outbound',
            url: '/{id}/edit',
            data: {
                authorities: ['PARCEL']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/express-parcel/express-parcel-dialog.html',
                    controller: 'ExpressParcelDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['ExpressParcel', 'WinAES', function(ExpressParcel, WinAES) {
                            return ExpressParcel.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^', null, { reload: false });
                });
            }]
        })
        .state('express-biz.edit', {
            parent: 'express-biz',
            url: '/{id}/edit',
            data: {
                authorities: ['PARCEL']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/express-parcel/express-parcel-dialog.html',
                    controller: 'ExpressParcelDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['ExpressParcel', 'WinAES', function(ExpressParcel, WinAES) {
                            return ExpressParcel.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    $state.go('^', null, { reload: false });
                });
            }]
        })
        .state('express-parcel.copy', {
            parent: 'express-parcel',
            url: '/{id}/copy',
            data: {
                authorities: ['PARCEL']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/express-parcel/express-parcel-dialog.html',
                    controller: 'ExpressParcelDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['ExpressParcel', 'WinAES', function(ExpressParcel, WinAES) {
                            return ExpressParcel.getCopyGuide({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    // $state.go('^');
                    $state.go('^', null, { reload: true });
                });
            }]
        })
        .state('wms-outbound.copy', {
            parent: 'wms-outbound',
            url: '/{id}/copy',
            data: {
                authorities: ['PARCEL']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/express-parcel/express-parcel-dialog.html',
                    controller: 'ExpressParcelDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['ExpressParcel', 'WinAES', function(ExpressParcel, WinAES) {
                            return ExpressParcel.getCopyGuide({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    // $state.go('^');
                    $state.go('^', null, { reload: true });
                });
            }]
        })
        .state('express-biz.copy', {
            parent: 'express-biz',
            url: '/{id}/copy',
            data: {
                authorities: ['PARCEL']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/express-parcel/express-parcel-dialog.html',
                    controller: 'ExpressParcelDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['ExpressParcel', 'WinAES', function(ExpressParcel, WinAES) {
                            return ExpressParcel.getCopyGuide({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    // $state.go('^');
                    $state.go('^', null, { reload: true });
                });
            }]
        })
        .state('parcel-biz.copy', {
            parent: 'parcel-biz',
            url: '/{id}/copy',
            data: {
                authorities: ['PARCEL']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/express-parcel/express-parcel-dialog.html',
                    controller: 'ExpressParcelDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['ExpressParcel', 'WinAES', function(ExpressParcel, WinAES) {
                            return ExpressParcel.getCopyGuide({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', null, { reload: true });
                }, function() {
                    // $state.go('^');
                    $state.go('^', null, { reload: true });
                });
            }]
        });
    }

})();
