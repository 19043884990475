(function () {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GridStateDialogExcelController', GridStateDialogExcelController);

    GridStateDialogExcelController.$inject = ['$rootScope', '$scope', '$uibModalInstance', 
        'entity', 'GridState', '$timeout', '$translate', 'OpsFiles', 'DataUtils', 'OrderNode'];

    function GridStateDialogExcelController($rootScope, $scope, $uibModalInstance, 
        entity, GridState, $timeout, $translate, OpsFiles, DataUtils, OrderNode) {
        var vm = this;

        $scope.gridState = entity;
        vm.clear = clear;
        vm.save = save;
        vm.addHead = addHead;
        vm.delHead = delHead;
        vm.addBody = addBody;
        vm.delBody = delBody;
        vm.changeType = changeType;
        vm.reloadGridState = reloadGridState;
        vm.headFields = [];
        vm.bodyFields = [];
        $scope.files = [];

        function initGridState() {
            if ($scope.gridState.gridState) {
                vm.data = angular.fromJson($scope.gridState.gridState);
            }
            if (!vm.data) {
                vm.data = {};
                vm.data.heads = [];
                vm.data.bodys = [];
            }
            changeType();
        }

        $timeout(function () {
            initGridState();
        })

        function reloadGridState(gridStateId) {
            if(!gridStateId) return;
            GridState.getOneCustomExcelImport({ id: gridStateId}, function (result) {
                $scope.gridState = result;
                initGridState();
            }, $rootScope.ALERT_ERROR)
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
        function save() {
            for(var i = 0; i < vm.data.bodys.length; i++){
                if(vm.data.bodys[i].location && vm.data.bodys[i].colTitle){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.gridState.bodyBothAlert', {param: vm.data.bodys[i].fieldName}));
                    return;
                }
                if(!vm.data.bodys[i].location && !vm.data.bodys[i].colTitle){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.gridState.bodyNonAlert', {param: vm.data.bodys[i].fieldName}));
                    return;
                }
            }
            $scope.gridState.gridState = angular.toJson(vm.data);
            GridState.saveCustomRecord($scope.gridState, function (result) {
                layer.msg($translate.instant('global.save_success'));
                reloadGridState(result.id);
            }, $rootScope.ALERT_ERROR)
        }
        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function (date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        function addHead() {
            vm.data.heads.push({
                field: null,
                location: "",
                cannotBlank: false
            })
        }

        function delHead(head) {
            var index = vm.data.heads.indexOf(head);
            if (index > -1) {
                vm.data.heads.splice(index, 1);
            };
        }

        function addBody() {
            vm.data.bodys.push({
                field: null,
                location: "",
                cannotBlank: false
            })
        }

        function delBody(body) {
            var index = vm.data.bodys.indexOf(body);
            if (index > -1) {
                vm.data.bodys.splice(index, 1);
            };
        }

        $scope.deleOssFile = function (gridState) {
            OpsFiles.delOssFile({
                filepath: gridState.filepath
            }, function (result) {
                reloadGridState($scope.gridState.id);
                layer.msg($translate.instant('global.delete_success'));
            }, function (reject) {
                layer.msg($translate.instant('global.delete_fail'));
            }, function (error) {
                console.log(error);
            });
        }

        function changeType() {
            if ($scope.gridState.gridId == "1") {
                // 导入一个FBA订单
                vm.data.bodyType = "bookingCtns";
                vm.headFieldValue = ['shipmentType', 'poNum', 'fbaRef', 'shipmentId', 'soNum', 'BKsoNum', 'hblNum', 'mblNum', 'etd', 'cargoReadyDate',
                    'eta', 'vsl', 'voy', 'carrier', 'productName', 'deliveryWay', 'deliveryChannel', 'BKcarrierFullName', 'tkState',
                    'cargoType', 'pkgNum', 'BKpkgType', 'gw', 'vol', 'chargeWeight', 'BKdivVolBy', 'vw', 'BKvolumeShare', 'whName', 'chargeByCbm', 
                    'salesName', 'checkByName', 'cusName', 'overseasCs', 'opName', 'docName', 'finName', 'bookingBy', 'truckBy', 'customBy', 
                    'customsType', 'remarks', 'BKremarks', 'bizType', 'internalType', 'customerName', 'shipperName', 'bookingToName', 
                    'deliveryName', 'clearanceAgent', 'cargoName', 'BKhsCode', 'pieces', 'BKtradeTerm', 'cargoValue', 'siCutTime', 'warehouseName', 'fumigationName', 'traderName', 'insuranceName', 
                    'cls', 'billDate', 'BKetd', 'deliveryDate', 'freightTerm', 'serviceTerm', 'refNum', 'thirdpartyPlatformRef', 'sayCtns', "valueCur",
                    'BKporName', 'BKpolName', 'BKpodName', 'BKdestName', 'BKdeliveryTo', 'BKimporterName', 'BKimporterTaxId', 'BKimporterEori',
                    'BKimporterAddress', 'BKshipperBl', 'BKcneeBl', 'BKnotifyBl', 'BKdeliveryBl', 'BKmark', 'BKgoodsDiscription', 'deliveryBl', 'shipfromCode',
                    'serviceDescription', 'shiptoCode', 'BKbookingToAttn', 'BKhblNum', 'destCountryCode', 'shiptoPostcode', 'BKbookingToEmail', 'BKbookingToFax',
                    'BKbookingToAddress', 'BKbookingToMob', 'BKbookingToTel', 'templateName', 'customRequired', 'truckRequired', 'insuranceRequired', 'fumigationRequired', 'warehouseRequired', 'coRequired'];
                vm.bodyFieldValue = ["ctnType", "gw", "vol", "pkgNum", "pkgType", "length", "width", "height", "cargoName", "model", "electric", "magnetic",
                    "hsCode", "fbaRef", "material", "useAge", "pieces","unitPrice", "totalPrice", "sku", "taxRefund",
                    "pallets", "brand", "remarks", "nw", "cargoNameEn","mark", "dutyRate", "dutyAudited", "shipmentId", "materialEn",
                    "useAgeEn", "modelEn", "brandEn", "importCountryCode", "gwTtl", "volTtl", "productUrl", "cargoPic", "productSkuPic", "productSellPic", "productPricePic"];
            }
            else if($scope.gridState.gridId == "2") {
                vm.data.bodyType = "bookingCtns";
                vm.headFieldValue = ['shipmentType', 'poNum', 'fbaRef', 'shipmentId', 'soNum', 'BKsoNum', 'hblNum', 'mblNum', 'etd', 'cargoReadyDate',
                    'eta', 'vsl', 'voy', 'carrier', 'productName', 'deliveryWay', 'deliveryChannel', 'BKcarrierFullName', 'tkState', 
                    'warehouseName', 'fumigationName', 'traderName', 'insuranceName', 
                    'cargoType', 'pkgNum', 'BKpkgType', 'gw', 'vol', 'chargeWeight', 'BKdivVolBy', 'vw', 'BKvolumeShare', 'whName', 'chargeByCbm', 'salesName', 'customsType', 'remarks', 'BKremarks', 'bizType', 'internalType', 'customerName', 'shipperName',
                    'bookingToName', 'deliveryName', 'clearanceAgent', 'cargoName', 'BKhsCode', 'pieces', 'BKtradeTerm', 'cargoValue', 'siCutTime',
                    'cls', 'billDate', 'BKetd', 'deliveryDate', 'freightTerm', 'serviceTerm', 'refNum', 'thirdpartyPlatformRef', 'sayCtns', "valueCur",
                    'BKporName', 'BKpolName', 'BKpodName', 'BKdestName', 'BKdeliveryTo', 'BKimporterName', 'BKimporterTaxId', 'BKimporterEori',
                    'BKimporterAddress', 'BKshipperBl', 'BKcneeBl', 'BKnotifyBl', 'BKdeliveryBl', 'BKmark', 'BKgoodsDiscription', 'deliveryBl', 'shipfromCode',
                    'serviceDescription', 'shiptoCode', 'BKbookingToAttn', 'BKhblNum', 'destCountryCode', 'shiptoPostcode', 'BKbookingToEmail', 'BKbookingToFax',
                    'BKbookingToAddress', 'BKbookingToMob', 'BKbookingToTel', 'templateName', 'customRequired', 'truckRequired', 'insuranceRequired', 'fumigationRequired', 'warehouseRequired', 'coRequired'];
                vm.bodyFieldValue = ["ctnType", "gw", "vol", "pkgNum", "pkgType", "length", "width", "height", "cargoName", "model", "electric", "magnetic",
                    "hsCode", "fbaRef", "material", "useAge", "pieces","unitPrice", "totalPrice", "sku", "taxRefund",
                    "pallets", "brand", "remarks", "nw", "cargoNameEn","mark", "dutyRate", "dutyAudited", "shipmentId", "materialEn",
                    "useAgeEn", "modelEn", "brandEn", "importCountryCode", "gwTtl", "volTtl", "productUrl", "cargoPic", "productSkuPic",
                    "productSellPic", "productPricePic"];
            }
            else if($scope.gridState.gridId == "3") {
                vm.data.bodyType == "bookingCtns";
                vm.headFieldValue = ['templateName', 'shipmentType'];
                // Guide单字段
                vm.bodyFieldValue = ['soNum', 'mblNum', 'hblNum', 'carrier', 'lane', 'vsl', 'voy', 'etd', 'eta', 'pkgNum', 'BKpkgType', 'gw', 'vol',
                    'BKdivVolBy', 'vw', 'BKpkgNum', 'BKgw', 'BKvol', 'whName', 'warehouseName', 'fumigationName', 'traderName', 'insuranceName', 'salesName', 'cusName', 'overseasCs', 'opName', 'docName',
                    'finName', 'bookingBy', 'truckBy', 'customBy', 'remarks', 'BKremarks', 'bizType', 'internalType', 'checkByName',
                    'customerName', 'shipperName', 'bookingToName', 'deliveryName', 'clearanceAgent', 'productName', 'deliveryWay', 'deliveryChannel',
                    'cargoReadyDate', 'BKetd', 'soExpectedTime', 'poNum', 'BKwmsNum', 'BKsoNum', 'cargoName', 'BKtradeTerm', 'cargoValue',
                    'BKhsCode', 'pieces', 'cargoType', 'dock', 'polRegion', 'podRegion', 'siCutTime', 'vgm', 'cls', 'billDate', 'deliveryDate',
                    'refNum', 'thirdpartyPlatformRef', 'BKporName', 'BKpolName', 'BKpodName', 'BKdestName', 'BKdeliveryTo', 'BKserviceTerm',
                    'hblRlsWay', 'BKfreightTerm', 'awbCharges','porName','polName','podName','destName','destCountryCode','deliveryTo',
                    'serviceTerm','releaseWay','freightTerm', 'shiptoCode', 'BKbookingToAttn', 'BKhblNum', 'shiptoPostcode', 'BKbookingToEmail', 'BKbookingToFax',
                    'BKbookingToAddress', 'BKbookingToMob', 'BKbookingToTel', 'valueCur', 'customsType', 'customRequired', 'truckRequired', 'insuranceRequired', 'fumigationRequired', 'warehouseRequired', 'coRequired'];
                // 装箱单字段
                vm.bodyFieldValue2 = ["ctnType", "gw", "vol", "pkgNum", "pkgType", "length", "width", "height", "cargoName", "model", "electric", "magnetic",
                    "hsCode", "fbaRef", "material", "useAge", "pieces","unitPrice", "totalPrice", "sku", "taxRefund",
                    "pallets", "brand", "remarks", "nw", "cargoNameEn","mark", "dutyRate", "dutyAmt", "dutyAudited", "shipmentId", "materialEn",
                    "useAgeEn", "modelEn", "brandEn", "importCountryCode", "gwTtl", "volTtl", "productUrl", "cargoPic", "productSkuPic", "productSellPic", "productPricePic"];
            } else if($scope.gridState.gridId == "4" || $scope.gridState.gridId == "7") {
                // 快递/小包订单导入
                vm.data.bodyType == "expressCargos";
                vm.headFieldValue = ["templateName", "shipmentType", "updateTask"];
                if ($scope.gridState.gridId == "7") {
                    vm.headFieldValue = ["templateName", "orderType"];
                }
                vm.bodyFieldValue = ["shipperName", "bookingToName","jobNum", "innerRemarks", "carrier", "remarks", "salesName", "opName", "cusName", "mblNum", "soNum", "poNum",
                "goodsDiscription", "etd","gw", "vol", "cargoType", "hsCode", "pkgNum", "pkgType", "divVolBy", "tradeTerm", "ctnNum",
                "shiptoCompanyName", "shiptoAttentionName", "shiptoPhoneNumber", "shiptoCity", "shiptoPostalCode", "shiptoProvinceCode", "shiptoCountryCode", "shiptoAddressLineOne", "shiptoAddressLineTwo",
                "shiptoAddressLineThree", "serviceType", "warehouseName", "payParty", "shipfromCode", "shiptoCode", "shiptoEmailAddress", "channel", "cargoValueUsd", "eta", "finName",
                "dispatcher", "storekeeper", "pickingNum", "dcType", "platform", "shipperExt", "shiptoExt", "inchOz", "collect", "collectCur", "sellAmt", "sellCur", "saleFreight",
                "platformFee", "thirdpartyPlatform", "isRemote", "onHold", "otherFee","irregularPacking", "isResidential", "isSuperRemote", "addressModify", "poNumber",
                "bookingToCode", "insuranceRequired", "shipperBl", "cneeBl", "importerTaxId", "shiptoDoorplate", "bizType", "costChannel", "remotePostalCode", "dutyPayParty", "ownImporterCode", "ownImporter",
                "accountCode", "dutyPayPartyCode", "shipmentType"];
                // 货物
                if($scope.gridState.gridId == "7"){
                    vm.bodyFieldValue2 = ["sku","pkgs"];
                }
                else {
                    vm.bodyFieldValue2 = ["sku","cargoName","packageType","pkgs","gw","vol","length","width","height","shipmentId",
                    "fba","pallets", "gwTtl","volTtl","remarks","hsCode","quantity","usdValuePerUnit","usdValueTtl","originalCountry","brand","model","material","useAge",
                    "cargoNameEn","productUrl","parcelGoods", "cargoPic"];
                }                
            } else if($scope.gridState.gridId == "5") {
                // 入仓单导入
                vm.data.bodyType == "expressCargos";
                vm.headFieldValue = ["templateName"];
                vm.bodyFieldValue = ["clientName","mark","cargoDescription","gw","vol","remarks",
                "warehouseName","podName","type","customNum","poNum","expectedWarehousingTime","ctnNum","truckNum","handleType","ctnType","freeDays", "deliveryBySelf"];
                // 货物
                vm.bodyFieldValue2 = ["gwTtl","volTtl","code","cargoName","packageType","gw","vol","length","width","height","pkgs",
                "location","palletizedAt","palletCapacity","remarks","chargeType","chargePrice","returnTracking", "upc", "fnSku", "asin", "goodsValue", "dutyRate", "declareValue",
                "batchOne","batchTwo","batchThree","batchFour","sku","piece","pieceUnit","nw","capacity","capacityUnit","mark","cargoNameEn"];
            } else if($scope.gridState.gridId == "6") {
                // 出仓单导入
                vm.data.bodyType == "expressCargos";
                vm.headFieldValue = ["templateName"];
                vm.bodyFieldValue = ["clientName","mark","cargoDescription","remarks","warehouseName","podName","type","customNum","poNum","expectedWarehousingTime",
                "ctnNum","truckNum","pallets","cusName"];
                // 货物
                vm.bodyFieldValue2 = ["sku","pkgs"]
            }

            vm.headFields = [];
            vm.bodyFields = [];
            if ($scope.gridState.gridId == "1" || $scope.gridState.gridId == "2") {
                angular.forEach(vm.headFieldValue, function (item) {
                    var data = {};
                    data.name = item;
                    if (item == 'templateName') {
                        data.langName = $translate.instant('autopsApp.gridState.allField.templateName');
                    }
                    else if (item == 'chargeByCbm') {
                        data.langName = $translate.instant('autopsApp.guide.chargeByCbm');
                    } else {
                        DataUtils.getLangNameFba(data);
                    }
                    vm.headFields.push(data);
                })
                OrderNode.getAllOrderNodes(function(result){
                    angular.forEach(result, function (item) {
                        var data = {
                            name: item,
                            langName: item
                        };
                        vm.headFields.push(data);
                    })
                })
                angular.forEach(vm.bodyFieldValue, function (item) {
                    var data = {};
                    data.name = item;
                    getLangNameBookingCtns(data);
                    vm.bodyFields.push(data);
                })
            }
            if ($scope.gridState.gridId == "3") {
                angular.forEach(vm.headFieldValue, function (item) {
                    var data = {};
                    data.name = item;
                    if (item == 'templateName') {
                        data.langName = $translate.instant('autopsApp.gridState.allField.templateName');
                    } else if (item == 'chargeByCbm') {
                        data.langName = $translate.instant('autopsApp.guide.chargeByCbm');
                    } else {
                        DataUtils.getLangNameFba(data);
                    }
                    vm.headFields.push(data);
                })

                angular.forEach(vm.bodyFieldValue, function (item) {
                    var data = {};
                    data.name = item;
                    DataUtils.getLangNameFba(data);
                    vm.bodyFields.push(data);
                })

                angular.forEach(vm.bodyFieldValue2, function (item) {
                    var data = {};
                    data.name = item;
                    getLangNameBookingCtns(data);
                    data.name = "PL" + data.name;
                    data.langName = data.langName + '(装箱单)'
                    vm.bodyFields.push(data);
                })
            }

            if ($scope.gridState.gridId == "4" || $scope.gridState.gridId == "7") {
                angular.forEach(vm.headFieldValue, function (item) {
                    var data = {};
                    data.name = item;
                    if (item == 'templateName') {
                        data.langName = $translate.instant('autopsApp.gridState.allField.templateName');
                    } else if (item == 'updateTask') {
                        data.langName = $translate.instant('autopsApp.gridState.allField.expressParcel.updateTask');
                    } else if (item == 'orderType') {
                        data.langName = $translate.instant('autopsApp.gridState.allField.expressParcel.orderType');
                    }  else {
                        DataUtils.getLangNameFms(data);
                    }
                    vm.headFields.push(data);
                })

                var otherField = ["shipperName", "bookingToName", "mblNum", "soNum", "labelBindType", "updateTask", "salesName", "opName", "cusName", "dispatcher",
                "storekeeper", "finName", "sku", "length", "width", "height", "fba", "quantity", "usdValuePerUnit", "usdValueTtl", "packageType", "shipfromCode", "shiptoCode",
                "shiptoCompanyName", "shiptoAttentionName", "shiptoPhoneNumber", "shiptoCity", "shiptoPostalCode", "shiptoProvinceCode", "shiptoCountryCode", "shiptoAddressLineOne", "shiptoAddressLineTwo",
                "shiptoAddressLineThree", "shiptoEmailAddress", "cargoValueUsd", "shipperExt", "shiptoExt", "collectCur", "sellCur", "poNumber", "shiptoDoorplate", "ownImporterCode",
                "accountCode", "dutyPayPartyCode", "goodsDiscription", "ctnNum"];
                angular.forEach(vm.bodyFieldValue, function (item) {
                    var data = {};
                    data.name = item;
                    if (otherField.indexOf(data.name) != -1) {
                        data.langName = $translate.instant('autopsApp.gridState.allField.expressParcel.' + data.name);
                    } else {
                        data.langName = $translate.instant('autopsApp.expressParcel.' + data.name);
                    }
                    if(data.langName.startsWith("autopsApp")) {
                        data.langName = $translate.instant('autopsApp.guide.' + data.name);
                    }
                    if(data.langName.startsWith("autopsApp")) {
                        data.langName = $translate.instant('autopsApp.booking.' + data.name);
                    }
                    if (data.langName.startsWith("autopsApp")) {
                        data.langName = $translate.instant('autopsApp.booking.bookingCtns.' + data.name);
                    }
                    vm.bodyFields.push(data);
                })

                angular.forEach(vm.bodyFieldValue2, function (item) {
                    var data = {};
                    data.name = item;
                    if (otherField.indexOf(data.name) != -1) {
                        data.langName = $translate.instant('autopsApp.gridState.allField.expressParcel.' + data.name);
                    } else {
                        data.langName = $translate.instant('autopsApp.expressParcel.' + data.name);
                    }
                    if(data.langName.startsWith("autopsApp")) {
                        data.langName = $translate.instant('autopsApp.guide.' + data.name);
                    }
                    if(data.langName.startsWith("autopsApp")) {
                        data.langName = $translate.instant('autopsApp.booking.' + data.name);
                    }
                    if (data.langName.startsWith("autopsApp")) {
                        data.langName = $translate.instant('autopsApp.booking.bookingCtns.' + data.name);
                    }
                    data.name = "CARGO" + data.name;
                    data.langName = data.langName + '(物品)';
                    vm.bodyFields.push(data);
                })
            }

            if ($scope.gridState.gridId == "5" || $scope.gridState.gridId == "6") {
                angular.forEach(vm.headFieldValue, function (item) {
                    var data = {};
                    data.name = item;
                    if (item == 'templateName') {
                        data.langName = $translate.instant('autopsApp.gridState.allField.templateName');
                    }else {
                        DataUtils.getLangNameFms(data);
                    }
                    vm.headFields.push(data);
                })

                var otherField = ["handleType","packageType","batchOne","batchTwo","batchThree","batchFour","sku","warehouseName", "palletCapacity"];
                angular.forEach(vm.bodyFieldValue, function (item) {
                    var data = {};
                    data.name = item;
                    if (otherField.indexOf(data.name) != -1) {
                        data.langName = $translate.instant('autopsApp.gridState.allField.wmsIn.' + data.name);
                    } else {
                        data.langName = $translate.instant('autopsApp.wmsIn.' + data.name);
                    }
                    if ($scope.gridState.gridId == "6" && (data.name == 'expectedWarehousingTime' || data.name == 'pallets')) {
                        data.langName = $translate.instant('autopsApp.gridState.allField.wmsInIsOut.' + data.name);
                    }
                    vm.bodyFields.push(data);
                })

                var isPalletField = ["pkgs", "palletCapacity"];
                var pkgMoreField = ["piece", "pieceUnit", "pieceTtl", "capacity", "capacityUnit", "capacityTtl"];
                var batchMoreField = ["batchOne", "batchTwo", "batchThree", "batchFour"];

                angular.forEach(vm.bodyFieldValue2, function (item) {
                    var data = {};
                    data.name = item;
                    if (otherField.indexOf(data.name) != -1) {
                        data.langName = $translate.instant('autopsApp.gridState.allField.wmsIn.' + data.name);
                    }else {
                        data.langName = $translate.instant('autopsApp.wmsIn.' + data.name);
                    }
                    if (isPalletField.indexOf(data.name) != -1) {
                        data.langName = data.langName + '(物品) - 托盘/大包装';
                    } else if (pkgMoreField.indexOf(data.name) != -1) {
                        data.langName = data.langName + '(物品) - 小包装';
                    } else if (batchMoreField.indexOf(data.name) != -1) {
                        data.langName = data.langName + '(物品) - 批次';
                    } else {
                        data.langName = data.langName + '(物品)';
                    }
                    data.name = "CARGO" + data.name;
                    vm.bodyFields.push(data);
                })
            }
        }

        function getLangNameBookingCtns(data) {
            var otherField = ["length", "width", "height", "shipmentId", "materialEn", "useAgeEn", "modelEn", "brandEn", "hsCode", "fbaRef", "sku"];
            if(otherField.indexOf(data.name) != -1) {
                data.langName = $translate.instant("autopsApp.gridState.allField.bookingCtns." + data.name);
            }else {
                data.langName = $translate.instant("autopsApp.booking.bookingCtns." + data.name);
            }
        }
        $scope.dateArray = ["lfd", "eta", "etd", "feederEtd", "deliveryDate", "transferDate", "billDate", "opDate", "atd", "revDeadLine", "cargoReadyDate", "bookingEtd", "BKetd", "BKissueDate"];
        $scope.timeArray = ["ctnReturnTime", "createdTime", "cls", "lastModifiedTime", "bookingTime", "soReleaseTime", "truckArrangeTime", "customArrangeTime", "siSubmitedTime", "hblConfirmedTime", "mblConfirmedTime", "dnSentTime", "saSentTime", "hblReleasedTime", "mblReleasedTime", "invoiceIssuedTime", "invoiceReceivedTime", "paymentPaidTime", "paymentReceivedTime", "siCutTime", "localTimeOne", "localTimeTwo", "otherTimeOne", "otherTimeTwo", "otherTimeThree", "vgmTime", "vgm", "ctnsLoadedTime", "customReleaseTime", "deliveryTime", "plConfirmTime", "customClearTime", "ataTime", "deliveryAppointmentTime", "soExpectedTime", "applyRlsTime", "deliveryGuideTime", "closeTime", "ctnAvailableTime", "BKcreatedTime", "BKlastModifiedTime", "BKsiReceivedTime"];
        $scope.numArray = ["goodsValue", "declareValue", "dutyRate", "wmsGw", "wmsVol", "wmsCw", "id", "shipperId", "createdBy", "checkById", "deliveryId", "bookingToId", "warehouseVid", "fumigationVid", "traderVid", "insuranceVid", "refGuideId", "salesId", "opId", "docId", "finId", "companyId", "branchGuideId", "agentUserId", "customerId", "masterId", "cusId", "awbWeight", "awbPrice", "awbChargesTtl", "costId", "bookingById", "truckById", "customById", "timeStamp", "agentGuideId", "expressLabelId", "docStamp", "overseasCsId", "customerContactId", "shipperContactId", "bookingToContactId", "deliveryContactId", "warehouseContactId", "fumigationContactId", "insuranceContactId", "traderContactId", "medCompanyId", "medVenderId", "gw", "vol", "pkgNum", "shipperUserId", "whId", "wmsActAmt", "customerUserId", "bookingId", "chargeWeight", "pieces", "palletNum", "vw", "shipFromId", "shipToId", "parcelId", "thirdpartyPlatformId", "clearanceAgentId", "clearanceAgentContactId", "BKid", "BKbookingToId", "BKcneeId", "BKgw", "BKvol", "BKpkgNum", "BKguideId", "BKdivVolBy", "BKtempFrom", "BKtempTo", "BKrefGuideId", "BKrating", "BKsellingId", "BKnoOfCopies", "BKvolumeShare", "BKcreatedBy", "BKchargeWeight", "BKcompanyId"];
        $scope.booleanArray = ["electric", "magnetic", "dutyAudited", "isWood", "isExport", "isTemplate", "ratesLocked", "isLocked", "isApplied", "isClosed", "issueFeederHbl", "etdLocked", "mblOnly", "deliverySecret", "trackingFinished", "hidenCost", "isVoid", "spx", "hidenSelling", "allBilled", "truckRequired", "customRequired", 'truckRequired', 'insuranceRequired', 'fumigationRequired', 'warehouseRequired', 'coRequired', "vat", "incomeTax", "isDelivering", "feederEtdConfirmed", "salesChecked", "submited", "dontSynTtl", "chargesAsArranged", "priceAsArranged", "attachMark", "attachDescription", "attachCtns", "isVirtual", "asWmsIn", "asWmsOut", "plConfirmed", "parcelMaster", "chargeByCbm", "isPallet", "ownImporter", "autoTracking", "consolidated", "isRemote", "onHold", "customsInspection", "labelVirtual", "autoSendPlCheck", "isResidential", "headTrackFinished", "orderPushTag", "directDelivery", "assistChargesConfirmed", "insuranceRequired", "fumigationRequired", "warehouseRequired", "coRequired", "BKisTemplate", "BKisLocked", "BKetdConfirmed", "BKattachCtns", "BKmagneticInspection", "BKidentification", "BKotherService", "BKhblPrinted", "BKattachMark", "BKinclTax", "BKattachDescription", "BKplConfirmed", "remotePostalCode", "deliveryBySelf"];
        $scope.clerkArray = ["salesName", "opName", "docName", "overseasCs", "cusName", "customBy", "checkByName", "finName", "truckBy"];
        $scope.picArray = ["cargoPic", "productSkuPic", "productSellPic", "productPricePic"];

        $scope.placeTips = function (item) {
            item.tips = null;
            var itemFd = item.field;
            if(itemFd && itemFd.startsWith("CARGO")){
                itemFd = itemFd.replace("CARGO", "");
            }
            if($scope.dateArray.indexOf(itemFd) != -1){
                item.tips =  $translate.instant("autopsApp.gridState.tips.date");
            }
            if($scope.timeArray.indexOf(itemFd) != -1){
                item.tips = $translate.instant("autopsApp.gridState.tips.time");
            }
            if($scope.numArray.indexOf(itemFd) != -1){
                item.tips = $translate.instant("autopsApp.gridState.tips.number");
            }
            if($scope.booleanArray.indexOf(itemFd) != -1){
                item.tips = $translate.instant("autopsApp.gridState.tips.boolean");
            }
            if($scope.clerkArray.indexOf(itemFd) != -1){
                item.tips = $translate.instant("autopsApp.gridState.tips.clerk");
            }
        }

        $scope.clearDefaultValue = function (data) {
            data.defaultValue = "";
        }

        // 不允许手动输入默认值的字段
        var notStringArray = ["shipmentType", "tkState", "deliveryWay", "cargoType", "bizType", "internalType", "freightTerm", "BKfreightTerm", "serviceTerm", "BKserviceTerm", "type", "handleType"];
        $scope.displayStringInput = function (field) {
            if(notStringArray.indexOf(field) != -1 || $scope.booleanArray.indexOf(field) != -1 || $scope.clerkArray.indexOf(field) != -1){
                return false;
            }
            return true;
        }
    }
})();
