(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AddressBookController', AddressBookController);

    AddressBookController.$inject = ['$scope', '$state', '$http', '$translate', '$location', '$rootScope', '$timeout',
    'WinAES', 'AddressBook', 'AddressBookSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function AddressBookController ($scope, $state, $http, $translate, $location, $rootScope, $timeout,
        WinAES, AddressBook, AddressBookSearch, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        if ($location.path().indexOf("label-edi") >= 0){
            $rootScope.IS_LABEL_EDI = true;
        }
        else{
            $rootScope.IS_LABEL_EDI = false;
        }

        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                AddressBookSearch.query({
                    labelEdi: $rootScope.IS_LABEL_EDI,
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                AddressBook.query({
                    labelEdi: $rootScope.IS_LABEL_EDI,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.addressBooks = data;
                vm.page = pagingParams.page;
                angular.forEach(vm.addressBooks, function(data){
                    data.encodeId = WinAES.encode(data.id.toString())
                })
            }
            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        $scope.excelImport = function(file) {
            if($rootScope.account.companyId && $rootScope.HAVENOAUTH('FUN_ADDRESS_BOOK')){
                $rootScope.FORBIDDEN_BY_ALERT('FUN_ADDRESS_BOOK');
                return;
            }
            if(!file) {return;}
            var fd = new FormData();
            fd.append('file', file);
            $http.post(
                'api/address-books/importByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    loadAll();
                    data = angular.fromJson(data);
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.addressBook.importSuccess', {
                        successAmt: data.successAmt}));
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                });
        };
        $scope.groupShareOne = function(exchangeRate){
            layer.confirm($translate.instant('autopsApp.addressBook.groupShareOneAlert'), {
                icon: 3,
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                layer.close(index);
                AddressBook.groupShareOne({id: exchangeRate.id}, function(){
                    layer.msg($translate.instant('global.syn_success'));
                }, $rootScope.ALERT_ERROR);
            }, function() {
            });
        }
        $timeout(function (){
            $rootScope.RESIZE();
            if($rootScope.HAVEAUTH("SUB_ADDR_FB") && !$rootScope.account.companyId){
                $state.go('home', null, { reload: true });
            }
        });
        $scope.exportExcel = function() {
            var fileName = "address_book.xlsx";
            var loadIndex = layer.load(1, {shade: 0.3});
            AddressBook.exportExcel({
                enCn: $translate.instant('global.enCn')
            }, null, function(result) {
                layer.close(loadIndex);
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }
    }
})();
