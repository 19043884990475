(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('Guide', Guide);

    Guide.$inject = ['$resource', 'DataUtils', 'DateUtils','WinAES'];
    
    function Guide($resource, DataUtils, DateUtils, WinAES) {
        function initGuideResponse(data){
            if (!data){return;}    
            data = angular.fromJson(data);  
            convertDateAndTimeFromServer(data);    
            DataUtils.fillGuideShowStatus(data);
            return data;
        }
        var dateFds = ["lfd", "etd", "eta", "cargoReadyDate", "billDate", "opDate", "bookingEtd", "atd", "feederEtd", "deliveryDate", "transferDate", "revDeadLine"];
        var timeFds = ["cls", "siCutTime", "createdTime", "siCutTime", "soExpectedTime", "vgm", "lastModifiedTime", "paymentPaidTime", 
            "paymentReceivedTime", "hblReleasedTime", "bookingTime", "soReleaseTime", "truckArrangeTime", "customArrangeTime", "siSubmitedTime",
            "hblConfirmedTime", "mblConfirmedTime", "dnSentTime", "saSentTime", "localTimeOne", "localTimeTwo", "otherTimeOne", "otherTimeTwo", 
            "otherTimeThree", "vgmTime", "ctnsLoadedTime", "customReleaseTime", "mblReleasedTime", "deliveryTime", "plConfirmTime", "customClearTime", 
            "ataTime", "deliveryAppointmentTime", "applyRlsTime", "siCutTime", "invoiceIssuedTime", "invoiceReceivedTime", "closeTime", 
            "ctnAvailableTime", "ctnReturnTime"];
        function convertDateAndTimeFromServer(data){
            DateUtils.convertObjDateAndTimeFromServer(data, dateFds, timeFds);
            if (data.booking){
                angular.forEach(data.booking.bookingTrucks, function(bookingTruck){
                    bookingTruck.loadTime = DateUtils.convertDateTimeFromServer(bookingTruck.loadTime);
                })
                data.booking.issueDate = DateUtils.convertLocalDateFromServer(data.booking.issueDate);
                data.booking.etd = DateUtils.convertLocalDateFromServer(data.booking.etd);
            }
            angular.forEach(data.bookingCustoms, function(bookingCustom){
                bookingCustom.declareDate = DateUtils.convertLocalDateFromServer(bookingCustom.declareDate);
            })
            angular.forEach(data.guideCtns, function(ctn){
                ctn.returnTime = DateUtils.convertDateTimeFromServer(ctn.returnTime);
            })
            if(data.id){
                data.encodeId = WinAES.encode(data.id.toString());
            }
        }

        function initGuideRequest (data) {
            data = angular.copy(data);
            DateUtils.convertObjDateToServer(data, dateFds);
            if (data.booking){
                data.booking.issueDate = DateUtils.convertLocalDateToServer(data.booking.issueDate);
                data.booking.etd = DateUtils.convertLocalDateToServer(data.booking.etd);
            }
            angular.forEach(data.bookingCustoms, function(bookingCustom){
                bookingCustom.declareDate = DateUtils.convertLocalDateToServer(bookingCustom.declareDate);
            })
            angular.forEach(data.guideCtns, function(ctn) {
                ctn.gwDec = ctn.gw;
            })
           return angular.toJson(data);
        }

        return $resource('api/guides/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'searchGuide': { 
                method: 'GET', 
                url:'api/guide/searchGuide',
                isArray: true
            },
            'queryVirtual': {
                method: 'GET', 
                url:'api/guides/queryVirtual',
                isArray: true
            },  
            'matchCostsByProduct': {
                method: 'PUT', 
                url:'api/guides/matchCostsByProduct',
                isArray: true,
                transformRequest: function (data) {
                   return initGuideRequest(data);
                }
            },  
            'getGuideRates': { 
                method: 'GET', 
                url:'api/guides/getGuideRates',
                isArray: true
            },     
            'getGuideRatesByJobNum': { 
                method: 'GET', 
                url:'api/guides/getGuideRatesByJobNum',
                isArray: true
            },     
            'copyHbls': { 
                method: 'GET', 
                url:'api/guides/copyHbls'
            }, 
            'autoTrackingCheck': { 
                method: 'PUT', 
                url:'api/guides/autoTrackingCheck'
            }, 
            'synWarehousingData': { 
                method: 'GET', 
                url:'api/guides/synWarehousingData'
            }, 
            'getGuideDTO': { 
                method: 'GET', 
                url:'api/guides/getGuideDTO',
                isArray: false
            },     
            'updateSelectedTtl': { 
                method: 'POST', 
                url:'api/guides/updateSelectedTtl'
            },
            'updateAuditInfo': { 
                method: 'POST', 
                url:'api/guides/updateAuditInfo',
                isArray: true
            },
            'searchByNums': { 
                method: 'PUT', 
                url:'api/guides/searchByNums',
                isArray: true
            },               
            'getGuideForHbls': { 
                method: 'GET', 
                url:'api/guides/getGuideForHbls',
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'getByBookingId': { 
                method: 'GET', 
                url:'api/guides/getByBookingId',
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'checkChannel': {
                url:'api/guides/checkChannel',
                method: 'POST',
                transformRequest: function (data) {
                   return initGuideRequest(data);
                }
            },
            'getFilterPage': {
                url:'api/guides/getFilterPage',
                method: 'POST',
                isArray: true
            },
            'switchJobCompany': { 
                method: 'GET', 
                url:'api/guides/switchJobCompany'
            },            
            'getGuideAndRates': {
                method:'GET',
                url:'api/guides/getGuideAndRates',
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },     
            'checkChannelValid': {
                url:'api/guides/checkChannelValid',
                method: 'POST',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                }
            },
            'getChargeWeight': {
                url:'api/guides/getChargeWeight',
                method: 'POST'
            },
            'getGuideAndRatesByJobNum': {
                method:'GET',
                url:'api/guides/getGuideAndRatesByJobNum',
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },        
            'saveGuideAndRates': {
                method:'POST',
                url:'api/guides/saveGuideAndRates',          
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'testHuoSiFuWxPush': {
                method:'POST',
                url:'api/guides/testHuoSiFuWxPush',          
                transformRequest: function (data) {
                    return initGuideRequest(data);
                }
            },
            'printLabel':{
                method: 'POST',
                url:'api/guides/printLabel',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'updateStatusBatch':{
                method: 'PUT',
                url:'api/guides/updateStatusBatch'
            },            
            'fbaRegistIn':{
                method: 'PUT',
                url:'api/guides/fbaRegistIn'
            },
            'fmsRegistIn':{
                method: 'PUT',
                url:'api/warehousing-data/fmsRegistIn',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'getMGuideCtns': { 
                method: 'GET', 
                url:'api/guides/getMGuideCtns',
                isArray: true
            },
            'getRecentShipments': { 
                method: 'GET', 
                url:'api/guides/getRecentShipments',
                isArray: true
            },            
            'amendShipmentType': { 
                method: 'GET', 
                url:'api/guides/amendShipmentType'
            },
            'getWorkload': { 
                method: 'GET', 
                url:'api/guides/workLoad'
            },   
            'getFmsWorkload': { 
                method: 'POST', 
                url:'api/guides/getFmsWorkload'
            },   
            'changeExImport': { 
                method: 'GET', 
                url:'api/guides/changeExImport'
            },       
            'getGuideByJobNum': { 
                method: 'GET', 
                url:'api/guides/getGuideByJobNum'
            },    
            'setVoid': {
                method: 'GET', 
                url:'api/guides/setVoid'
            },
            'getTruckCost': {
                method: 'GET', 
                url:'api/guides/getTruckCost'
            },            
            'combineMark': { 
                method: 'GET', 
                url:'api/guides/combineMark'
            }, 
            'combineDescription': { 
                method: 'GET', 
                url:'api/guides/combineDescription'
            },                
            'getBillsWorkload': { 
                method: 'GET', 
                url:'api/guides/getBillsWorkload'
            },
            'deliverySign': { 
                method: 'PUT', 
                url:'api/guides/deliverySign'
            },
            'getDepWorkload': { 
                method: 'GET', 
                url:'api/guides/getDepWorkload',
                isArray: true
            },
            'getAllTableGuides': { 
                method: 'GET', 
                url:'api/guides/table',
                isArray: true
            },          
            'updateFin': { 
                method: 'GET', 
                url:'api/guides/updateFin'
            },
            'upateSoNum': {
                method: 'GET',
                url:'api/guides/upateSoNum'
            },
            'upateMemo': {
                method: 'GET',
                url:'api/guides/upateMemo'
            },
            'unbind': {
                method: 'GET', 
                url:'api/guides/unbind'
            },
            'getShareRates': { 
                method: 'GET', 
                url:'api/guides/getShareRates',
                isArray: true
            },
            'getCombineRates': { 
                method: 'GET', 
                url:'api/guides/getCombineRates',
                isArray: true
            },            
            'delAllShareCost': { 
                method: 'GET', 
                url:'api/guides/delAllShareCost'
            },
            'reShareCost': { 
                method: 'PUT', 
                url:'api/guides/reShareCost',
                isArray: true
            },
            'searchAGuide': { 
                method: 'GET', 
                url:'api/guide/searchAgent',
                isArray: true
            },
            'amendMblNum': { 
                method: 'GET', 
                url:'api/guide/amendMblNum'
            },                        
            'agentImport': { 
                method: 'GET', 
                url:'api/agentImport/:id',
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },            
            'getImports': {
                method:'GET',
                url:'api/imports',
                isArray:true
            },
            'getAImports': {
                method:'GET',
                url:'api/agentGuides',
                isArray:true
            },
            'getBills': {
                method:'GET',
                url:'api/bills',
                isArray:true
            },
            'salesLock': {
                method:'GET',
                url:'api/guides/lock'
            },
            'amendMblNumByOp': {
                method:'GET',
                url:'api/guide/amendMblNumByOp'         
            },          
            'validateXML': {
                method:'GET',
                url:'api/validateXML'         
            },     
            'setStatusOne': {
                method:'GET',
                url:'api/guide/setStatusOne',
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },    
            'setStatusAll': {
                method:'POST',
                url:'api/guide/setStatusAll',
                isArray:true     
            },   
            'applyRelease': {
                method:'GET',
                url:'api/guides/release'
            },
            'assistChargesConfirmed': {
                method:'GET',
                url:'api/guides/assistChargesConfirmed'
            },
            'setAutoTracking': {
                method:'GET',
                url:'api/guides/setAutoTracking'
            },            
            // 'doAutoTracking': {
            //     method:'GET',
            //     url:'api/guides/doAutoTracking'
            // },
            'advanceSearchGuides': {
                method:'PUT',
                url:'api/advanceSearchGuides',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.etdFM = DateUtils.convertLocalDateToServer(data.etdFM);
                    data.etdTO = DateUtils.convertLocalDateToServer(data.etdTO);
                    data.lfdFM = DateUtils.convertLocalDateToServer(data.lfdFM);
                    data.lfdTO = DateUtils.convertLocalDateToServer(data.lfdTO);                    
                    data.revDeadLineFM = DateUtils.convertLocalDateToServer(data.revDeadLineFM);
                    data.revDeadLineTO = DateUtils.convertLocalDateToServer(data.revDeadLineTO);
                    data.transferDateFM = DateUtils.convertLocalDateToServer(data.transferDateFM);
                    data.transferDateTO = DateUtils.convertLocalDateToServer(data.transferDateTO);
                    data.opDateFM = DateUtils.convertLocalDateToServer(data.opDateFM);
                    data.opDateTO = DateUtils.convertLocalDateToServer(data.opDateTO);
                    data.etaFM = DateUtils.convertLocalDateToServer(data.etaFM);
                    data.etaTO = DateUtils.convertLocalDateToServer(data.etaTO);
                    data.deliveryDateFM = DateUtils.convertLocalDateToServer(data.deliveryDateFM);
                    data.deliveryDateTO = DateUtils.convertLocalDateToServer(data.deliveryDateTO);
                    data.bookingEtdFM = DateUtils.convertLocalDateToServer(data.bookingEtdFM);
                    data.bookingEtdTO = DateUtils.convertLocalDateToServer(data.bookingEtdTO);
                    data.atdFM = DateUtils.convertLocalDateToServer(data.atdFM);
                    data.atdTO = DateUtils.convertLocalDateToServer(data.atdTO);
                    data.billDateFM = DateUtils.convertLocalDateToServer(data.billDateFM);
                    data.billDateTO = DateUtils.convertLocalDateToServer(data.billDateTO);    
                    data.cargoReadyDateFM = DateUtils.convertLocalDateToServer(data.cargoReadyDateFM);
                    data.cargoReadyDateTO = DateUtils.convertLocalDateToServer(data.cargoReadyDateTO);    
                    return angular.toJson(data);
                },
                transformResponse: function (resultList) {
                    if (!resultList){return;}   
                    resultList = angular.fromJson(resultList);
                    angular.forEach(resultList, function(data){
                        if(!angular.isDefined(data.isSub)){
                            convertDateAndTimeFromServer(data);
                        }                        
                    })
                    return resultList;
                }
            },
            'releaseGuides': {
                method:'POST',
                url:'api/releaseGuides',
                isArray:true
            },
            'lockRatesAll': {
                method:'POST',
                url:'api/guides/lockRatesAll',
                isArray:true
            },
            'lockGuidesAll': {
                method:'POST',
                url:'api/guides/lockGuidesAll',
                isArray:true
            },
            'setFmsInBoundRequest': {
                method:'POST',
                url:'api/apiFms/setFmsInBoundRequest'
            }, 
            'ormesaniSftpUpload': {
                method:'POST',
                url:'api/apiFms/ormesaniSftpUpload'
            }, 
            'shipmentSend': {
                method:'POST',
                url:'api/apiFms/shipmentSend'
            },             
            'shipmentSend2': {
                method:'POST',
                url:'api/apiFms/shipmentSend',
                transformResponse: function (data) {
                    try {
                        JSON.parse(data);
                        return angular.fromJson(data);
                      } catch (error) {
                        var result = new Object();
                        result['data'] = data;
                        return result;
                      }
                }
            },          
            'getRequestBody': {
                method:'POST',
                url:'api/apiFms/getRequestBody'
            },
            'lockSubGuidesAll': {
                method:'GET',
                url:'api/guides/lockSubGuidesAll'
            },
            'lockRateOne': {
                method:'GET',
                url:'api/guides/lockRateOne'            
            },
            'salesChecked': {
                method:'GET',
                url:'api/guides/salesChecked'
            },
            'getTrackingReport': {
                method:'PUT',
                url:'api/guides/getTrackingReport',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.etdFrom = DateUtils.convertLocalDateToServer(data.etdFrom);
                    data.etdEnd = DateUtils.convertLocalDateToServer(data.etdEnd);
                    return angular.toJson(data);
                },
                isArray:true
            },
            'getAuditInfo': {
                method:'GET',
                url:'api/guides/getAuditInfo'
            },
            'checkSo': {
                method:'GET',
                url:'api/checkSoRepeat'
            },
            'checkMbl': {
                method:'GET',
                url:'api/checkMblRepeat'
            },
            'checkRefNum': {
                method:'GET',
                url:'api/checkRefNumRepeat'
            },
            'checkJobNum': {
                method:'GET',
                url:'api/checkJobNumRepeat'
            },
            'checkHbl': {
                method:'GET',
                url:'api/checkHblNumRepeat'
            },
            'checkPoNum': {
                method:'GET',
                url:'api/guides/checkPoNumRepeat'
            },
            'setRelease': {
                method:'PUT',
                url:'api/guidedto/setRelease'
            },
            'setAudit': {
                method:'PUT',
                url:'api/guidedto/setAudit'
            },
            'createImport': {
                method:'POST',
                url:'api/imports',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'sendSi': {
                method:'PUT',
                url:'api/guides/sendSi',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                }
            },
            'authorizeBranch': {
                method:'PUT',
                url:'api/authorizeBranch',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'synBranchGuide': {
                method:'PUT',
                url:'api/synBranchGuide',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'amendEtd': {
                method:'POST',
                url:'api/guide/amendEtd',
                transformRequest: function (data) {
                   return initGuideRequest(data);
                }
            },
            'getGuideByJobNumAndType': {
                method: 'GET',
                url:'api/guides/getGuideByJobNumAndType',
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'getByJobNum': {
                method: 'GET',
                url:'api/guides/getByJobNum',
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'getRapidInGuide': {
                method: 'GET',
                url:'api/guides/getRapidInGuide',
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'getFmsRapidInGuide': {
                method: 'GET',
                url:'api/guides/getFmsRapidInGuide',
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'get': {
                method: 'GET',                
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'getCopyGuide': {
                method: 'GET',
                url:'api/guides/getCopyGuide',
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },       
            'getGuideBooking': {
                method: 'GET',
                url:'api/guides/getGuideBooking',
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },      
            'shaPortPost': {
                method: 'POST',
                url:'api/guides/shaPortPost',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    if (!data){
                        return;
                    }
                    data = angular.fromJson(data);

                    data.guide.cls = DateUtils.convertDateTimeFromServer(data.guide.cls);
                    data.guide.eta = DateUtils.convertLocalDateFromServer(data.guide.eta);
                    data.guide.etd = DateUtils.convertLocalDateFromServer(data.guide.etd);
                    data.guide.siCutTime = DateUtils.convertDateTimeFromServer(data.guide.siCutTime);
                    data.guide.vgm = DateUtils.convertDateTimeFromServer(data.guide.vgm);
                    data.guide.feederEtd = DateUtils.convertLocalDateFromServer(data.guide.feederEtd);
                    data.guide.cargoReadyDate = DateUtils.convertLocalDateFromServer(data.guide.cargoReadyDate);
                    data.guide.lastModifiedTime = DateUtils.convertDateTimeFromServer(data.guide.lastModifiedTime);
                    if (data.booking){
                        angular.forEach(data.booking.bookingTrucks, function(data){
                            data.loadTime = DateUtils.convertDateTimeFromServer(data.loadTime);
                        })
                        data.booking.issueDate = DateUtils.convertLocalDateFromServer(data.booking.issueDate);
                    }
                    angular.forEach(data.bookingCustoms, function(data){
                        data.declareDate = DateUtils.convertLocalDateFromServer(data.declareDate);
                    })
                    angular.forEach(data.guideCtns, function(data){
                        data.returnTime = DateUtils.convertDateTimeFromServer(data.returnTime);
                    })
                    data.shaPort = angular.fromJson(data.shaPort);
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'generateDutyCharges': {
                method: 'PUT',
                url:'api/guides/generateDutyCharges',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'synEtdToHbls': {
                method: 'POST',
                url:'api/guides/synEtdToHbls',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                }
            },            
            'synCtns': {
                url:'api/guides/synCtns',                
                method: 'PUT',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    angular.forEach(data, function(ctn){
                        ctn.returnTime = DateUtils.convertDateTimeFromServer(ctn.returnTime);
                    })
                    return data;
                }
            },
            'advanceShareCost': {
                url:'api/guides/advanceShareCost',
                method: 'PUT'
            },
            'delivery': {
                method: 'PUT',
                url:'api/deliveryGuide',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'refreshGwVolPkgs': {
                method: 'POST',
                url:'api/guides/refreshGwVolPkgs',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'getGuideLabel': {
                method: 'POST',
                url:'api/guides/getGuideLabel',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },       
            'voidLabel':{
                method: 'POST',
                url:'api/guides/voidLabel'
            },     
            'preBookingConfirm': {
                method: 'PUT',
                url:'api/guides/preBookingConfirm',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.cargoReadyDate = DateUtils.convertLocalDateToServer(data.cargoReadyDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'splitGuide': {
                method: 'PUT',
                url:'api/guides/splitGuide',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    if (!data){
                        return;
                    }
                    data = angular.fromJson(data);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    data.lastModifiedTime = DateUtils.convertDateTimeFromServer(data.lastModifiedTime);
                    data.etd = DateUtils.convertLocalDateFromServer(data.etd);
                    data.siCutTime = DateUtils.convertDateTimeFromServer(data.siCutTime);
                    data.vgm = DateUtils.convertDateTimeFromServer(data.vgm);
                    data.feederEtd = DateUtils.convertLocalDateFromServer(data.feederEtd);
                    data.cargoReadyDate = DateUtils.convertLocalDateFromServer(data.cargoReadyDate);
                    if (data.bookingTrucks){
                        for(var i=0; i<data.bookingTrucks.length; i++){
                            data.bookingTrucks[i].loadTime = DateUtils.convertDateTimeFromServer(data.bookingTrucks[i].loadTime);
                        }  
                    }                    
                    return data;
                }
            },
            'getallcarrier': {
                method:'GET',
                url:'api/guides/getallcarrier',
                isArray:true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);      
                    return data;
                }
            },
            'getGuideReview': {
                method:'GET',
                url:'api/guides/review',
                isArray:false
            },
            'penavicoTianjinEdi': {
                method: 'POST',
                url:'api/penavicoTianjinEdi',
                isArray:false,
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'IFCSUM': {
                method: 'POST',
                url:'api/edifact/IFCSUM',
                isArray:false,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    if(data || data.length > 0){
                        angular.forEach(data, function(guide){
                            guide.cargoReadyDate = DateUtils.convertLocalDateToServer(guide.cargoReadyDate);
                            guide.eta = DateUtils.convertLocalDateToServer(guide.eta);
                            guide.etd = DateUtils.convertLocalDateToServer(guide.etd);
                            guide.atd = DateUtils.convertLocalDateToServer(guide.atd);
                            guide.billDate = DateUtils.convertLocalDateToServer(guide.billDate);
                            guide.opDate = DateUtils.convertLocalDateToServer(guide.opDate);
                            guide.feederEtd = DateUtils.convertLocalDateToServer(guide.feederEtd);
                            guide.deliveryDate = DateUtils.convertLocalDateToServer(guide.deliveryDate);
                        })
                    }
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'penavicoTianjinEdiNew': {
                method: 'POST',
                url:'api/penavicoTianjinEdiNew',
                isArray:false,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.guide.eta = DateUtils.convertLocalDateToServer(data.guide.eta);
                    data.guide.feederEtd = DateUtils.convertLocalDateToServer(data.guide.feederEtd);
                    if (data.guide.booking){
                        data.guide.cargoReadyDate = DateUtils.convertLocalDateToServer(data.guide.cargoReadyDate);
                        data.guide.etd = DateUtils.convertLocalDateToServer(data.guide.etd);
                        data.guide.deliveryDate = DateUtils.convertLocalDateToServer(data.guide.deliveryDate);
                        data.guide.booking.issueDate = DateUtils.convertLocalDateToServer(data.guide.booking.issueDate);
                    }
                    angular.forEach(data.guide.bookingCustoms, function(data){
                        data.declareDate = DateUtils.convertLocalDateFromServer(data.declareDate);
                    })
                   return angular.toJson(data);
                },transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'getallcontract': {
                method:'GET',
                url:'api/guides/getallcontract',
                isArray:true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);      
                    return data;
                }
            },
            'getAllLanes': {
                method:'GET',
                url:'api/guides/getAllLanes',
                isArray:true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);      
                    return data;
                }
            },
            'removeFromConsol': {
                method:'GET',
                url:'api/guides/removeFromConsol'
            },
            'onGoingShipments': {
                method:'GET',
                url:'api/guides/onGoingShipments',
                isArray:true
            },
            'refreshCharges':{
                method:'PUT',
                url:'api/guides/refreshCharges',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },     
            'viewSubShipments': {
                method:'PUT',
                url:'api/guides/viewSubShipments',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.etd = DateUtils.convertLocalDateToServer(data.etd);
                    return angular.toJson(data);
                }
            },
            'getSonShipments': {
                method:'GET',
                url:'api/guides/getSonShipments',
                isArray:true
            },
            'pilEdi': {
                method: 'POST',
                url:'api/pilBrEdi',
                transformRequest: function (data) {
                    data.guide = angular.fromJson(initGuideRequest(data.guide));
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (!data){return;}
                    data = angular.fromJson(data);      
                    return data;
                }
            },
            'deleteCtnMovement':{
                method:'DELETE',
                url:'api/deleteCtnMovement/:id'
            },
            'delAll':{
                method:'POST',
                url:'api/guides/delAll'
            },
            'getGuideBookingDTO': {
                method: 'GET',
                url:'api/guides/getGuideBookingDTO',
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'getHblsCount': {
                method: 'GET',
                url:'edi/web-services/fms/getHblsCount'
            },
            'getSiGuide': {
                method: 'GET',
                url:'edi/web-services/fms/getSiGuide',
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'getSiGuideForHbls': { 
                method: 'GET', 
                url:'edi/web-services/fms/getSiGuideForHbls',
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'getAllReportTemplate': {
                method: 'GET',
                url:'edi/web-services/fms/getAllReportTemplate',
                isArray:true
            },            
            'delHbl': {
                method:'DELETE',
                url:'edi/web-services/fms/delHbl'
            },
            'saveBooking': {
                method: 'POST',
                url:'edi/web-services/fms/saveBooking',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.etd = DateUtils.convertLocalDateToServer(data.etd);
                    data.issueDate = DateUtils.convertLocalDateToServer(data.issueDate);
                    data.cargoReadyDate = DateUtils.convertLocalDateToServer(data.cargoReadyDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    data.lastModifiedTime = DateUtils.convertDateTimeFromServer(data.lastModifiedTime);
                    data.etd = DateUtils.convertLocalDateFromServer(data.etd);
                    data.issueDate = DateUtils.convertLocalDateFromServer(data.issueDate);
                    data.cargoReadyDate = DateUtils.convertLocalDateFromServer(data.cargoReadyDate);
                    if (data.bookingTrucks){
                        for(var i=0; i<data.bookingTrucks.length; i++){
                            data.bookingTrucks[i].loadTime = DateUtils.convertDateTimeFromServer(data.bookingTrucks[i].loadTime);
                        }
                    }
                    return data;
                }
            },            
            'getOpsShippers': {
                method: 'GET',
                url:'edi/web-services/fms/getOpsShippers',
                isArray:true
            },
            'getHbls': {
                method: 'GET',
                url:'edi/web-services/fms/getHbls',
                isArray:true
            },            
            'getOpsPorts': {
                method: 'GET',
                url:'edi/web-services/fms/getOpsPorts',
                isArray:true
            },
            'sendSiOnline': {
                method:'PUT',
                url:'edi/web-services/fms/sendSiOnline',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                }
            },
            'guideReport':{
                method:'POST',
                url:'edi/web-services/fms/guideReport',
                responseType: 'arraybuffer',
                isArray:false,
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    var result = new Object();                    
                    result['data'] = data;
                    return result;
                }
            },
            'getRevDeadLine': {
                method: 'PUT',
                url:'api/guides/getRevDeadLine',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'updateGuideBooking': {
                method: 'PUT',
                url:'api/guides/updateGuideBooking',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'createParcel': {
                method: 'PUT',
                url:'api/guides/createParcel',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                }
            },
            'getDeliveryDateTest': {
                method: 'POST',
                url:'api/guides/getDeliveryDateTest',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                }
            },
            'setIsClosed': {
                method: 'GET',
                url:'api/guides/setIsClosed'
            },
            'setAsMaster': {
                method: 'GET',
                url:'api/guides/setAsMaster'
            },
            'getOnlineSiUrl': {
                method: 'GET',
                url:'api/guides/getOnlineSiUrl'
            },
            'refreshWarehousingDataByWmsIn': {
                method: 'GET',
                url:'api/guides/refreshWarehousingDataByWmsIn'
            },
            'pushFbaShipment':{
                url:'api/apiFms/pushFbaShipment',
                method:'POST'
            },
            'queryFbaOrder':{
                url:'api/apiFms/queryFbaOrder',
                method:'POST'
            },
            'getFbaRequestBody':{
                url:'api/apiFms/getFbaRequestBody',
                method:'POST'
            },            
            'cancelFbaOrder':{
                url:'api/apiFms/cancelFbaOrder',
                method:'POST'
            },
            'setAllCleared':{
                method:'PUT',
                url:'api/guides/setAllCleared'
            },
            'weiPaiCtnReport': {
                method: 'POST',
                url:'api/excel/weiPaiCtnReport',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportBookingCtns': {
                method: 'POST',
                url:'api/excel/exportBookingCtns',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'freightOsTest': {
                method:'POST',
                url:'api/apiFms/freightOsTest'
            },
            'rxo304Edi': {
                method:'POST',
                url:'api/apiFms/rxo304Edi'
            },
            'rxo315Edi': {
                method:'POST',
                url:'api/apiFms/rxo315Edi'
            },
            'rxo990Edi': {
                method:'POST',
                url:'api/apiFms/rxo990Edi'
            },
            'OTELSZExportItems': {
                method: 'GET',
                url:'api/excel/OTELSZExportItems',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'OTELSZExportVendorInvoiceRegistry': {
                method: 'GET',
                url:'api/excel/OTELSZExportVendorInvoiceRegistry',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'getCustomExcelImportRecord': {
                method:'GET',
                url:'api/grid-states/getCustomExcelImportRecord',
                isArray: true
            },
            'customizeImportOrder': {
                method:'POST',
                url:'api/guide-import/customizeImportOrder'
            },
            'getDeliveryChannelByGuide': {
                method:'PUT',
                url:'api/guides/getDeliveryChannelByGuide',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                }
            },
            'createBranchGuide': {
                method:'POST',
                url:'api/synOrder/createBranchGuide',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'synBranchGuideData': {
                method:'POST',
                url:'api/synOrder/synBranchGuideData',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'getMoreLinkChannels': {
                method:'POST',
                url:'api/apiFms/getMoreLinkChannels',
                isArray:true
            },
            'getMoreLinkWarehouses': {
                method:'POST',
                url:'api/apiFms/getMoreLinkWarehouses',
                isArray:true
            },
            'DLXExportUnpackingList': {
                method: 'GET',
                url:'api/excel/DLXExportUnpackingList',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'DLXExportInvoicePackingList': {
                method: 'GET',
                url:'api/excel/DLXExportInvoicePackingList',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'updateTrackingNum': {
                method:'POST',
                url:'api/guides/updateTrackingNum'
            },
            'synBranchGuideBatch': {
                method:'PUT',
                url:'api/synOrder/synBranchGuideBatch'
            },
            'autoSynDo': {
                method:'PUT',
                url:'api/synOrder/autoSynDo'
            },
            'TPDExportInvoicePackingList': {
                method: 'GET',
                url:'api/excel/TPDExportInvoicePackingList',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'getNewMoreLinkDomesticProducts': {
                method:'POST',
                url:'api/apiFms/getNewMoreLinkDomesticProducts',
                isArray:true
            },
            'deliveryGuideBatch': {
                method:'POST',
                url:'api/deliveryGuideBatch'
            },
            'guideAutoLockCloseTest': {
                method:'GET',
                url:'api/apiErp/guideAutoLockCloseTest'
            }, 
            'getNewMoreLinkWarehouses': {
                method:'POST',
                url:'api/apiFms/getNewMoreLinkWarehouses',
                isArray:true
            },
            'getNewMoreLinkShippingCompany': {
                method:'POST',
                url:'api/apiFms/getNewMoreLinkShippingCompany',
                isArray:true
            },
            'autoPrintLabel':{
                method: 'GET',
                url:'api/guides/autoPrintLabel',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'printLabels':{
                method: 'POST',
                url:'api/guides/printLabels',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'DLXExportISFAndAMSInfo': {
                method: 'GET',
                url:'api/excel/DLXExportISFAndAMSInfo',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'getWarehouses': {
                method:'POST',
                url:'api/apiFms/getWarehouses',
                isArray:true
            },
            'getChannels': {
                method:'POST',
                url:'api/apiFms/getChannels',
                isArray:true
            },
            'getTplPorts': {
                method:'POST',
                url:'api/apiFms/getTplPorts',
                isArray:true
            },
            'updateCargoValue': {
                method:'GET',
                url:'api/guides/updateCargoValue'
            },
            'queryAppointAbility': {
                method:'POST',
                url:'api/lazada/queryAppointAbility',
                transformRequest: function (data) {
                   return initGuideRequest(data);
                }
            },
            'makeAppoint': {
                method:'POST',
                url:'api/lazada/makeAppoint'
            },
            'changeAppointDate': {
                method:'POST',
                url:'api/lazada/changeAppointDate'
            },
            'orderStatusUpdate': {
                method:'POST',
                url:'api/lazada/orderStatusUpdate'
            },
            'appointDetailPrint': {
                method:'POST',
                url:'api/lazada/appointDetailPrint'
            },
            'changeCustomerCheck': {
                method:'PUT',
                url:'api/guides/changeCustomerCheck'
            }
        });
    }
})();
