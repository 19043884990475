(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('OpsCarrier', OpsCarrier);

    OpsCarrier.$inject = ['$resource', 'DateUtils'];

    function OpsCarrier ($resource, DateUtils) {
        var resourceUrl =  'api/ops-carriers/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.effectiveDate = DateUtils.convertLocalDateFromServer(data.effectiveDate);
                        data.expireDate = DateUtils.convertLocalDateFromServer(data.expireDate);
                    }
                    return data;
                }
            },
            'getAll': {
                method:'GET',
                url:'api/ops-carriers/getAll',
                isArray:true
            },    
            'getChannels': {
                method:'GET',
                url:'api/ops-carriers/getChannels',
                isArray:true
            },             
            'getServiceZonesByChannel': {
                method:'GET',
                url:'api/service-zone/getServiceZonesByChannel',
                isArray:true
            },    
            'delAll': {
                method:'POST',
                url:'api/service-zone/delAll'
            },  
            'saveAll': {
                method:'POST',
                url:'api/service-zone/saveAll',
                isArray:true
            },  
            'deleteAllCarrers': {
                method:'DELETE',
                url:'api/ops-carriers/deleteAllCarrers'
            },
            'getOneByName': {
                method:'GET',
                url:'api/ops-carriers/getOneByName'
            },     
            'groupShare': {
                method:'GET',
                url:'api/ops-carriers/groupShare'
            },         
            'update': { method:'PUT',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.effectiveDate = DateUtils.convertLocalDateToServer(data.effectiveDate);
                    data.expireDate = DateUtils.convertLocalDateToServer(data.expireDate);
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.effectiveDate = DateUtils.convertLocalDateToServer(data.effectiveDate);
                    data.expireDate = DateUtils.convertLocalDateToServer(data.expireDate);
                    return angular.toJson(data);
                }
            }
        });
    }
})();
