(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('WmsIn', WmsIn);

    WmsIn.$inject = ['$resource', 'DateUtils'];

    function WmsIn ($resource, DateUtils) {
        function initResponse(data){
            if (!data){return;}   
            data = angular.fromJson(data);
            data.poDate = DateUtils.convertLocalDateFromServer(data.poDate);
            data.etd = DateUtils.convertLocalDateFromServer(data.etd);
            data.ata = DateUtils.convertLocalDateFromServer(data.ata);
            data.lfd = DateUtils.convertLocalDateFromServer(data.lfd);
            data.expectedWarehousingTime = DateUtils.convertDateTimeFromServer(data.expectedWarehousingTime);
            data.inTime = DateUtils.convertDateTimeFromServer(data.inTime);
            data.customClearTime = DateUtils.convertDateTimeFromServer(data.customClearTime);
            data.pickupTime = DateUtils.convertDateTimeFromServer(data.pickupTime);
            angular.forEach(data.cargoList, function(cargo){
                cargo.gateInTime = DateUtils.convertDateTimeFromServer(cargo.gateInTime);
                cargo.outTime = DateUtils.convertDateTimeFromServer(cargo.outTime);
            })
            return data;
        }

        function initRequest (data) {
            data = angular.copy(data);
            data.poDate = DateUtils.convertLocalDateToServer(data.poDate);
            data.etd = DateUtils.convertLocalDateToServer(data.etd);
            data.ata = DateUtils.convertLocalDateToServer(data.ata);
            data.lfd = DateUtils.convertLocalDateToServer(data.lfd);
           return angular.toJson(data);
        }
        var resourceUrl =  'api/wms-ins/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'amendEtd': {
                method:'POST',
                url:'api/wms-ins/amendEtd',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.etd = DateUtils.convertLocalDateToServer(data.etd);
                    return angular.toJson(data);
                }
            },
            'getPltWmsInByTdOrder': {
                method:'POST',
                url:'api/wms-ins/getPltWmsInByTdOrder',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'registPltIn': {
                method:'POST',
                url:'api/wms-ins/registPltIn',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },            
            'loadWmsIn': {
                method: 'GET',
                url:'api/wms-ins/loadWmsIn',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'getWmsInBywarehousingNumber': {
                method: 'GET',
                url:'api/wms-ins/getWmsInBywarehousingNumber',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'getByWarehousingNumber': {
                method: 'GET',
                url:'api/wms-ins/getByWarehousingNumber',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },            
            'getRapidInBywarehousingNumber': {
                method: 'GET',
                url:'api/wms-ins/getRapidInBywarehousingNumber',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'getRapidOutBywarehousingNumber': {
                method: 'GET',
                url:'api/wms-ins/getRapidOutBywarehousingNumber',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'getByTrackingNumber': {
                method: 'GET',
                url:'api/wms-ins/getByTrackingNumber',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'rapidShelve': {
                method: 'GET',
                url:'api/wms-ins/rapidShelve',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'setAudit': {
                method: 'GET',
                url:'api/wms-ins/setAudit',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'sendWmsInApi':{
                url:'api/api-wms/sendWmsInApi',
                method:'POST'
            },
            'dayAutoCharge':{
                url:'api/wms-ins/dayAutoCharge',
                method:'POST'
            },            
            'addSkuToWmsGoods':{
                url:'api/wms-ins/addSkuToWmsGoods',
                method:'POST'
            },            
            'updateWmsInByEdi': {
                method: 'GET',
                url:'api/api-wms/updateWmsInByEdi'
            },
            'tdForceOut': {
                method: 'POST',
                url:'api/wms-ins/tdForceOut'
            },            
            'savePalletize': {
                method: 'PUT',
                url:'api/wms-ins/savePalletize'
            },
            'createImportWmsIn': {
                method: 'PUT',
                url:'api/wms-ins/createImportWmsIn'
            },
            'createEdiWmsIn': {
                method: 'PUT',
                url:'api/wms-ins/createEdiWmsIn'
            },
            'getFilterPage': {
                method: 'POST',
                url:'api/wms-ins/getFilterPage',
                isArray: true
            },       
            'setClosedAll': {
                method: 'POST',
                url:'api/wms-ins/setClosedAll',
                isArray: true
            },                   
            'createWmsInByGuide': {
                method:'PUT',
                url:'api/wms-ins/createWmsInByGuide'
            },
            'addAutoWmsCharges': {
                method:'POST',
                url:'api/wms-ins/addAutoWmsCharges',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'createWmsOutByGuide': {
                method:'PUT',
                url:'api/wms-ins/createWmsOutByGuide'
            },
            'generateGuide': {
                method: 'PUT',
                url:'api/wms-ins/generateGuide',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'rapidIn': {
                method:'POST',
                url:'api/wms-ins/rapidIn'
            },
            'updateList': {
                method:'PUT',
                url:'api/wms-ins/updateList'
            },
            'getWmsCargoIds': {
                method:'GET',
                url:'api/wms-ins/getWmsCargoIds',
                isArray:true
            },  
            'loadWmsInInventoryCargo': {
                method:'GET',
                url:'api/wms-ins/loadWmsInInventoryCargo',
                isArray:true
            }, 
            'saveAndCharge': {
                method:'POST',
                url:'api/wms-ins/saveAndCharge',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.workTime = DateUtils.convertDateTimeFromServer(data.workTime);
                        data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    }
                    return data;
                }
            },
            'antiIn': {
                method:'GET',
                url:'api/wms-ins/antiIn',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'antiOut': {
                method:'GET',
                url:'api/wms-ins/antiOut',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'antiOutOne': {
                method:'GET',
                url:'api/wms-ins/antiOutOne',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'filter': {
                method:'PUT',
                url:'api/wms-ins/filter',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.etdFM = DateUtils.convertLocalDateToServer(data.etdFM);
                    data.etdTO = DateUtils.convertLocalDateToServer(data.etdTO);
                    if(data.statParams){
                        data.statParams.etdFM = DateUtils.convertLocalDateToServer(data.statParams.etdFM);
                        data.statParams.etdTO = DateUtils.convertLocalDateToServer(data.statParams.etdTO);
                        data.statParams.accountDateFM = DateUtils.convertLocalDateToServer(data.statParams.accountDateFM);
                        data.statParams.accountDateTO = DateUtils.convertLocalDateToServer(data.statParams.accountDateTO);
                    }
                    return angular.toJson(data);
                }
            },
            'getGuideRates': {
                method:'GET',
                url:'api/wms-ins/getGuideRates',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'getGuideRatesByWarehousingNumber': {
                method:'GET',
                url:'api/wms-ins/getGuideRatesByWarehousingNumber',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'saveGuideRates': {
                method:'POST',
                url:'api/wms-ins/saveGuideRates',          
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.etd = DateUtils.convertLocalDateToServer(data.etd);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'generateCustoms': {
                method: 'PUT',
                url:'api/wms-ins/generateCustoms',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getListById': { 
                method: 'GET', 
                url:'api/wms-ins/getListById',
                isArray: true
            },
            'bindGuide': { 
                method: 'GET', 
                url:'api/wms-ins/bindGuide'
            },
            'unbindGuide': { 
                method: 'GET', 
                url:'api/wms-ins/unbindGuide'
            },
            'getWmsVirtual': { 
                method: 'GET', 
                url:'api/wms-ins/virtual',
                isArray: true
            }, 
            'getAllForOut': { 
                method: 'GET', 
                url:'api/wms-ins-for-out',            
                isArray: true
            },
            'getAllPodNames': {
                method:'GET',
                url:'api/wms-ins/getAllPodNames',
                isArray: true
            }, 
            'send': {
                method: 'PUT',
                url:'api/wms-ins/send',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'saveRegistIn': {
                method: 'PUT',
                url:'api/wms-ins/saveRegistIn',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        angular.forEach(data.cargoList, function(cargo){
                            cargo.gateInTime = DateUtils.convertDateTimeFromServer(cargo.gateInTime);
                            cargo.outTime = DateUtils.convertDateTimeFromServer(cargo.outTime);
                        })
                    }
                    return data;
                }
            },
            'saveRegistOut': {
                method: 'PUT',
                url:'api/wms-ins/saveRegistOut',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        angular.forEach(data.cargoList, function(cargo){
                            cargo.gateInTime = DateUtils.convertDateTimeFromServer(cargo.gateInTime);
                            cargo.outTime = DateUtils.convertDateTimeFromServer(cargo.outTime);
                        })
                    }
                    return data;
                }
            },
            'saveRegistOutAll': {
                method: 'PUT',
                url:'api/wms-ins/saveRegistOutAll',
                isArray:true,
                transformResponse: function (dataList) {
                    if (dataList) {
                        dataList = angular.fromJson(dataList);
                        angular.forEach(dataList, function (data) {
                            angular.forEach(data.cargoList, function(cargo){
                                cargo.gateInTime = DateUtils.convertDateTimeFromServer(cargo.gateInTime);
                                cargo.outTime = DateUtils.convertDateTimeFromServer(cargo.outTime);
                            })
                        })
                    }
                    return dataList;
                }
            },
            'getWmsInByGuide': {
                method: 'POST',
                url:'api/wms-ins/getWmsInByGuide'
            },
            'antiTempRegistIn': {
                method: 'PUT',
                url:'api/wms-ins/antiTempRegistIn'
            },
            'getWmsInByJobNum': {
                method: 'GET',
                url:'api/wms-ins/getWmsInByJobNum'
            },
            'saveTempRegistOut': {
                method: 'PUT',
                url:'api/wms-ins/saveTempRegistOut'
            },
            'saveTempRegistIn': {
                method: 'PUT',
                url:'api/wms-ins/saveTempRegistIn'
            },            
            'getWmsCharges': {
                method:'PUT',
                url:'api/wms-ins/getWmsCharges',
                isArray:false,
                transformRequest: function (data) {
                    if(!data){return;}
                    data = angular.copy(data);
                    data.etdEnd = DateUtils.convertLocalDateToServer(data.etdEnd);                    
                    return angular.toJson(data);
                }
            },
            'saveWmsCharges': {
                method:'PUT',
                url:'api/wms-ins/saveWmsCharges',
                isArray:false,
                transformRequest: function (data) {
                    if(!data){return;}
                    data = angular.copy(data);
                    data.etdEnd = DateUtils.convertLocalDateToServer(data.etdEnd);                    
                    return angular.toJson(data);
                }
            },
            'update': { 
                method:'PUT',                
                transformRequest: function (data) {
                    return initRequest (data);
                },
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'save': { 
                method:'POST',
                transformRequest: function (data) {
                    return initRequest (data);
                },
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'createTms': { 
                method:'POST',
                url:'api/wms-ins/createTms'
            },
            'setIsClosed': {
                method: 'GET',
                url:'api/wms-ins/setIsClosed',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'printReturnWorkSheet':{
                method: 'POST',
                url:'api/wms-ins/printReturnWorkSheet',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'wmsLabels':{
                method: 'POST',
                url:'api/wms-ins/wmsLabels',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'batchWmsLabels':{
                method: 'POST',
                url:'api/wms-ins/batchWmsLabels',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'zipWmsLabels':{
                method: 'POST',
                url:'api/wms-ins/zipWmsLabels',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'wmsRapidLabels':{
                method: 'POST',
                url:'api/wms-ins/wmsRapidLabels',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'wmsPltLabels':{
                method: 'POST',
                url:'api/wms-ins/wmsPltLabels',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            }, 
            'skuBarcode':{
                url:'api/wms-ins/skuBarcode',
                method:'POST',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'rmaLabels':{
                method: 'POST',
                url:'api/wms-ins/rmaLabels',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'prindBol':{
                method:'POST',
                url:'api/wms-ins/prindBol',
                responseType: 'arraybuffer',
                isArray:false,
                transformResponse: function (data) {
                    var result = new Object();                    
                    result['data'] = data;
                    return result;
                }
            },
            'sendSizeToWarehouse': {
                method:'GET',
                url:'api/wms-ins/sendSizeToWarehouse'
            },
            'createWmsCargo': { 
                method:'POST',
                url:'api/wms-ins/createWmsCargo'
            },
            'cancelOrder': {
                method: 'POST',
                url: 'api/api-wms/cancelOrder'
            },
            'checkChannelValid': {
                method: 'POST',
                url: 'api/wms-ins/checkChannelValid',
                transformRequest: function (data) {
                    return initRequest (data);
                },
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'createImportFmsOrder': {
                method: 'POST',
                url: 'api/wms-ins/createImportFmsOrder',
                transformRequest: function (data) {
                    return initRequest (data);
                }
            },
            'getWmsInApiRequestBody':{
                method: 'POST',
                url:'api/api-wms/getWmsInApiRequestBody'
            },
            'setStatus':{
                method: 'GET',
                url:'api/wms-ins/setStatus'
            }
             
        });
    }
})();
