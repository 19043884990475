(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsInDetailController', WmsInDetailController);

    WmsInDetailController.$inject = ['$scope', '$state', '$rootScope', '$http', '$translate', '$location', '$timeout', '$uibModalInstance', '$stateParams', '$uibModal',
        'OpsPorts', 'entity', 'WinAES', 'WmsIn', 'WmsCargo', 'OpsCarrier', 'Principal', 'OpsFiles', 'WmsWarehouse', 'Palletize', 
        'ManageEnterprise', 'DataUtils', 'OpsItem', 'WmsWorkLog', 'AccountBind', 'OpsCompany', 'WmsSubPackingList'
    ];

    function WmsInDetailController($scope, $state, $rootScope, $http, $translate, $location, $timeout, $uibModalInstance, $stateParams, $uibModal,
        OpsPorts, entity, WinAES, WmsIn, WmsCargo, OpsCarrier, Principal, OpsFiles, WmsWarehouse, Palletize, 
        ManageEnterprise, DataUtils, OpsItem, WmsWorkLog, AccountBind, OpsCompany, WmsSubPackingList) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };
        var vm = this;
        vm.wmsIn = entity;
        vm.addCargo = addCargo;
        vm.delCargo = delCargo;
        vm.save = save;
        vm.updateTtl = updateTtl;
        vm.updateVol = updateVol;
        vm.updateCargoTtl = updateCargoTtl;
        vm.registPltIn = registPltIn;
        vm.getWmsInApiRequestBody = getWmsInApiRequestBody;

        vm.send = send;
        vm.resend = resend;
        vm.setStatus = setStatus;
        vm.clientSelected = clientSelected;
        vm.warehouseSelected = warehouseSelected;
        vm.amendation = amendation;
        vm.getPubGoods = getPubGoods;
        vm._onPubGoodsSelected = _onPubGoodsSelected;
        vm.isEdit = false;
        vm.warehouses = [];

        vm.portType = true;

        vm.generateGuide = generateGuide;
        vm._onGoodsSelected = _onGoodsSelected;
        vm.clearWmsGoods = clearWmsGoods;

        vm.getWarehouses = getWarehouses;
        vm.importCargo = importCargo;
        vm.setAudited = setAudited;
        vm.getBarcode = getBarcode;
        vm.printReturnWorkSheet = printReturnWorkSheet;
        vm.getAllWmsService = getAllWmsService;
        vm.getByTrackingNumber = getByTrackingNumber;
        vm.pkgTypeSelected = pkgTypeSelected;
        vm.addSkuToWmsGoods = addSkuToWmsGoods;

        vm.copyCargo = copyCargo;
        vm.deleteOne = deleteOne;
        vm.antiIn = antiIn;

        vm.searchParam = null;
        vm.loadWmsIn = loadWmsIn;
        vm.changeValue = changeValue;

        vm.printIn = printIn;
        vm.printPl = printPl;
        vm.generateCustoms = generateCustoms;
        vm.updateShipmentPkgAmt = updateShipmentPkgAmt;
        vm.wmsWorkLogs = [];

        vm.files = [];

        vm.getAccountBinds = getAccountBinds;
        vm.sendWmsInApi = sendWmsInApi;
        vm.deliveryBySelfChange = deliveryBySelfChange;

        vm.accountBinds = [];
        vm._onLocationSelected = _onLocationSelected;
        vm.getLocations = getLocations;
        vm.autoFillLocation = autoFillLocation;
        vm.updateWmsInByEdi = updateWmsInByEdi;
        vm.updateTtlPlts = updateTtlPlts;
        vm.gwTtlChange = gwTtlChange;
        vm.volTtlChange = volTtlChange;
        vm.cancelOrder = cancelOrder;
        vm.loadPalletizes = loadPalletizes;
        vm.imperialChange = imperialChange;
        vm.imperialUnitChange = imperialUnitChange;
        vm.imperialVolTtlChange = imperialVolTtlChange;
        vm.imperialUnit = false;
        
        function updateWmsInByEdi() {
            WmsIn.updateWmsInByEdi({ id: vm.wmsIn.id }, function(result) {
                WmsIn.get({ id: vm.wmsIn.id }, onSaveSuccess);
            }, $rootScope.ALERT_ERROR)
        }

        function autoFillLocation(cargo) {
            if (!cargo.location) {
                return;
            }
            angular.forEach(vm.wmsIn.cargoList, function(data) {
                if (!data.location) {
                    data.location = cargo.location;
                    data.locationId = cargo.locationId;
                    data.locationType = cargo.locationType;
                    data.areaId = cargo.areaId;
                    data.warehouseId = cargo.warehouseId;
                }
            })
        }

        function _onLocationSelected(item, cargo) {
            cargo.location = item.code;
            cargo.locationId = item.locationId;
            cargo.locationType = item.locationType;
            cargo.areaId = item.areaId;
            cargo.warehouseId = item.warehouseId;
        }
        vm.locations = [];

        function getLocations() {
            if (!vm.locations || vm.locations.length == 0) {
                WmsWarehouse.getLocations({ warehouseId: vm.wmsIn.warehouseId }, function(data) {
                    vm.locations = data;
                    $translate.refresh();
                });
            }
        }

        function getAccountBinds() {
            if (!$rootScope.account.companyId || vm.accountBinds.length > 0) {
                return;
            }
            AccountBind.getEdiAccounts({
                companyId: $rootScope.account.companyId,
                type: "wmsIn"
            }, function(result) {
                vm.accountBinds = result;
            })
        }

        function sendWmsInApi(accountBind) {
            var pushOrdersDTO = {};
            pushOrdersDTO.accountBind = accountBind;
            pushOrdersDTO.wmsIn = vm.wmsIn;

            if (accountBind.type == 'chukou1' || accountBind.type == 'ecang' || accountBind.type == 'nodeWms') {
                $uibModal.open({
                    templateUrl: 'app/entities/sub-tracking/sub-tracking-delete-dialog.html',
                    controller: 'SubTrackingDeleteController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                        entity: [function() {
                            return pushOrdersDTO;
                        }]
                    }
                }).result.then(function(resultList) {
                    WmsIn.get({ id: vm.wmsIn.id }, onSaveSuccess);
                }, function() {});
                return;
            } else {
                WmsIn.sendWmsInApi(pushOrdersDTO, function(result) {
                    WmsIn.get({ id: vm.wmsIn.id }, onSaveSuccess);
                }, $rootScope.ALERT_ERROR);
            }
        }
        $scope.updateSales = function(item) {
            vm.wmsIn.salesId = item.id;
        };
        $scope.updateCus = function(item) {
            vm.wmsIn.cusId = item.id;
        };
        $scope.salesFilter = function(item) {
            return item.authorities.indexOf("ROLE_SALES") !== -1;
        };
        $scope.cusFilter = function(item) {
            return item.authorities.indexOf("ROLE_CS") !== -1;
        };

        function updateShipmentPkgAmt(cargo) {
            if (!cargo.pkgs || !cargo.palletCapacity) {
                cargo.palletAmt = null;
                return;
            }
            var palletAmtFloat = cargo.pkgs / cargo.palletCapacity;
            cargo.palletAmt = parseInt(palletAmtFloat);
            if (palletAmtFloat != cargo.palletAmt) {
                cargo.palletAmt++;
            }
            updateTtlPlts();
        }

        function updateTtlPlts() {
            var pallets = 0;
            angular.forEach(vm.wmsIn.cargoList, function(data) {
                if (data.palletAmt) {
                    pallets += data.palletAmt;
                }
            })
            vm.wmsIn.pallets = pallets;
        }

        function generateCustoms() {
            vm.isSaving = true;
            WmsIn.generateCustoms(vm.wmsIn, onGenerateSuccess, onGenerateError);
        }

        function onGenerateSuccess(result) {
            result.deliveryArrange = result.deliveryBySelf ? "y" : "n";
            vm.wmsIn = result;
            updateGwVolTtl();
            if (vm.wmsIn.customDeclarationId) {
                vm.wmsIn.encodeIdCus = WinAES.encode(vm.wmsIn.customDeclarationId.toString());
            }
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submitted'));
        }

        function updateGwVolTtl() {
            angular.forEach(vm.wmsIn.cargoList, function(data) {
                updatedDataTtl(data);
            })
            vm.totalItems = vm.wmsIn.cargoList.length;
            imperialUnitInit();
            $translate.refresh();
        }

        function updatedDataTtl(data) {
            if (data.pkgs) {
                if (data.nw) {
                    data.nwTtl = DataUtils.round(data.nw * data.pkgs, 4);
                }
                if (data.gw) {
                    data.gwTtl = DataUtils.round(data.gw * data.pkgs, 4);
                }
                if (data.vol) {
                    data.volTtl = DataUtils.round(data.vol * data.pkgs, 4);
                }
                if (data.capacity) {
                    data.capacityTtl = DataUtils.round(data.capacity * data.pkgs, 2);
                }
                if (data.piece) {
                    data.pieceTtl = DataUtils.round(data.piece * data.pkgs, 2);
                }
            }
        }
        function updateCargoTtl(data) {
            updatedDataTtl(data);
            updateTtl();
        }
        function onGenerateError() {
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submittedfailed'));

        }

        function printIn(reportType) {
            var wmsIn = angular.copy(vm.wmsIn);
            wmsIn.wmsWorkLogs = vm.wmsWorkLogs;
            OpsFiles.generateWmsInPaper({ reportType: reportType }, wmsIn, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('autopsApp.wmsIn.viewWmsInPaperFailed'));
            });
        }

        function printPl(reportType, fileType) {
            var wmsIn = angular.copy(vm.wmsIn);
            wmsIn.reportType = reportType;
            wmsIn.fileType = fileType;
            wmsIn.wmsWorkLogs = vm.wmsWorkLogs;
            wmsIn.cargoDTOList = angular.copy(wmsIn.cargoList);
            OpsFiles.generateWmsInPaper({}, wmsIn, function(result) {
                $rootScope.PDFPRINTEXCELDOWNLOAD(result, fileType, 'packingList');
            }, function(error) {
                layer.msg($translate.instant('autopsApp.wmsIn.viewWmsInPaperFailed'));
            });
        }

        function loadWmsIn() {
            if (!vm.searchParam) {
                return;
            }
            WmsIn.loadWmsIn({
                searchNum: vm.searchParam
            }, function(result) {
                if (!result || !result.id) {
                    layer.msg($translate.instant('global.messages.resultNull'));
                    return;
                }
                result.deliveryArrange = result.deliveryBySelf ? "y" : "n";
                vm.wmsIn = result;
                updateGwVolTtl();
                vm.files = [];
                $translate.refresh();
                layer.msg($translate.instant('entity.action.loadSuccess'));
            }, function(error) {
                layer.msg($translate.instant('global.loadFailed'));
            });
        }

        function copyCargo(cargo, index) {
            var cargoCopy = angular.copy(cargo);
            cargoCopy.id = null;
            cargoCopy.inTime = null;
            cargoCopy.outTime = null;
            cargoCopy.location = null;
            cargoCopy.locationId = null;
            cargoCopy.locationType = null;
            cargoCopy.allOut = false;
            cargoCopy.pkgs = null;
            cargoCopy.warehouseId = null;
            cargoCopy.areaId = null;
            cargoCopy.palletAmt = null;
            cargoCopy.gateInTime = null;
            cargoCopy.remarks = null;
            cargoCopy.status = null;
            cargoCopy.billDate = null;
            cargoCopy.lastCargoId = null;
            cargoCopy.returnHandled = (isOut == null ? false : null);       
            if ($scope.isMixedCargo(cargoCopy)) {
                cargoCopy.wmsSubPackingLists.forEach(function(item){
                    item.id = null;
                    item.wmsCargo = null;
                    item.wmsCargoId = null;
                })
            }     
            vm.wmsIn.cargoList.splice(index+1, 0, cargoCopy);
            updateTtl();            
        }

        function doAntiIn() {
            vm.isSaving = true;
            WmsIn.antiIn({
                id: vm.wmsIn.id
            }, function(result) {
                result.deliveryArrange = result.deliveryBySelf ? "y" : "n";
                vm.wmsIn = result;
                updateGwVolTtl();
                vm.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(error) {
                vm.isSaving = false;
                layer.msg($translate.instant('global.messages.submittedfailed'));
            })
        }
        $scope.wmsLabels = function(reportType) {
            $scope.format = 1;
            WmsIn.wmsLabels({
                reportType: reportType,
                fileType: $scope.format
            }, vm.wmsIn, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('autopsApp.wmsIn.viewWmsInPaperFailed'));
            });
        }
        $scope.wmsPltLabels = function(reportType) {
            $scope.format = 1;
            WmsIn.wmsPltLabels({
                reportType: reportType,
                fileType: $scope.format
            }, vm.wmsIn, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('autopsApp.wmsIn.viewWmsInPaperFailed'));
            });
        }

        function antiIn() {
            if (!$rootScope.account.companyId) { return; }
            if ($rootScope.HAVENOAUTH('AU_WMS_ANTI')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_WMS_ANTI');
                return;
            }
            layer.confirm($translate.instant('autopsApp.wmsIn.antiInAlert'), {
                icon: 0,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                doAntiIn();
            }, function() {
                return;
            });
        }

        vm.opsItems = [];

        function getAllWmsService(cargo) {
            if (cargo && !cargo.wmsService) {
                cargo.wmsService = "";
            }
            if (vm.opsItems && vm.opsItems.length > 0) {
                return;
            }
            OpsItem.getAllWmsService({
                whId: vm.wmsIn.warehouseId,
                companyId: $rootScope.account.companyId
            }, function(result) {
                vm.opsItems = result;
                if (!vm.opsItems || vm.opsItems.length == 0) {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.wmsServiceTips'));
                }
            }, function(error) {
                layer.msg($translate.instant('global.noRecord'));
            });
        }

        function printReturnWorkSheet() {
            if (!vm.wmsIn.cargoList || vm.wmsIn.cargoList.length == 0) {
                return;
            }
            var wmsIns = [];
            wmsIns.push(vm.wmsIn);
            WmsIn.printReturnWorkSheet({
                reportCode: 830,
                companyId: $rootScope.account.companyId
            }, wmsIns, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.noRecord'));
            });
        }

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        function deleteOne(wmsIn) {
            if ($rootScope.HAVENOAUTH('AU_DEL_BIZ')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_DEL_BIZ');
                return;
            }        
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-delete-dialog.html',
                controller: 'WmsInDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return wmsIn;
                    }]
                }
            }).result.then(function() {
                $uibModalInstance.close(vm.wmsIn);
            }, function() {});

        }

        $scope.clear = function() {
            $uibModalInstance.dismiss();
        }

        function isCreateReturn() {
            if (!vm.wmsIn.cargoList) {
                return false;
            }
            for (var i = 0; i < vm.wmsIn.cargoList.length; i++) {
                if (vm.wmsIn.cargoList[i].returnHandle) {
                    return true;
                }
            }
            return false;
        }

        var path = $location.path();
        var wmsInCopy = {};
        var isOut = false;
        $timeout(function() {
            if (path.indexOf("rma") >= 0 || isCreateReturn()) {
                isOut = null;
            }
            if (path.indexOf("copy") >= 0) {
                vm.wmsIn.id = null;
                vm.wmsIn.status = "new";
                vm.wmsIn.updateTime = null;
                vm.wmsIn.warehousingNumber = null;
                vm.wmsIn.actualNum = null;
                vm.wmsIn.registerBy = null;
                vm.wmsIn.registerId = null;
                vm.wmsIn.createTime = null;
                vm.wmsIn.inTime = null;
                vm.wmsIn.allOut = false;
                vm.wmsIn.outNum = null;
                vm.wmsIn.applicationNum = null;
                vm.wmsIn.gw = null;
                vm.wmsIn.vol = null;
                vm.wmsIn.vol = null;
                vm.wmsIn.guideId = null;
                vm.wmsIn.poNum = null;
                vm.wmsIn.ctnNum = null;
                vm.wmsIn.truckNum = null;
                vm.wmsIn.wmsInNum = null;
                vm.wmsIn.wmsOutNum = null;
                vm.wmsIn.remarks = null;
                vm.wmsIn.audited = null;
                vm.wmsIn.auditor = null;
                vm.wmsIn.returnTracking = null;
                vm.wmsIn.orderPushed = false;
                vm.wmsIn.thirdpartyPlatform = null;
                vm.wmsIn.thirdpartyPlatformId = null;
                vm.wmsIn.thirdpartyPlatformRef = null;
                vm.wmsIn.jobNum = null;
                vm.wmsIn.isOut = isOut;
                vm.wmsIn.customDeclarationId = null;
                vm.wmsIn.customReleased = false;
                vm.wmsIn.customNum = null;
                if (vm.wmsIn.cargoList && vm.wmsIn.cargoList.length > 0) {
                    for (var i = 0; i < vm.wmsIn.cargoList.length; i++) {
                        vm.wmsIn.cargoList[i].id = null;
                        vm.wmsIn.cargoList[i].inTime = null;
                        vm.wmsIn.cargoList[i].outTime = null;
                        vm.wmsIn.cargoList[i].location = null;
                        vm.wmsIn.cargoList[i].locationId = null;
                        vm.wmsIn.cargoList[i].locationType = null;
                        vm.wmsIn.cargoList[i].allOut = false;
                        vm.wmsIn.cargoList[i].pkgs = null;
                        vm.wmsIn.cargoList[i].warehouseId = null;
                        vm.wmsIn.cargoList[i].areaId = null;
                        vm.wmsIn.cargoList[i].palletAmt = null;
                        vm.wmsIn.cargoList[i].gateInTime = null;
                        vm.wmsIn.cargoList[i].remarks = null;
                        vm.wmsIn.cargoList[i].status = null;
                        vm.wmsIn.cargoList[i].poNum = null;
                        vm.wmsIn.cargoList[i].ctnNum = null;
                        vm.wmsIn.cargoList[i].truckNum = null;
                        vm.wmsIn.cargoList[i].wmsInNum = null;
                        vm.wmsIn.cargoList[i].wmsOutNum = null;
                        vm.wmsIn.cargoList[i].billDate = null;
                        vm.wmsIn.cargoList[i].lastCargoId = null;
                        vm.wmsIn.cargoList[i].oldLabel = null;
                        vm.wmsIn.cargoList[i].newLabel = null;
                        vm.wmsIn.cargoList[i].returnHandled = (isOut == null ? false : null);
                        vm.wmsIn.cargoList[i].palletizes = null;
                        vm.wmsIn.cargoList[i].wmsSubPackingLists = null;
                    }
                }
                vm.isEdit = true;
            } else if (path.indexOf("new") >= 0) {
                vm.wmsIn = {
                    cargoList: [{
                        allOut: false,
                        action: "appIn",
                        deliveryBySelf: false,
                        returnHandle: isOut == null ? "stock" : null,
                        returnHandled: isOut == null ? false : null,
                        packageType: "CTNS"
                    }],
                    type: isOut == null?null:($rootScope.HAVEAUTH('FBA') ? "FBASEA" : "WMS"),
                    handleType: $rootScope.HAVEAUTH('WMS_OVERSEAS') ? "wmsService" : "transportService",
                    isOut: isOut,
                    freeDays: isOut ? null : $rootScope.account.freeDays,
                    status: "new",
                    allOut: false
                }
                vm.isEdit = true;
                getWarehouses();
            } else { //(path.indexOf("wms-in/detail") >= 0)
                if (vm.wmsIn.status == "new" || vm.wmsIn.checkWmsIn) {
                    vm.isEdit = true;
                }
                if (vm.wmsIn.guideId) {
                    vm.wmsIn.encodeId = WinAES.encode(vm.wmsIn.guideId.toString());
                }
                if (vm.wmsIn.customDeclarationId) {
                    vm.wmsIn.encodeIdCus = WinAES.encode(vm.wmsIn.customDeclarationId.toString());
                }
                if (vm.wmsIn.id) {
                    WmsWorkLog.getOpRequirment({
                        type: "wms",
                        id: vm.wmsIn.id
                    }, function(result) {
                        vm.wmsWorkLogs = result;
                    });
                }
            }
            vm.wmsIn.deliveryArrange = vm.wmsIn.deliveryBySelf ? "y" : "n";
            vm.imperialUnit = $rootScope.HAVEAUTH("IMPERIAL_UNIT");
            updateGwVolTtl();
            updateTtl();  
            if(vm.wmsIn.warehousingNumber && vm.wmsIn.warehousingNumber.indexOf("PLT") == 0){
                vm.isEdit = true;
            }            
            angular.copy(vm.wmsIn, wmsInCopy);
            $translate.refresh();
        });
        var rateUnsubscribe = $rootScope.$on('autopsApp:wmsWorkLogUpdate', function(event, result) {
            if (result && result.id) {
                var isNew = true;
                angular.forEach(vm.wmsWorkLogs, function(data) {
                    if (data.id == result.id) {
                        data.count = result.count;
                        data.work = result.work;
                        isNew = false;
                    }
                })
                if (isNew) {
                    vm.wmsWorkLogs.push(result)
                }
            }
        });
        $scope.$on('$destroy', rateUnsubscribe);

        function getBarcode(cargo, type) {
            if (!vm.isEdit || cargo.allOut) { return; }
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-dialog.html',
                controller: 'WmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md'
            }).result.then(function(code) {
                cargo[type] = code;
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }
        function addCargo() {
            if (!vm.wmsIn.cargoList) {
                vm.wmsIn.cargoList = [];
            }
            vm.wmsIn.cargoList.push({
                isPallet: vm.wmsIn.handleType == "transportService",
                packageType: "CTNS",
                allOut: false,
                returnHandle: isOut == null ? "stock" : null,
                returnHandled: isOut == null ? false : null,
                action: "appIn"
            });
            updateGwVolTtl();
            updateTtl();    
        }

        function delCargo(cargo) {
            if (cargo.id && !angular.equals(vm.wmsIn, wmsInCopy)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if (cargo.id != null) {
                WmsCargo.delete({
                    id: cargo.id
                }, function(result) {
                    result.deliveryArrange = result.deliveryBySelf ? "y" : "n";
                    vm.wmsIn = result;
                    updateGwVolTtl();
                    wmsInCopy = angular.copy(vm.wmsIn);
                    $scope.$emit('autopsApp:wmsInUpdate', result);
                }, $rootScope.ALERT_ERROR);
            } else {
                var index = vm.wmsIn.cargoList.indexOf(cargo);
                if (index > -1) {
                    vm.wmsIn.cargoList.splice(index, 1);
                };
                updateTtl();
            }
        };

        function save() {
            if (vm.wmsIn.isClosed) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if (!vm.wmsIn.venderId || !vm.wmsIn.warehouseId) {
                layer.msg($translate.instant('autopsApp.wmsIn.chooseClientAndWarehouseTips'));
                return;
            }
            vm.isSaving = true;
            if (angular.equals(vm.wmsIn, wmsInCopy)) {
                layer.msg($translate.instant('global.no_amendmend'));
                vm.isSaving = false;
                return;
            }
            if (vm.wmsIn.isOut == false) {
                var skuNull = false;
                angular.forEach(vm.wmsIn.cargoList, function(data) {
                    if (!data.sku) {
                        skuNull = true;
                    }
                })
                if (skuNull && vm.wmsIn.handleType == 'wmsService') {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.skuNullAlert'));
                    vm.isSaving = false;
                    return;
                }
            }
            if ($rootScope.account.companyId) {
                if (!vm.wmsIn.companyId) {
                    vm.wmsIn.companyId = $rootScope.account.companyId;
                }
                vm.wmsIn.opName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                if (vm.wmsIn.status == "new" || vm.wmsIn.status == "Requested" || vm.wmsIn.status == "Amended") {
                    vm.wmsIn.status = "Confirmed";
                }
                if (vm.wmsIn.actualNum) {
                    if (vm.wmsIn.actualNum == vm.wmsIn.applicationNum) {
                        vm.wmsIn.status = "allIn";
                        vm.wmsIn.allOut = true;
                        if (!vm.wmsIn.inTime) {
                            vm.wmsIn.inTime = new Date();
                        }
                    } else {
                        vm.wmsIn.status = "partIn";
                        vm.wmsIn.allOut = false;
                    }
                }
                angular.forEach(vm.wmsIn.cargoList, function(data) {
                    if (!data.keeperId) {
                        data.inTime = new Date();
                        data.keeper = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                        data.keeperId = $rootScope.account.id;
                    }
                })
            }
            initWmsCargo();
            vm.wmsIn.deliveryBySelf = vm.wmsIn.deliveryArrange == "y" ? true : false;
            if (vm.wmsIn.id) {
                WmsIn.update(vm.wmsIn, onSaveSuccess, onSaveError);
            } else {
                vm.wmsIn.isOut = isOut;
                WmsIn.save(vm.wmsIn, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            result.deliveryArrange = result.deliveryBySelf ? "y" : "n";
            vm.wmsIn = result;
            updateGwVolTtl();
            wmsInCopy = angular.copy(vm.wmsIn);
            vm.isSaving = false;
            vm.isEdit = result.warehousingNumber.indexOf("PLT") == 0;
            $scope.$emit('autopsApp:wmsInUpdate', result);
            layer.msg($translate.instant('global.messages.submitted'));
        }

        function onSaveError(result) {
            vm.isSaving = false;
            $rootScope.ALERT_ERROR(result);
        }

        function updateVol(cargo) {
            if (cargo.length && cargo.width && cargo.height) {
                cargo.vol = cargo.length / 100 * cargo.width / 100 * cargo.height / 100;
                cargo.vol = cargo.vol.toFixed(4) - 0;
                updateCargoTtl(cargo);
            }            
        }
        function gwTtlChange(cargo) {
            if(!cargo.pkgs || !cargo.gwTtl){
                return;
            }
            cargo.gw = DataUtils.round(cargo.gwTtl/cargo.pkgs, 2);
            updateTtl();
        }
        function volTtlChange(cargo) {
            if(!cargo.pkgs || !cargo.volTtl){
                return;
            }
            cargo.vol = DataUtils.round(cargo.volTtl/cargo.pkgs, 4);
            updateTtl();
        }

        function updateTtl() {
            if (!vm.wmsIn.cargoList || vm.wmsIn.cargoList.length == 0) {
                return;
            }
            vm.wmsIn.gw = 0;
            vm.wmsIn.vol = 0;
            vm.wmsIn.applicationNum = 0;
            for (var i = 0; i < vm.wmsIn.cargoList.length; i++) {
                if (!vm.wmsIn.cargoList[i].vol) {
                    if (vm.wmsIn.cargoList[i].length && vm.wmsIn.cargoList[i].width && vm.wmsIn.cargoList[i].height) {
                        vm.wmsIn.cargoList[i].vol = vm.wmsIn.cargoList[i].length / 100 * vm.wmsIn.cargoList[i].width / 100 * vm.wmsIn.cargoList[i].height / 100;
                        vm.wmsIn.cargoList[i].vol = vm.wmsIn.cargoList[i].vol.toFixed(4) - 0;
                    }
                }
                if (vm.wmsIn.cargoList[i].pkgs) {
                    vm.wmsIn.applicationNum = vm.wmsIn.applicationNum + vm.wmsIn.cargoList[i].pkgs;
                    if (vm.wmsIn.cargoList[i].volTtl) {
                        // vm.wmsIn.cargoList[i].volTtl = (vm.wmsIn.cargoList[i].vol * vm.wmsIn.cargoList[i].pkgs).toFixed(4) - 0;
                        vm.wmsIn.vol += vm.wmsIn.cargoList[i].volTtl;
                    }
                    if (vm.wmsIn.cargoList[i].gwTtl) {
                        // vm.wmsIn.cargoList[i].gwTtl = (vm.wmsIn.cargoList[i].gw * vm.wmsIn.cargoList[i].pkgs).toFixed(2) - 0;
                        vm.wmsIn.gw += vm.wmsIn.cargoList[i].gwTtl;
                    }
                    if (vm.wmsIn.cargoList[i].nw) {
                        vm.wmsIn.cargoList[i].nwTtl = (vm.wmsIn.cargoList[i].nw * vm.wmsIn.cargoList[i].pkgs).toFixed(2) - 0;
                    }
                    if (vm.wmsIn.cargoList[i].capacity) {
                        vm.wmsIn.cargoList[i].capacityTtl = (vm.wmsIn.cargoList[i].capacity * vm.wmsIn.cargoList[i].pkgs).toFixed(2) - 0;
                    }
                    if (vm.wmsIn.cargoList[i].piece) {
                        vm.wmsIn.cargoList[i].pieceTtl = (vm.wmsIn.cargoList[i].piece * vm.wmsIn.cargoList[i].pkgs).toFixed(2) - 0;
                    }
                }
                updateShipmentPkgAmt(vm.wmsIn.cargoList[i]);
            }
            if (vm.wmsIn.gw) {
                vm.wmsIn.gw = vm.wmsIn.gw.toFixed(2) - 0;
            } else {
                vm.wmsIn.gw = null;
            }

            if (vm.wmsIn.vol) {
                vm.wmsIn.vol = vm.wmsIn.vol.toFixed(3) - 0;
            } else {
                vm.wmsIn.vol = null;
            }

            if (vm.wmsIn.applicationNum) {
                vm.wmsIn.applicationNum = vm.wmsIn.applicationNum.toFixed(0) - 0;
            } else {
                vm.wmsIn.applicationNum = null;
            }
            vm.totalItems = vm.wmsIn.cargoList.length;
            $translate.refresh();
        }

        var isLoadPreIn = false;

        function resend() {
            vm.isSaving = true;
            if (angular.equals(vm.wmsIn, wmsInCopy)) {
                layer.msg($translate.instant('global.no_amendmend'));
                vm.isSaving = false;
                return;
            }
            if (!isLoadPreIn) {
                // vm.wmsIn.warehousingNumber = null;  
                vm.wmsIn.status = "Amended";
            }
            vm.wmsIn.deliveryBySelf = vm.wmsIn.deliveryArrange == "y" ? true : false;
            initWmsCargo()
            WmsIn.update(vm.wmsIn, onSendSuccess, onSendError);
        }

        vm.pubGoods = null;

        function getPubGoods() {
            if (vm.pubGoods || !$rootScope.account.companyId) {
                return;
            }
            ManageEnterprise.getPubGoods({ companyId: $rootScope.account.companyId }, function(result) {
                vm.pubGoods = result;
            })
        }

        function send() {
            vm.isSaving = true;
            if (!vm.wmsIn.id) {
                vm.wmsIn.isOut = isOut;
            }
            if (!isLoadPreIn) {
                // vm.wmsIn.warehousingNumber = null;
                vm.wmsIn.status = "Requested";
            }
            vm.wmsIn.deliveryBySelf = vm.wmsIn.deliveryArrange == "y" ? true : false;
            initWmsCargo()
            WmsIn.send(vm.wmsIn, onSendSuccess, onSendError);
        }

        function onSendSuccess(result) {
            result.deliveryArrange = result.deliveryBySelf ? "y" : "n";
            vm.wmsIn = result;
            updateGwVolTtl();
            angular.copy(vm.wmsIn, wmsInCopy);
            layer.msg($translate.instant('global.send_success'));
            vm.isSaving = false;
            vm.isEdit = false;
        }

        function onSendError(error) {
            vm.isSaving = false;
            angular.copy(wmsInCopy, vm.wmsIn);
            $rootScope.ALERT_ERROR(error);
        }

        function setStatus(status) {
            vm.isSaving = true;
            WmsIn.setStatus({
                id: vm.wmsIn.id,
                status: status
            }, onSaveSuccess, onSaveError);
        }

        function initWmsCargo() {
            if (vm.wmsIn.cargoList && vm.wmsIn.cargoList.length > 0) {
                angular.forEach(vm.wmsIn.cargoList, function(data) {
                    data.clientId = vm.wmsIn.venderId;
                    data.companyId = vm.wmsIn.companyId;
                    data.medCompanyId = vm.wmsIn.medCompanyId;
                    if (!data.isPallet) {
                        data.palletCapacity = null;
                        data.palletAmt = null;
                    }
                })
                vm.totalItems = vm.wmsIn.cargoList.length;
                $translate.refresh();
            }
        }

        function getWarehouses() {
            if (vm.warehouses && vm.warehouses.length > 0) {
                return;
            }
            if (!$rootScope.account.companyId) {
                WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: true,
                    includeGroup: true
                }, function(data) {
                    vm.warehouses = data;
                    autoFillWarehouse();
                });
            } else if (vm.wmsIn.isVirtual && vm.wmsIn.medCompanyId == $rootScope.account.companyId) {
                WmsWarehouse.getAll({
                    includeOwn: false,
                    includeVirtual: true,
                    includeGroup: false
                }, function(data) {
                    vm.warehouses = data;
                    autoFillWarehouse();
                });
            } else {
                WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function(data) {
                    vm.warehouses = data;
                    autoFillWarehouse();
                });
            }
        }
        function autoFillWarehouse() {
            if(!vm.wmsIn.warehouseId && vm.warehouses && vm.warehouses.length == 1){
                warehouseSelected(vm.warehouses[0]);
            }
        }


        function clientSelected(client) {
            vm.wmsIn.venderId = client.id;
            vm.wmsIn.salesName = client.creater;
            vm.wmsIn.salesId = client.createdBy;
            if (client.cusId && client.cusName) {
                vm.wmsIn.cusId = client.cusId;
                vm.wmsIn.cusName = client.cusName;
            }
            if (client.freeDays) {
                vm.wmsIn.freeDays = client.freeDays;
            }
            $rootScope.WMSGOODS = [];
        }

        function warehouseSelected(warehouse) {
            vm.wmsIn.warehouseName = warehouse.name;
            vm.wmsIn.warehouseId = warehouse.id;
            vm.wmsIn.companyId = warehouse.companyId;
            vm.wmsIn.medCompanyId = warehouse.medCompanyId;
            vm.wmsIn.isVirtual = warehouse.isVirtual;
            vm.wmsIn.medCompany = warehouse.medCompany;
            vm.wmsIn.medVenderId = warehouse.medVenderId;
            vm.locations = null;
        }

        function amendation() {
            vm.isEdit = true;
        }


        function generateGuide(companyId) {
            if (vm.isSaving) {
                return;
            }
            vm.isSaving = true;
            var wmsInDTO = angular.copy(vm.wmsIn);
            if (companyId) {
                wmsInDTO.guideCompanyId = companyId;
            }
            WmsIn.generateGuide(wmsInDTO, function(result) {
                result.deliveryArrange = result.deliveryBySelf ? "y" : "n";
                vm.wmsIn = result;
                updateGwVolTtl();
                vm.isSaving = false;
                if (vm.wmsIn.guideId) {
                    vm.wmsIn.encodeId = WinAES.encode(vm.wmsIn.guideId.toString());
                }
                layer.msg($translate.instant('global.messages.submitted'));
            });
        }

        function _onGoodsSelected(data, cargo) {
            cargo.wmsGoodsId = data.wmsGoodsId; //batchid
            goodsSelected(data, cargo);
        }

        function _onPubGoodsSelected(data, cargo) {
            goodsSelected(data, cargo);
        }

        function goodsSelected(data, cargo) {
            cargo.code = data.code;
            cargo.sku = data.sku;
            cargo.cargoName = data.cargoName;
            cargo.cargoNameEn = data.cargoNameEn;
            cargo.packageType = data.packageType;
            cargo.gw = data.gw;
            cargo.pkgs = null;
            cargo.palletAmt = null;
            cargo.vol = data.vol;
            cargo.length = data.length;
            cargo.width = data.width;
            cargo.height = data.height;
            cargo.isPallet = data.isPallet;
            cargo.palletCapacity = data.palletCapacity;
            cargo.chargeType = data.chargeType;
            cargo.chargePrice = data.chargePrice;
            cargo.piece = data.piece;
            cargo.pieceUnit = data.pieceUnit;
            cargo.nw = data.nw;
            cargo.capacity = data.capacity;
            cargo.capacityUnit = data.capacityUnit;
            cargo.goodsValue = data.goodsValue;
            cargo.dutyRate = data.dutyRate;
            cargo.declareValue = data.declareValue;
            cargo.combinationSet = data.combinationSet;
            if (cargo.cargoName) {
                if (!vm.wmsIn.cargoDescription) {
                    vm.wmsIn.cargoDescription = cargo.cargoName;
                }
            }
        }

        function clearWmsGoods(cargo) {
            if (!vm.isEdit) { return; };
            cargo.code = null;
            cargo.wmsGoodsId = null;
            cargo.cargoName = null;
            cargo.cargoNameEn = null;
            cargo.packageType = null;
            cargo.gw = null;
            cargo.vol = null;
            cargo.length = null;
            cargo.width = null;
            cargo.height = null;
            cargo.isPallet = null;
            cargo.palletCapacity = null;
            cargo.pkgs = null;
            cargo.palletAmt = null;
        }

        function uploadFileOnClick(file) {
            if (!file) {
                return;
            }
            var fd = new FormData();
            var filePath = "/" + "wmsIn/" + vm.wmsIn.id + "/";
            fd.append('filepath', filePath);
            fd.append('file', file);
            fd.append('companyId', vm.wmsIn.companyId);

            $http.post(
                'api/ops-files/uploadFile', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(
                function(data) {
                    if (angular.isDefined(data.filepath)) {
                        reloadFiles(data);
                        layer.msg($translate.instant('global.uploadSuccess'));
                    }
                }).error(function(error) {
                layer.msg($translate.instant('global.uploadFail'));
            });
        };

        function importCargo(file) {
            if (!file) { return; }
            var fd = new FormData();
            fd.append('file', file);
            if(vm.wmsIn.venderId){
                fd.append('customerId', vm.wmsIn.venderId);
            }
            if(vm.wmsIn.warehouseId){
                fd.append('warehouseId', vm.wmsIn.warehouseId);
            }            
            $http.post(
                'api/wms-cargos/importByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {                
                if (!data || data.length == 0) {
                    layer.msg($translate.instant('global.messages.resultNull'));
                    return;
                }
                data = angular.fromJson(data);
                if (!data || data.length == 0){
                    layer.msg($translate.instant('global.messages.resultNull'));  
                    return;
                }
                if (vm.wmsIn.cargoList &&
                    vm.wmsIn.cargoList.length == 1 &&
                    !vm.wmsIn.cargoList[0].id &&
                    !vm.wmsIn.cargoList[0].pkgs) {
                    vm.wmsIn.cargoList = [];
                }
                angular.forEach(data, function(cargo) {
                    vm.wmsIn.cargoList.push(cargo);
                })
                updateTtl();
                layer.msg($translate.instant('global.importSuccess'));
                if(vm.wmsIn.id && vm.wmsIn.companyId){
                    $rootScope.UPLOAD_FILE_WITH_NAME(file, '/wmsIn/' + vm.wmsIn.id + '/', vm.files, vm.wmsIn.companyId, null);
                }
            }).error(function(error) {
                $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
            });

        };

        function setAudited(audied) {
            if (!$rootScope.account.authorities || $rootScope.account.authorities.indexOf("AU_WMS_AUDIT") == -1) {
                vm.wmsIn.audited = !audied;
                $rootScope.FORBIDDEN_BY_ALERT('AU_WMS_AUDIT');
                return;
            }
            if (audied) {
                vm.wmsIn.auditor = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            } else {
                vm.wmsIn.auditor = null;
            }
            save();
        }

        function deliveryBySelfChange() {
            save();
        }

        function getByTrackingNumber(trackingNum) {
            if (!trackingNum || $rootScope.account.companyId || vm.wmsIn.id) {
                return;
            }
            WmsIn.getByTrackingNumber({
                returnTracking: trackingNum
            }, function(result) {
                if (result && result.id) {
                    layer.confirm($translate.instant('autopsApp.wmsIn.trackingExistAlert'), {
                        icon: 3,
                        btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                    }, function(index) {
                        result.deliveryArrange = result.deliveryBySelf ? "y" : "n";
                        vm.wmsIn = result;
                        updateGwVolTtl();
                        isLoadPreIn = true;
                        $translate.refresh();
                        layer.close(index);
                    }, function() {});
                }
            });
        }

        $scope.isPalletChange = function(cargo) {
            if (!cargo.isPallet) {
                cargo.palletCapacity = null;
                cargo.palletAmt = null;
            }
        }

        $scope.pkgChange = function(cargo) {
            if (!cargo.pkgMore) {
                cargo.piece = null;
                cargo.pieceUnit = null;
                cargo.capacity = null;
                cargo.capacityUnit = null;
            }
        }

        $scope.batchChange = function(cargo) {
            if (!cargo.batchMore) {
                cargo.batchOne = null;
                cargo.batchTwo = null;
                cargo.batchThree = null;
                cargo.batchFour = null;
            }
        }

        $scope.skuMoreChange = function(cargo) {
            cargo.skuMore = !cargo.skuMore;
            if (!cargo.skuMore) {
                cargo.fnSku = null;
                cargo.upc = null;
                cargo.asin = null;
            }
        }

        function pkgTypeSelected(item, cargo) {
            if (item == 'PALLETS') {
                cargo.pkgMore = true;
            }
        }

        $scope.palletizePop = function() {
            $uibModal.open({
                templateUrl: 'app/entities/crm-price/crm-price-dialog.html',
                controller: 'CrmPriceDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return angular.copy(vm.wmsIn);
                    }]
                }
            }).result.then(function(result) {
                vm.wmsIn.pallets = result.pltNum;
                vm.wmsIn.palletizeJson = angular.toJson(result);
            }, function() {});
        }

        function changeValue(cargo) {
            var goodsValue = cargo.goodsValue;
            layer.prompt({
                formType: 0,
                value: goodsValue,
                title: $translate.instant('autopsApp.wmsIn.goodsValue'),
                area: ['280px', '160px']
            }, function(value, index, elem) {
                value = value - 0;
                if (!value || value == cargo.goodsValue) {
                    layer.close(index);
                    return;
                }
                if (isNaN(value)) {
                    layer.msg($translate.instant('autopsApp.wmsIn.notNumAlert'));
                    return;
                }
                var cargoCopy = angular.copy(cargo);
                cargoCopy.goodsValue = value;
                WmsCargo.updateInCargoCost(cargoCopy, function(result) {
                    cargo.goodsValue = result.goodsValue;
                    $translate.refresh();
                });
                layer.close(index);
                $translate.refresh();
            });
        }

        $scope.groupCompanys = [];
        $scope.loadCompanys = function() {
            if ($scope.groupCompanys && $scope.groupCompanys.length > 0) {
                return;
            }
            OpsCompany.getGroupCompanys(function(result) {
                $scope.groupCompanys = result;
            })
        };


        function addSkuToWmsGoods() {
            WmsIn.addSkuToWmsGoods(vm.wmsIn, function(result) {
                $rootScope.OPS_ALERT($translate.instant('global.importSuccessAmt', { param: result.successCount }));
            }, $rootScope.ALERT_ERROR)
        }

        $scope.setIsClosed = function() {
            vm.wmsIn.isClosed = !vm.wmsIn.isClosed;
            if (!vm.wmsIn.isClosed && $rootScope.account.authorities.indexOf("AU_BILL_CLOSE") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_CLOSE');
                return;
            }
            if (vm.wmsIn.isClosed && $rootScope.account.authorities.indexOf("AU_BILL_OPEN") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_OPEN');
                return;
            }
            if (!vm.wmsIn.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if (!angular.equals(wmsInCopy, vm.wmsIn)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            WmsIn.setIsClosed({ id: vm.wmsIn.id }, onSaveSuccess, onSaveError);
        }

        $scope.sendSizeToWarehouse = function() {
            if (!angular.equals(vm.wmsIn, wmsInCopy)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            layer.confirm($translate.instant('global.confirmAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                WmsIn.sendSizeToWarehouse({ id: vm.wmsIn.id }, function(result) {
                    layer.msg($translate.instant('global.send_success'));
                }, $rootScope.ALERT_ERROR);
            }, function() {});
        }
        function cancelOrder () {
            layer.confirm($translate.instant('autopsApp.wmsIn.cancelOrderAlert'), {
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                layer.load(1, {shade: 0.3});
                WmsIn.cancelOrder(vm.wmsIn, function(){
                    WmsIn.get({id : vm.wmsIn.id}, onSaveSuccess);
                    layer.closeAll();
                }, function (error){
                    layer.closeAll();  
                    if(error && error.data && error.data.description){
                        $rootScope.OPS_ALERT(error.data.description);
                    }
                    else {
                        layer.msg($translate.instant('global.requestFail'));
                    }
                });
                layer.close(index);
            }, function() {
            });
        }
        vm.page = 1;
        vm.itemsPerPage = 20;
        vm.totalItems = 0;

        $scope.filter = null;
        $scope.pageFiler = function(cargo, idx){
            if($scope.filter){
                return true;
            }
            return idx >= (vm.itemsPerPage * (vm.page - 1)) && idx < (vm.page * vm.itemsPerPage) ;
        }

        function registPltIn(){
            if(vm.wmsIn.audited){
                layer.msg($translate.instant('autopsApp.wmsIn.auditedAlert'));
                return;
            }   
            WmsIn.registPltIn(vm.wmsIn, onSaveSuccess, onSaveError);     
        }
        function loadPalletizes(wmsCargo){
            Palletize.get({palletId: wmsCargo.id}, function(result){
                wmsCargo.palletizes = result;
                angular.forEach(wmsCargo.palletizes, function(data){
                    if(data.boxNumType == "mark"){
                        data.boxNumTypeName = $translate.instant('autopsApp.wmsIn.mark');
                    }
                    if(data.boxNumType == "sku"){
                        data.boxNumTypeName = "SKU";
                    }
                    if(data.boxNumType == "poNum"){
                        data.boxNumTypeName = "PO#";
                    }
                    if(data.boxNumType == "batchOne"){
                        data.boxNumTypeName = "ShipmentId";
                    }
                })
            }, onSaveError);     
        }
        function getWmsInApiRequestBody(accountBind){
            var pushOrdersDTO = {};
            pushOrdersDTO.accountBind = accountBind;
            pushOrdersDTO.wmsIn = vm.wmsIn;
            layer.load(1, {shade: 0.3});
            WmsIn.getWmsInApiRequestBody(pushOrdersDTO, function(result){
                layer.closeAll();
                $rootScope.DOWNLOAD_TXT(result.value, vm.wmsIn.warehousingNumber + "_request_body.txt");                
            }, function(error){
                layer.closeAll();
            });
        }

        
        function imperialUnitChange () {
            if(vm.imperialUnit){
                imperialUnitInit();
            }
        }
        function imperialUnitInit () {
            angular.forEach(vm.wmsIn.cargoList, function(cargo){
                if(cargo.gw){
                    cargo.gwLb = DataUtils.round(cargo.gw * 2.20462262, 2);
                }
                if(cargo.length){
                    cargo.lengthInch = DataUtils.round(cargo.length * 0.393700788, 2);
                }
                if(cargo.width){
                    cargo.widthInch = DataUtils.round(cargo.width * 0.393700788, 2);
                }
                if(cargo.height){
                    cargo.heightInch = DataUtils.round(cargo.height * 0.393700788, 2);
                }
                if(cargo.vol){
                    cargo.volInch = DataUtils.round(cargo.vol * 35.3147, 4);
                }
            })            
        }

        function imperialChange (cargo) {
            if(cargo.gwLb){
                cargo.gw = DataUtils.round(cargo.gwLb / 2.20462262, 2);
            }
            if(cargo.lengthInch){
                cargo.length = DataUtils.round(cargo.lengthInch / 0.393700788, 2);
            }
            if(cargo.widthInch){
                cargo.width = DataUtils.round(cargo.widthInch / 0.393700788, 2);
            }
            if(cargo.heightInch){
                cargo.height = DataUtils.round(cargo.heightInch / 0.393700788, 2);
            }
            if (cargo.lengthInch && cargo.widthInch && cargo.heightInch){
                cargo.volInch = cargo.lengthInch * cargo.widthInch * cargo.heightInch/1728;
                cargo.volInch = cargo.volInch.toFixed(4) -0;
                cargo.vol = DataUtils.round(cargo.volInch/35.3147, 4);
            }
        }
        function imperialVolTtlChange (cargo) {
            if (cargo.volInch){
                cargo.vol = DataUtils.round(vm.wmsGoods.volInch/35.3147, 4);
            }
        }
        $scope.isMixedCargo = function(cargo){
            if (!cargo) {
                return false;
            }
            return cargo.wmsSubPackingLists && cargo.wmsSubPackingLists.length != 0;
        }

        $scope.addMixPl = function(cargo) {
            if(!cargo.wmsSubPackingLists || cargo.wmsSubPackingLists.length == 0){
                cargo.wmsSubPackingLists = [];
                var subPacking = angular.copy(cargo);
                subPacking.id = null;
                subPacking.palletizes = null;
                subPacking.wmsIn = null;
                cargo.wmsSubPackingLists.push(subPacking);
            }
            else {
                cargo.wmsSubPackingLists.push({});
            }
            $translate.refresh();
        };

        $scope.updateWmsSubPackingVol = function(wmsSubPacking) {
            if (wmsSubPacking.length && wmsSubPacking.width && wmsSubPacking.height) {
                wmsSubPacking.vol = wmsSubPacking.length / 100 * wmsSubPacking.width / 100 * wmsSubPacking.height / 100;
                wmsSubPacking.vol = wmsSubPacking.vol.toFixed(4) - 0;
            }
        }

        $scope.deleteMixPl = function(mixPl, cargo) {
            if(vm.wmsIn.isClosed){return;}
            if (mixPl.id && vm.wmsIn.id) {
                var loadIndex = layer.load(1, {shade: 0.3});
                WmsSubPackingList.delete({
                    id: mixPl.id
                }, function(){
                    layer.close(loadIndex);
                    WmsIn.get({id : vm.wmsIn.id}, function(result) {
                        onSaveSuccess(result);
                        amendation();
                    });
                }, function(result){
                    layer.close(loadIndex);
                    layer.msg($translate.instant('global.delete_fail'));
                })
            }
            else {
                var index = cargo.wmsSubPackingLists.indexOf(mixPl);
                if (index > -1) {
                    cargo.wmsSubPackingLists.splice(index, 1);
                }
            }
        }
        $scope.isYuyueEdiFm = function() {
            if ($rootScope.isYuyue(vm.wmsIn.companyId) 
                && vm.wmsIn.thirdpartyPlatform == "auwms") {
                return true;
            }
            return false;
        }


    }
})();
