(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WriteOffController', WriteOffController);

    WriteOffController.$inject = ['$rootScope', '$scope', '$state', '$uibModal', '$timeout',
        'WinAES', 'Principal', 'WriteOff', 'WriteOffSearch', 'ParseLinks', 'AlertService', 'pagingParams',
        'paginationConstants', 'DataUtils', '$translate', 'uiGridConstants', 'DateUtils', 'GridState'
    ];

    function WriteOffController($rootScope, $scope, $state, $uibModal, $timeout,
        WinAES, Principal, WriteOff, WriteOffSearch, ParseLinks, AlertService, pagingParams,
        paginationConstants, DataUtils, $translate, uiGridConstants, DateUtils, GridState) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        }

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = "id";
        vm.direction = 'desc';
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.exportExcel = exportExcel;
        loadAll();
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.searchRequestPop = searchRequestPop;
        var pageLoaded = false;

        var gridCopy;
        var filterTimer;

        function loadAll() {
            if (pagingParams.search) {
                vm.currentSearch = null;
                WriteOffSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                var filter = {};
                if (gridCopy) {
                    angular.forEach(gridCopy.columns, function(column) {
                        angular.forEach(column.filters, function(cf) {
                            if (cf.term != null && angular.isDefined(cf.term)) {
                                if (cf.condition) {
                                    var fd = column.field;
                                    if (fd.endWith("Str")) {
                                        fd = fd.replace("Str", "");
                                    }
                                    if (cf.condition == 128) { //" < ",
                                        if (column.field == "poDate" || column.field == "etd") {
                                            filter[fd + "TO"] = DateUtils.convertLocalDateToServer(cf.term);
                                        } else {
                                            filter[fd + "TO"] = cf.term;
                                        }
                                    } else if (cf.condition == 32) { //" > ",
                                        if (column.field == "poDate" || column.field == "etd") {
                                            filter[fd + "FM"] = DateUtils.convertLocalDateToServer(cf.term);
                                        } else {
                                            filter[fd + "FM"] = cf.term;
                                        }
                                    }
                                } else {
                                    filter[column.field] = cf.term;
                                }
                            }
                        })
                    })
                }
                vm.currentSearch = null;
                WriteOff.getFilterPage({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, filter, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + vm.direction];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                if (headers('X-Total-Count')) {
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                } else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                vm.page = pagingParams.page;
                angular.forEach($scope.gridOptions.data, function(data) {
                    data.encodeId = WinAES.encode(data.id.toString())
                })
                resize();
                updateTotal();
                updateByIsAdvanceSearch();
            }

            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }

        $scope.gridOptions = {
            enableSorting: true, //排序
            useExternalSorting: true,

            enableGridMenu: true, //表格 菜单
            showGridFooter: false, //表格的footer
            showColumnFooter: false, //每列footer

            enableFiltering: true, //高级过滤
            useExternalFiltering: true,

            enableHorizontalScrollbar: 1, //表格的水平滚动条
            enableVerticalScrollbar: 1, //表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv: false,
            exporterMenuPdf: false,
            exporterMenuExcel: false,

            paginationPageSizes: [20, 35, 50],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 70,
            exporterExcelFilename: "Write Off",
            columnDefs: [{
                    field: 'id',
                    width: 150,
                    displayName: "",
                    pinnedLeft: true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    cellTemplate: '<div class="btn-group flex-btn-group-container"><button class="btn btn-grid btn-primary"type=button ui-sref=write-off.edit({id:row.entity.encodeId})><span class="glyphicon glyphicon-eye-open"></span> <span class="hidden-sm hidden-xs"translate=entity.action.view></span></button> <button class="btn btn-grid btn-danger"type=button ng-click=grid.appScope.deleteOne(row.entity) ng-disabled="grid.appScope.HAVENOAUTH(\'AU_ANTI_WRITEOFF\')"><span class="glyphicon glyphicon-fast-backward"></span> <span class="hidden-sm hidden-xs"translate=autopsApp.writeOff.antiWriteOff></span></button></div>'
                }, {
                    field: 'writeOffCode',
                    minWidth: 160,
                    displayName: $translate.instant('autopsApp.writeOff.writeOffCode'),
                    cellTemplate: '<span class="grid-text"><a ui-sref="write-off.edit({id:row.entity.encodeId})">{{row.entity.writeOffCode}}</a></span>'
                }, {
                    field: 'id',
                    displayName: $translate.instant('global.cloudFiles'),
                    width: 80,
                    enableSorting: false,
                    cellTemplate: '<div id="btnsId" class="btn-group flex-btn-group-container" ng-if="row.entity.id"><div class="btn-group" uib-dropdown dropdown-append-to-body><button id="btn-append-to-body" type="button" class="btn btn-link btn-grid" uib-dropdown-toggle ng-click="row.entity.files=[];grid.appScope.LOADFILES(row.entity.files, \'/writeOff/\' + row.entity.id + \'/\', row.entity.companyId)"><span class="glyphicon glyphicon-cloud"></span><span translate="global.cloudFiles">cloudFiles</span><span class="caret"></span></button><ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="btn-append-to-body"><li><a href="" ngf-select ngf-change="grid.appScope.UPLOADFILES($files, \'/writeOff/\' + row.entity.id + \'/\', row.entity.files, row.entity.companyId)"><span class="glyphicon glyphicon-cloud-upload"></span><span class="ml-4" translate="global.uploadFiles">uploadFiles</span></a></li><li role="separator" class="divider" ng-if="row.entity.files.length"></li><li ng-repeat="file in row.entity.files"><a href="" ng-click="grid.appScope.PDFPOP(file.fileurl,file.filename)"><span class="glyphicon glyphicon-cloud-download"></span><span class="ml-4">{{file.filename}}</span></a></span></li><li role="separator" class="divider" ng-if="row.entity.files.length && grid.appScope.HAVEALLAUTH(\'AU_DEL_FILE\', \'ROLE_ACCOUNT\')"></li><li ng-repeat="file in row.entity.files"ng-if="grid.appScope.HAVEALLAUTH(\'AU_DEL_FILE\', \'ROLE_ACCOUNT\')"><a href="" ng-click="grid.appScope.deleOssFile(file, row.entity.files)"><span class="glyphicon glyphicon-remove text-danger"></span><span class="ml-4">{{file.filename}}</span></a></li></ul></div></div>'
                }, {
                    field: 'voucherNum',
                    minWidth: 120,
                    filter: {
                        placeholder: ' == '
                    },
                    displayName: $translate.instant('autopsApp.writeOff.voucherNum'),
                    cellTemplate: '<span class="grid-text"><a href="" ng-click="grid.appScope.VIEWVOUCHER(row.entity.voucherId)">{{row.entity.voucherNum}}</a></span>'
                }, {
                    field: 'writeOffDate',
                    minWidth: 120,
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [{
                            condition: uiGridConstants.filter.GREATER_THAN,
                            placeholder: ' > '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN,
                            placeholder: ' < '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.writeOff.writeOffDate'),
                    cellTemplate: '<span class="grid-text">{{row.entity.writeOffDate | date:\'mediumDate\'}}</span>'
                }, {
                    field: 'isDn',
                    minWidth: 90,
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: [
                            { value: true, label: $translate.instant('autopsApp.writeOff.isDn') },
                            { value: false, label: $translate.instant('autopsApp.writeOff.isCn') }
                        ]
                    },
                    displayName: $translate.instant('autopsApp.writeOff.isDnCn'),
                    cellTemplate: '<span class=grid-text ng-if=row.entity.isDn><span class="label label-success"translate=autopsApp.writeOff.isDn>isDn</span> </span><span class=grid-text ng-hide=row.entity.isDn><span class="label label-info"translate=autopsApp.writeOff.isCn>isCn</span></span>'
                }, {
                    field: 'currency',
                    minWidth: 120,
                    displayName: $translate.instant('autopsApp.writeOff.currency')
                }, {
                    field: 'paymentAmount',
                    displayName: $translate.instant('autopsApp.writeOff.paymentAmount'),
                    minWidth: 100,
                    filters: [{
                            condition: uiGridConstants.filter.GREATER_THAN,
                            placeholder: ' > '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN,
                            placeholder: ' < '
                        }
                    ],
                    cellTemplate: '<span class="info">{{grid.appScope.FORMAT_NUM(row.entity.paymentAmount)}}</span>'
                }, {
                    field: 'accountTitle',
                    minWidth: 100,
                    displayName: $translate.instant('autopsApp.writeOff.accountTitle')
                }, {
                    field: 'payer',
                    minWidth: 100,
                    displayName: $translate.instant('autopsApp.writeOff.payer')
                },
                {
                    field: 'creater',
                    minWidth: 100,
                    displayName: $translate.instant('autopsApp.writeOff.creater')
                },
                {
                    field: 'writeOffTime',
                    type: 'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [{
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.writeOff.writeOffTime'),
                    minWidth: 160,
                    cellTemplate: '<span class="grid-text">{{row.entity.writeOffTime | date:\'medium\'}}</span>'
                },
                {
                    field: 'rateUpdated',
                    displayName: $translate.instant('autopsApp.writeOff.rateUpdated'),
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5" ng-click="grid.appScope.updateOneWriteOff(row.entity)"><span ng-if="row.entity.rateUpdated"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.rateUpdated"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'
                },
                {
                    field: 'salesName',
                    minWidth: 100,
                    displayName: $translate.instant('autopsApp.writeOff.salesName')
                },
                {
                    field: 'branch',
                    minWidth: 100,
                    displayName: $translate.instant('autopsApp.writeOff.branch')
                },
                {
                    field: 'team',
                    minWidth: 100,
                    displayName: $translate.instant('autopsApp.writeOff.team')
                },
                {
                    field: 'audited',
                    displayName: $translate.instant('autopsApp.writeOff.audited'),
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5" ng-click="grid.appScope.setAudited(row.entity)"><span ng-if="row.entity.audited"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.audited"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'
                },
                {
                    field: 'auditor',
                    minWidth: 100,
                    displayName: $translate.instant('autopsApp.writeOff.auditor')
                },
                {
                    field: 'auditTime',
                    type: 'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [{
                            condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                            placeholder: ' >= '
                        },
                        {
                            condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                            placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.writeOff.auditTime'),
                    minWidth: 160,
                    cellTemplate: '<span class="grid-text">{{row.entity.auditTime | date:\'medium\'}}</span>'
                },
                {
                    field: 'remarks',
                    minWidth: 70,
                    displayName: $translate.instant('autopsApp.writeOff.remarks')
                }
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 148;
                    GridState.update(gridStateObj, function(result) {
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error) {
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if (gridStateObj.gridState) {
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "ediInsurance";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "ediInsurance";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function(gridApi) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if (!$scope.gridOptions.useExternalSorting) {
                        if (sortColumns[sortColumns.length - 1]) {
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if (sortColumns[sortColumns.length - 1]) {
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }
                    loadAll();
                });
                gridApi.core.on.filterChanged($scope, function() {
                    gridCopy = this.grid;
                    if (pageLoaded && $scope.gridOptions.useExternalFiltering) {
                        clearTimeout(filterTimer);
                        filterTimer = setTimeout(function() {
                            loadAll();
                        }, 1000); //查询延时时间，单位ms
                    }
                });
                if (!vm.currentSearch) {
                    gridApi.pagination.on.paginationChanged($scope, function(newPage, pageSize) {
                        vm.page = newPage;
                        vm.itemsPerPage = pageSize;
                        if (pageLoaded) {
                            loadAll();
                        }
                    });
                }
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',asc',
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.direction = 'desc';
            vm.currentSearch = searchQuery;
            vm.searchQuery = searchQuery;
            vm.transition();
        }

        function clear() {
            if (vm.currentSearch == "advance") {
                loadAll();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.direction = 'desc';
            vm.currentSearch = null;
            vm.searchQuery = null;
            vm.transition();
            // loadAll();
        }

        window.onresize = function() {
            resize();
        }

        function resize() {
            if (!$("#gridId") || !$("#gridId").offset()) {
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 190;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if (tabHeight > winHeight) {
                tabHeight = winHeight;
            }
            if (tabHeight < 500) {
                tabHeight = 500;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        function searchRequestPop() {
            var searchParams = {
                companyId: $rootScope.account.companyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/write-off-records/write-off-records-dialog.html',
                controller: 'WriteOffRecordsDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(results) {
                vm.currentSearch = "advance";
                $scope.gridOptions.data = results;
                angular.forEach($scope.gridOptions.data, function(data) {
                    data.encodeId = WinAES.encode(data.id.toString())
                })
                $scope.gridOptions.totalItems = $scope.gridOptions.data.length;
                $timeout(function() {
                    resize();
                });
                updateTotal();
                updateByIsAdvanceSearch();
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        function updateByIsAdvanceSearch(searchQuery) {
            if($rootScope.account.companyId != 647 && $rootScope.account.companyId != 54){
                for(var index = $scope.gridOptions.columnDefs.length - 1; index >= 0; index--){
                    var col = $scope.gridOptions.columnDefs[index];
                    if(col.field == "salesName" || col.field == "branch" || col.field == "team"){
                        $scope.gridOptions.columnDefs.splice(index, 1);
                    }
                }
            }
            $scope.gridOptions.useExternalSorting = !vm.currentSearch;
            $scope.gridOptions.useExternalFiltering = !vm.currentSearch;
            $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.OPTIONS);
            $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
        }



        vm.totalList = {};

        function updateTotal() {
            vm.totalList = {};
            if (!vm.currentSearch) {
                return;
            }
            var dnCurs = new Set();
            var cnCurs = new Set();
            angular.forEach($scope.gridOptions.data, function(data) {
                if (data.isDn) {
                    dnCurs.add(data.currency);
                } else {
                    cnCurs.add(data.currency);
                }
            });
            vm.totalList.dnList = [];
            dnCurs.forEach(function(cur) {
                var ttl = 0;
                angular.forEach($scope.gridOptions.data, function(data) {
                    if (data.isDn && data.currency == cur && data.paymentAmount) {
                        ttl += data.paymentAmount;
                    }
                });
                vm.totalList.dnList.push({
                    curency: cur,
                    ttl: DataUtils.round(ttl, 2)
                });
                vm.totalList.haveTtl = true;
            })
            vm.totalList.cnList = [];
            cnCurs.forEach(function(cur) {
                var ttl = 0;
                angular.forEach($scope.gridOptions.data, function(data) {
                    if (!data.isDn && data.currency == cur && data.paymentAmount) {
                        ttl += data.paymentAmount;
                    }
                });
                vm.totalList.cnList.push({
                    curency: cur,
                    ttl: DataUtils.round(ttl, 2)
                });
                vm.totalList.haveTtl = true;
            })
        }




        var isReverse = true;
        $scope.sortRequests = function(title) {
            isReverse = !isReverse;
            $scope.gridOptions.data.sort(function(a, b) {
                if (isReverse) {
                    return a[title] < b[title] ? 1 : -1;
                } else {
                    return a[title] > b[title] ? 1 : -1;
                }
            });
        }
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function() {
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 148
            }, function(result) {
                gridStateObj = result;
                if (!gridStateObj || !gridStateObj.id) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    pageLoaded = true;
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                pageLoaded = true;
                if (gridCopy) {
                    var filterRequired = false;
                    angular.forEach(gridCopy.columns, function(column) {
                        angular.forEach(column.filters, function(cf) {
                            if (cf.term) {
                                filterRequired = true;
                            }
                        })
                    })
                    if (filterRequired) {
                        layer.msg($translate.instant('global.filterDetectedAlert'));
                        loadAll();
                    }
                }
            })
        });

        $scope.deleteOne = function(writeOff) {
            $uibModal.open({
                templateUrl: 'app/entities/write-off/write-off-delete-dialog.html',
                controller: 'WriteOffDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['WriteOff', function(WriteOff) {
                        return WriteOff.get({ id: writeOff.id }).$promise;
                    }]
                }
            }).result.then(function() {
                var index = $scope.gridOptions.data.indexOf(writeOff);
                if (index > -1) {
                    $scope.gridOptions.data.splice(index, 1);
                };
                updateTotal();
            }, function() {

            });
        }

        function exportExcel() {
            if (!$scope.gridOptions.data || $scope.gridOptions.data.length == 0) {
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var fileName = "writeOffs.xlsx";
            WriteOff.exportWriteOffs($scope.gridOptions.data, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }
        $scope.setAudited = function(writeOff) {
            if($rootScope.HAVENOAUTH("ROLE_ACCOUNT") && $rootScope.HAVENOAUTH("ROLE_CASHIER")){
                layer.msg($translate.instant('global.forbiddenAccount'));
                return;
            }
            WriteOff.setAudited({id: writeOff.id}, function(result){
                writeOff.audited = result.audited;
                writeOff.auditor = result.auditor;
                writeOff.auditTime = result.auditTime;
            }, $rootScope.ALERT_ERROR);
        }

        $scope.updateOneWriteOff = function(writeOff) {
            layer.confirm($translate.instant('autopsApp.writeOff.updateOneWriteOffAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                WriteOff.updateOneWriteOff({id: writeOff.id}, function(result){
                    writeOff.rateUpdated = result.rateUpdated;
                }, $rootScope.ALERT_ERROR);
                $translate.refresh();
            }, function() {
                return;
            });
        }





    }
})();
