(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountBindController', AccountBindController);

    AccountBindController.$inject = ['$scope', '$state', '$uibModal', '$timeout', '$rootScope',
    'AccountBind', 'AccountBindSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function AccountBindController ($scope, $state, $uibModal, $timeout, $rootScope,
        AccountBind, AccountBindSearch, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;
        vm.createOne = createOne;
        vm.editOne = editOne;
        vm.delOne = delOne;
        vm.getOrders = getOrders;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                AccountBindSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                AccountBind.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.accountBinds = data;
                vm.page = pagingParams.page;
                angular.forEach(vm.accountBinds, function(data){
                    if(data.autoChannelJson){
                        data.autoChannelJson = angular.fromJson(data.autoChannelJson, true);
                    }
                })
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function createOne () {
            $uibModal.open({
                templateUrl: 'app/entities/account-bind/account-bind-dialog.html',
                controller: 'AccountBindDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: function () {
                        return {};
                    }
                }
            }).result.then(function() {
                loadAll();
            }, function() {
            });
        }

        function editOne (accountBind) {
            $uibModal.open({
                templateUrl: 'app/entities/account-bind/account-bind-dialog.html',
                controller: 'AccountBindDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return AccountBind.get({id : accountBind.id}).$promise;
                    }]
                }
            }).result.then(function() {
                loadAll();
            }, function() {
            });
        }

        function delOne (accountBind) {
            $uibModal.open({
                templateUrl: 'app/entities/account-bind/account-bind-delete-dialog.html',
                controller: 'AccountBindDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return AccountBind.get({id : accountBind.id}).$promise;
                    }]
                }
            }).result.then(function() {
                loadAll();
            }, function() {
            });
        }

        function getOrders (accountBind) {
            if(!accountBind.id){
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/express-cargo/express-cargos.html',
                controller: 'ExpressCargoController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: function () {
                        return AccountBind.get({id : accountBind.id}).$promise;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('stats');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
            }, function(result) {
            });
        }
        $timeout(function (){
            $rootScope.RESIZE();
        });

    }
})();
