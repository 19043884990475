(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('work-table', {
                parent: 'ops',
                url: '/work-table?page&sort&search',
                data: {
                    authorities: ['ROLE_OPERATOR', 'ROLE_DOCUMENT', 'ROLE_BOSS', 'ROLE_BRANCH'],
                    pageTitle: 'global.menu.entities.workTable'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/work-table/work-table-home.html',
                        controller: 'WorkTableHomeController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'bookingTime,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            })
            .state('fba-biz', {
                parent: 'ops',
                url: '/fba?page&sort&search',
                data: {
                    authorities: ['ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_SALES', 'ROLE_DOCUMENT', 'ROLE_CS', 'ROLE_MARKET'],
                    pageTitle: 'global.menu.entities.fba'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/guide/fba.html',
                        controller: 'FbaController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    customNode: null,
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            })     
            .state('sea-export', {
                parent: 'ops',
                url: '/sea-export?page&sort&search',
                data: {
                    authorities: ['ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_SALES', 'ROLE_DOCUMENT', 'ROLE_CS', 'ROLE_MARKET'],
                    pageTitle: 'global.menu.entities.seaExport'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/guide/sea.html',
                        controller: 'SeaExportController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    customNode: null,
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            })     
            .state('air-export', {
                parent: 'ops',
                url: '/air-export?page&sort&search',
                data: {
                    authorities: ['ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_SALES', 'ROLE_DOCUMENT', 'ROLE_CS', 'ROLE_MARKET'],
                    pageTitle: 'global.menu.entities.airExport'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/guide/air.html',
                        controller: 'AirExportController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    customNode: null,
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            })     
            .state('all-export', {
                parent: 'ops',
                url: '/all-export',
                data: {
                    authorities: ['ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_SALES', 'ROLE_DOCUMENT', 'ROLE_CS', 'ROLE_MARKET'],
                    pageTitle: 'global.menu.entities.allExport'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/guide/all.html',
                        controller: 'AllExportController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    customNode: null,
                    oaRefNum: null
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            })     
            .state('wms-local', {
                parent: 'ops',
                url: '/wms-local?page&sort&search',
                data: {
                    authorities: ['ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_SALES', 'ROLE_DOCUMENT', 'ROLE_CS', 'ROLE_MARKET'],
                    pageTitle: 'global.menu.entities.wms-local'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/guide/fba.html',
                        controller: 'FbaController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            })
            .state('guide-virtual', {
                parent: 'ops',
                url: '/shipments-virtual?page&sort&search',
                data: {
                    authorities: ['ROLE_OPERATOR', 'ROLE_SALES', 'ROLE_DOCUMENT', 'ROLE_CS'],
                    pageTitle: 'global.menu.entities.virtualWms'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/guide/guide-virtual.html',
                        controller: 'guideVirtualController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            })
            .state('loading-distribution', {
                parent: 'ops',
                url: '/loading-distribution',
                data: {
                    authorities: ['ROLE_DOCUMENT', 'ROLE_OPERATOR'],
                    pageTitle: 'global.menu.entities.loadingDistribution'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/guide/loading-distribution.html',
                        controller: 'LoadingDistributionController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            })
            .state('guide', {
                abstract: true,
                parent: 'ops',
            })    
            .state('fba-biz.detail', {
                parent: 'fba-biz',
                url: '/dialog/{guideId}',
                data: {
                    authorities: ['ROLE_SALES', 'ROLE_CS', 'ROLE_MARKET', 'ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_DOCUMENT', 'ROLE_BOSS', 'ROLE_BRANCH']
                },
                onEnter: ['$stateParams', 'WinAES', '$rootScope', function($stateParams, WinAES, $rootScope) {
                    if($rootScope.ROLE_BIZPOP){
                        $rootScope.ROLE_BIZPOP(WinAES.decode($stateParams.guideId));
                    }
                    else {
                        setTimeout(function() {
                            $rootScope.ROLE_BIZPOP(WinAES.decode($stateParams.guideId));
                        }, 1000);
                    }
                }]
            })
            .state('wms-local.detail', {
                parent: 'wms-local',
                url: '/dialog/{guideId}',
                data: {
                    authorities: ['ROLE_SALES', 'ROLE_CS', 'ROLE_MARKET', 'ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_DOCUMENT', 'ROLE_BOSS', 'ROLE_BRANCH']
                },
                onEnter: ['$stateParams', 'WinAES', '$rootScope', function($stateParams, WinAES, $rootScope) {
                    if($rootScope.ROLE_BIZPOP){
                        $rootScope.ROLE_BIZPOP(WinAES.decode($stateParams.guideId));
                    }
                    else {
                        setTimeout(function() {
                            $rootScope.ROLE_BIZPOP(WinAES.decode($stateParams.guideId));
                        }, 1000);
                    }
                }]
            })
            .state('air-export.detail', {
                parent: 'air-export',
                url: '/dialog/{guideId}',
                data: {
                    authorities: ['ROLE_SALES', 'ROLE_CS', 'ROLE_MARKET', 'ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_DOCUMENT', 'ROLE_BOSS', 'ROLE_BRANCH']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/guide/air-dialog.html',
                        controller: 'AirDialogController',
                        backdrop: false,
                        size: 'xxxl',
                        resolve: {
                            entity: ['WinAES', 'Guide', function(WinAES, Guide) {
                                return Guide.get({id : WinAES.decode($stateParams.guideId)}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('guide');
                                $translatePartialLoader.addPart('booking');
                                $translatePartialLoader.addPart('wmsIn');
                                $translatePartialLoader.addPart('expressParcel');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^');
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('sea-export.detail', {
                parent: 'sea-export',
                url: '/dialog/{guideId}',
                data: {
                    authorities: ['ROLE_SALES', 'ROLE_CS', 'ROLE_MARKET', 'ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_DOCUMENT', 'ROLE_BOSS', 'ROLE_BRANCH']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/guide/sea-dialog.html',
                        controller: 'SeaDialogController',
                        backdrop: false,
                        size: 'xxxl',
                        resolve: {
                            entity: ['WinAES', 'Guide', function(WinAES, Guide) {
                                return Guide.get({id : WinAES.decode($stateParams.guideId)}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('guide');
                                $translatePartialLoader.addPart('booking');
                                $translatePartialLoader.addPart('wmsIn');
                                $translatePartialLoader.addPart('expressParcel');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^');
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('all-export.sea', {
                parent: 'all-export',
                url: '/sea-dialog/{guideId}',
                data: {
                    authorities: ['ROLE_SALES', 'ROLE_CS', 'ROLE_MARKET', 'ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_DOCUMENT', 'ROLE_BOSS', 'ROLE_BRANCH']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/guide/sea-dialog.html',
                        controller: 'SeaDialogController',
                        backdrop: false,
                        size: 'xxxl',
                        resolve: {
                            entity: ['WinAES', 'Guide', function(WinAES, Guide) {
                                return Guide.get({id : WinAES.decode($stateParams.guideId)}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('guide');
                                $translatePartialLoader.addPart('booking');
                                $translatePartialLoader.addPart('wmsIn');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^');
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('all-export.air', {
                parent: 'all-export',
                url: '/air-dialog/{guideId}',
                data: {
                    authorities: ['ROLE_SALES', 'ROLE_CS', 'ROLE_MARKET', 'ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_DOCUMENT', 'ROLE_BOSS', 'ROLE_BRANCH']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/guide/air-dialog.html',
                        controller: 'AirDialogController',
                        backdrop: false,
                        size: 'xxxl',
                        resolve: {
                            entity: ['WinAES', 'Guide', function(WinAES, Guide) {
                                return Guide.get({id : WinAES.decode($stateParams.guideId)}).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('guide');
                                $translatePartialLoader.addPart('booking');
                                $translatePartialLoader.addPart('wmsIn');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function() {
                        $state.go('^');
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('all-export.fba', {
                parent: 'all-export',
                url: '/fba-dialog/{guideId}',
                data: {
                    authorities: ['ROLE_SALES', 'ROLE_CS', 'ROLE_MARKET', 'ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_DOCUMENT', 'ROLE_BOSS', 'ROLE_BRANCH']
                },
                onEnter: ['$stateParams', 'WinAES', '$rootScope', function($stateParams, WinAES, $rootScope) {
                    if($rootScope.ROLE_BIZPOP){
                        $rootScope.ROLE_BIZPOP(WinAES.decode($stateParams.guideId));
                    }
                    else {
                        setTimeout(function() {
                            $rootScope.ROLE_BIZPOP(WinAES.decode($stateParams.guideId));
                        }, 1000);
                    }
                }]
            })
            .state('all-export.wms', {
                parent: 'all-export',
                url: '/wms-dialog/{guideId}',
                data: {
                    authorities: ['ROLE_SALES', 'ROLE_CS', 'ROLE_MARKET', 'ROLE_ACCOUNT', 'ROLE_OPERATOR', 'ROLE_DOCUMENT', 'ROLE_BOSS', 'ROLE_BRANCH']
                },
                onEnter: ['$stateParams', 'WinAES', '$rootScope', function($stateParams, WinAES, $rootScope) {
                    if($rootScope.ROLE_BIZPOP){
                        $rootScope.ROLE_BIZPOP(WinAES.decode($stateParams.guideId));
                    }
                    else {
                        setTimeout(function() {
                            $rootScope.ROLE_BIZPOP(WinAES.decode($stateParams.guideId));
                        }, 1000);
                    }
                }]
            });
    }
})();
