(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('LoadingDistributionController', LoadingDistributionController);

    LoadingDistributionController.$inject = ['$rootScope', '$scope', '$state', '$translate', '$uibModal', '$timeout', 
    'Principal', 'DataUtils', 'Guide', 'ExpressParcel', 'GuideSearch', 'OpsFiles', 'uiGridConstants', 'GridState', 'OpsReport'];
    
    function LoadingDistributionController($rootScope, $scope, $state, $translate, $uibModal, $timeout, 
        Principal, DataUtils, Guide, ExpressParcel, GuideSearch, OpsFiles, uiGridConstants, GridState, OpsReport) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;     
                getUnshippedMbls();           
            });
        }
        else {
            getUnshippedMbls();
        }
        $scope.searchStr = "";
        $scope.virtualWms = false;
        var isExportCombine = true;
        $scope.searchType = null;

        $scope.setSearchType = function(type) {
            $scope.searchType = type;
        }

        $scope.searchStr = "";
        $scope.searchGuide = function() { 
            if($rootScope.HAVENOAUTH('FMS') && $rootScope.HAVENOAUTH('FBA') && $rootScope.HAVENOAUTH('WMS')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            if($scope.searchType){
                Guide.searchByNums({
                    jobType: "consol",
                    type: $scope.searchType,
                    isFms: $scope.isFms,
                    shipmentType: "all",
                    companyId: $scope.choosedCompanyId
                }, $scope.searchStr, function(result, headers) {
                    $rootScope.ALERT_RESULT_MSG(headers);
                    addToHGuides(result);
                }, function(response) {
                });
                return;
            }
            $scope.searchStr = $scope.searchStr.replace(/\s+/g,"");
            if (!$scope.searchStr){
                return;
            }
            Guide.searchGuide({
                isExport: $scope.isExportCombine,
                isFms: $scope.isFms,
                searchType: $scope.searchType,
                jobType: "consol",
                virtualWms: $scope.virtualWms,
                companyId: $scope.choosedCompanyId,
                inputStr: $scope.searchStr
            }, function(result) {
                addToHGuides(result);
            }, function(response) {
            });    
        };

        $scope.searchParcel = function() { 
            if($rootScope.HAVENOAUTH('PARCEL') && $rootScope.HAVENOAUTH('TMS')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            if($scope.searchType){
                ExpressParcel.searchByNums({
                    isFms: $scope.isFms,
                    type: $scope.searchType,
                    shipmentType: "ALL",
                    searchType: "consol",
                    companyId: $scope.choosedCompanyId
                }, $scope.searchStr, function(result, headers) {
                    $rootScope.ALERT_RESULT_MSG(headers);
                    addToHGuides(result);
                }, function(response) {
                });
                return;
            }
            $scope.searchStr = $scope.searchStr.replace(/\s+/g,"");
            if (!$scope.searchStr){
                return;
            }
            ExpressParcel.searchByNum({
                isFms: $scope.isFms,
                type: "consol",
                searchType: $scope.searchType,
                companyId: $scope.choosedCompanyId,
                inputStr: $scope.searchStr,
                isTms: $scope.isTms
            }, function(result) {
                addToHGuides(result);
                $scope.searchStr = null;
            }, function(response) {
            });  

        }
        var addToGuides = function(guide) {
            var isRepeated = false;
            for(var j = $scope.gridOptions_1.data.length -1; j >=0; j--){
                if (guide.id == $scope.gridOptions_1.data[j].id && guide.shipmentType == $scope.gridOptions_1.data[j].shipmentType){
                    isRepeated = true;
                    break;
                }
            }
            if (!isRepeated){
                DataUtils.fillFdLabel(guide);
                $scope.gridOptions_1.data.push(guide);
            }         
        }

        function addToHGuides (result) {
            if (!result || result.length == 0){
                layer.msg($translate.instant('global.messages.resultNull'));
            }
            else {
                angular.forEach(result, function(data){
                    if(data.wmsOutTime && $scope.isFms){
                        layer.msg(data.jobNum + ": " +$translate.instant('autopsApp.guide.sts.wmsOut'));                           
                    }
                    else if(data.isClosed){
                        layer.msg(data.jobNum + ": " +$translate.instant('autopsApp.guide.sts.isClosed'));                           
                    }
                    else if ($scope.virtualWms && data.medCompanyId != $rootScope.account.companyId){

                    }
                    else {
                        addToGuides(data);
                    }                     
                })
            }
            $scope.searchStr = null;
        };

        var addToMGuides = function(guide) {
            var isRepeated = false;
            for(var j = $scope.gridOptions_3.data.length -1; j >=0; j--){
                if (guide.id == $scope.gridOptions_3.data[j].id){
                    isRepeated = true;
                    break;
                }
            }
            if (!isRepeated){
                DataUtils.fillFdLabel(guide);
                $scope.gridOptions_3.data.push(guide);
            }         
        }
        $scope.delFromGuides = function(guide) {
            var index = $scope.gridOptions_1.data.indexOf(guide);
            if (index > -1) {
                $scope.gridOptions_1.data.splice(index, 1);
            };
        }

        $scope.searchParcelPop = function(type, shipmentType) {  
            if($rootScope.HAVENOAUTH('TMS') && shipmentType == "TMS"){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            if($rootScope.HAVENOAUTH('PARCEL') && shipmentType == "PARCEL"){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            var searchParams = {
                shipmentType: shipmentType,
                forConsol: "forCombine" == type,
                isFmsConsol: $scope.isFms,
                masterOnly: "masterOnly" == type,                
                companyId: $scope.choosedCompanyId == null? $rootScope.account.companyId: $scope.choosedCompanyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/express-parcel/express-parcel-detail.html',
                controller: 'ExpressParcelDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(result) {
                addAllToGuides(result, type);
            }, function() {
            });
        }


        $scope.searchGuidePop = function(type, isExport) {            
            if($rootScope.HAVENOAUTH('FMS') && $rootScope.HAVENOAUTH('FBA') && $rootScope.HAVENOAUTH('WMS')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            var searchParams = {
                isExport: "masterOnly" == type?isExport:isExportCombine,
                forConsol: "forCombine" == type,
                isFmsConsol: $scope.isFms,
                masterOnly: "masterOnly" == type,
                companyId: $scope.choosedCompanyId == null? $rootScope.account.companyId: $scope.choosedCompanyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/guide/guide-search-dialog.html',
                controller: 'GuideSearchDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(result) {
                addAllToGuides(result, type);
            }, function() {
            });
        }


        function addAllToGuides(result, type){
            if (!result || result.length == 0){
                layer.msg($translate.instant('global.messages.resultNull'));
            }
            else {
                if("forCombine" == type){
                    addToHGuides(result);
                }
                else {
                    angular.forEach(result, function(data){
                        addToMGuides(data);
                    })
                    utilizationUpdate();
                }
            }
        }

        $scope.exportConsolPackingList = function(guides) {
            if(!guides || guides.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            layer.load(1, {shade: 0.3});
            var fileName = $translate.instant('autopsApp.guide.packingList') + ".xlsx";           
            OpsFiles.exportConsolPackingList({
                enCn: $translate.instant('global.enCn')
            }, guides, function(result) {
                layer.closeAll(); 
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }  

        function getUnshippedMbls () {
            // layer.msg($translate.instant('autopsApp.guide.getUnshippedMbls')); 
            ExpressParcel.getUnshippedMbls({
                companyId: $scope.choosedCompanyId == null? $rootScope.account.companyId: $scope.choosedCompanyId
            }, function(result) {
                $scope.gridOptions_3.data = result;
                utilizationUpdate();
            }, function(response) {
            });  
        }
        function getUnshippedHbls (guide) {                
            ExpressParcel.getUnshippedHbls(guide, function(result) {
                layer.msg($translate.instant('autopsApp.guide.getUnshippedHbls'));  
                $scope.gridOptions_1.data = result;
                fillFdLabel($scope.gridOptions_1.data);
            }, function(response) {
            });  
        }
        function fillFdLabel(shipments) {
            angular.forEach(shipments, function(data){
                DataUtils.fillFdLabel(data);
            })
        }

        $scope.clearUnshippedHbls = function() { 
            $scope.gridOptions_1.data = [];
        }
        
        $scope.clearUnshippedMbls = function() { 
            $scope.gridOptions_3.data = [];
        }

        function utilizationUpdate () {
            angular.forEach($scope.gridOptions_3.data, function(data){
                DataUtils.fillFdLabel(data);
                if(data.shipmentType == "FCL" && data.volumes && data.vol){
                    var volumes = data.volumes.split(' ');
                    var ttl = 0;
                    angular.forEach(volumes, function(volume){
                        if (volume){
                            var teus = volume.split('X');
                            if(teus.length == 2){
                                if (teus[1].substring(0,2) == "20"){
                                    ttl = ttl + teus[0] * 33;
                                }
                                else if (teus[1].substring(0,2) == "45"){
                                    ttl = ttl + teus[0] * 85.7;
                                }
                                else if (teus[1] == "40HQ"){
                                    ttl = ttl + teus[0] * 76;
                                }
                                else if (teus[1].substring(0,2) == "40"){
                                    ttl = ttl + teus[0] * 67.3;
                                }
                            }
                        }
                    })
                    if(ttl){
                        var utilization = data.vol/ttl*100;                    
                        data.utilization = utilization.toFixed(2)-0;
                    }
                }
            })
        }

        $scope.choosedId = null;
        $scope.isFms = true;
        $scope.choosedCompanyId = $rootScope.account.companyId;
        function chooseMbl(guide) {
            $scope.choosedId = guide.id;
            isExportCombine = guide.isExport;
            $scope.choosedCompanyId = guide.companyId;
            $scope.isFms = guide.guideId != null;

            if ($scope.choosedCompanyId == $rootScope.account.companyId){
                $scope.virtualWms = false;
            }
            else {
                $scope.virtualWms = true;
            }
            getHouseGuides();
            getUnshippedHbls(guide);
        }
        function getHouseGuides() {
            if(!$scope.choosedId){
                return;
            }            
            var loadIndex = layer.load(1, {shade: 0.3});
            ExpressParcel.getConsoleShipments({
                companyId: $rootScope.account.companyId,
                masterId: $scope.choosedId,
                isFms: $scope.isFms
            }, function (resultList) {
                layer.close(loadIndex);  
                $scope.gridOptions_2.data = [];
                angular.forEach(resultList, function(data){
                    if(($scope.isFms && data.guideId == $scope.choosedId) 
                        || (!$scope.isFms && data.parcelId == $scope.choosedId)){
                        angular.forEach($scope.gridOptions_3.data, function(master){
                            if(master.id == data.id){
                                angular.copy(data, master);
                                utilizationUpdate();
                            }
                        })
                    }
                    else {
                        DataUtils.fillFdLabel(data);
                        $scope.gridOptions_2.data.push(data);
                    }
                })
            })
        }
        $scope.delFromList = function(guide) {
            if(guide.isClosed){
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            layer.confirm($translate.instant('autopsApp.guide.removeFromConsolAlert'), {
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')], icon: 3 //按钮
                }, function(index) {
                    ExpressParcel.removeFromConsol({
                        houseId:guide.id,
                        isFms: guide.guideId != null,
                        mIsFms: $scope.isFms
                    }, function(result){
                        getHouseGuides();
                        var isRepeated = false;
                        for(var j = $scope.gridOptions_1.data.length -1; j >=0; j--){
                            if (guide.id == $scope.gridOptions_1.data[j].id && guide.shipmentType == $scope.gridOptions_1.data[j].shipmentType){
                                isRepeated = true;
                                break;
                            }
                        }
                        if (!isRepeated){
                            if(guide.jobNum.indexOf("H-") === 0){
                                guide.jobNum = guide.jobNum.substring(2);
                            }
                            DataUtils.fillFdLabel(guide);
                            $scope.gridOptions_1.data.push(guide);
                        }
                        layer.msg($translate.instant('global.delete_success'));
                    }, function(result){
                        if(result.status == 403){
                            layer.msg($translate.instant('global.lockedAlert'));
                        }
                        else if(result.status == 404){                            
                            layer.msg($translate.instant('global.messages.resultNull'));
                        }
                        else if(result.status == 423){                            
                            layer.msg($translate.instant('autopsApp.guide.consolidatedAlert'));
                        }
                        else{
                            layer.msg($translate.instant('global.delete_fail'));
                        }
                    })
                    layer.close(index);
                }, function() {
            });
        }

        $scope.addToList = function(guide) {
            if(guide.isClosed){
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if(guide.isVoid){
                layer.msg($translate.instant('autopsApp.guide.home.voidedAlert'));
                return;
            }
            ExpressParcel.addToConsol({
                masterId: $scope.choosedId,
                isFms: $scope.isFms
            }, guide, function(result){
                getHouseGuides();
                var index = $scope.gridOptions_1.data.indexOf(guide);
                if (index > -1) {
                    $scope.gridOptions_1.data.splice(index, 1);
                };
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(result){
                if(result.status == 423){
                    layer.msg($translate.instant('autopsApp.guide.consolidatedAlert'));
                }
                else if(result.status == 403){
                    layer.msg($translate.instant('global.lockedAlert'));
                }
                else if(result.status == 410){
                    layer.msg($translate.instant('autopsApp.guide.home.voidedAlert'));
                }
                else if(result.status == 409){
                    layer.msg($translate.instant('autopsApp.guide.home.combineConflict'));
                }
                else if(result.status == 404){
                    layer.msg($translate.instant('global.noRecord'));
                }
                else{
                    layer.msg($translate.instant('global.requestFail'));
                }
            })
        }

        $scope.addListToConsol = function() {
            if(!$scope.gridOptions_1.data || $scope.gridOptions_1.data.length == 0){
                return;
            }
            layer.load(1, {shade: 0.3});
            ExpressParcel.addListToConsol({
                masterId: $scope.choosedId,
                isFms: $scope.isFms
            }, $scope.gridOptions_1.data, function(result){
                layer.closeAll(); 
                getHouseGuides();
                $scope.gridOptions_1.data = [];
                layer.msg($translate.instant('global.importSuccessAmt', {param: result.success}));
            }, function(result){
                layer.closeAll(); 
                layer.msg($translate.instant('global.requestFail'));
            })
        }

        $rootScope.LOAD_REPORT_TEMPLATES();
        $scope.setConsolidated = function(guide) {
            if(guide.consolidated && $rootScope.account.authorities.indexOf('AU_CONSOLIDATE_UNLOCK') === -1){
                $rootScope.FORBIDDEN_BY_ALERT('AU_CONSOLIDATE_UNLOCK'); 
                return;
            }
            if (guide.isClosed) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.lockedAlert'));
                return;
            }
            ExpressParcel.setConsolidated({
                id: guide.id,
                isFms: guide.guideId != null
            }, function(result) {
                guide.consolidated = result.consolidated;
                layer.msg($translate.instant('global.save_success'));
            }, function(result) {
                layer.msg($translate.instant('global.save_fail'));
            })
        }
        $scope.addToConsolByNum = function(isFms) {
            layer.prompt({
                formType: 0,
                value: '',
                title: $translate.instant('autopsApp.guide.inputJobPoNum')
            }, function(value, index, elem) {
                layer.close(index);  
                if($scope.isFms){
                    ExpressParcel.addToGuideConsol({
                        companyId: $rootScope.account.companyId,
                        masterId: $scope.choosedId,
                        jobNum: value,
                        isVirtual: $scope.isVirtual,
                        isFms: isFms
                    }, onAddSuccess, $rootScope.ALERT_ERROR)
                }
                else {
                    ExpressParcel.addToParcelConsol({
                        companyId: $rootScope.account.companyId,
                        masterId: $scope.choosedId,
                        jobNum: value,
                        isVirtual: $scope.isVirtual,
                        isFms: isFms
                    }, onAddSuccess, $rootScope.ALERT_ERROR)
                }
            });
        }
        function onAddSuccess (result) {            
            getHouseGuides();
            layer.msg($translate.instant('global.importSuccess'));
        }

        $scope.gridOptions_1 = {
            enableSorting: true,//排序
            useExternalSorting: false,      

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤            

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: 'unchoosed.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'], 
            minimumColumnSize: 80,
            columnDefs: [
                { field: 'id', 
                    width: 50,
                    displayName: "", 
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    cellTemplate: '<button type="button"ng-click="grid.appScope.addToList(row.entity)"class="btn btn-danger btn-grid ml-5"><span class="glyphicon glyphicon-plus"></span></button>'},
                { field: 'jobNum', 
                    displayName: 'Job#',
                    width: 150,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<a href=""ng-click="grid.appScope.BIZPOP(row.entity.guideId)"class="ml-5"ng-if="row.entity.guideId">{{row.entity.jobNum}}</a><a href=""ng-click="grid.appScope.VIEWEXPRESS(row.entity.parcelId)"class="ml-5"ng-if="row.entity.parcelId">{{row.entity.jobNum}}</a><a href=""class="ml-5"ng-click="grid.appScope.delFromGuides(row.entity)"><span class="glyphicon glyphicon-remove"></span></a><span class="glyphicon glyphicon-ok-circle ml-5 text-info"ng-if="row.entity.checkStatus == \'approved\'"data-toggle="tooltip"data-placement="top"title="{{\'autopsApp.guide.sts.approved\' | translate}}"></span><span class="glyphicon glyphicon-lock ml-5 text-danger"ng-if="row.entity.isClosed || row.entity.consolidated"data-toggle="tooltip"data-placement="top"title="{{row.entity.isClosed?\'Locked\':\'\'}} {{row.entity.consolidated?\'Consolidated\':\'\'}}"></span>'},
                { field: 'checkStatus', 
                    displayName: $translate.instant('autopsApp.guide.checkStatus'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CHECK_STATUS
                    },
                    cellTemplate: '<span class="grid-text" ng-if="row.entity.checkStatus"><span class="label label-info" ng-if="row.entity.checkStatus == \'notChecked\'" translate="autopsApp.guide.sts.notChecked">status</span><span class="label label-success" ng-if="row.entity.checkStatus == \'approved\'" translate="autopsApp.guide.sts.approved">status</span><span class="label label-danger" ng-if="row.entity.checkStatus == \'unapproved\'" translate="autopsApp.guide.sts.unapproved">status</span></span>'},
                { field: 'onHold', 
                    displayName: $translate.instant('autopsApp.guide.onHold'), 
                    width: 50,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5" ng-click="grid.appScope.consoleOnHoldShipmentPop(row.entity)"><span ng-if="row.entity.onHold"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.onHold"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'soNum', 
                    displayName: 'SO#',
                    visible: false},
                { field: 'poNum', 
                    displayName: 'PO#',
                    visible: false},
                { field: 'mblNum',
                    visible: false,
                    cellTemplate: '<span class="ml-5">{{row.entity.mblNum}}<span class="glyphicon glyphicon-ok-circle ml-5 text-primary"data-toggle="tooltip"data-placement="top"title="MBL released: {{row.entity.mblReleasedTime | date:\'medium\'}}"ng-if="row.entity.mblReleasedTime"></span></span>',
                    displayName: 'MBL/Mawb#'},
                { field: 'hblNum',
                    visible: false,
                    cellTemplate: '<span class="ml-5">{{row.entity.hblNum}}<span class="glyphicon glyphicon-ok-circle ml-5 text-primary"data-toggle="tooltip"data-placement="top"title="HBL released: {{row.entity.hblReleasedTime | date:\'medium\'}}"ng-if="row.entity.hblReleasedTime"></span></span>',
                    displayName: 'HBL/Hawb#'},
                { field: 'refNum', 
                    displayName: 'EDI Ref#',
                    visible: false},
                { field: 'thirdpartyPlatformRef', 
                    displayName: $translate.instant('autopsApp.guide.thirdpartyPlatformRef'),
                    visible: false},
                { field: 'clientSoNum', 
                    displayName: $translate.instant('autopsApp.guide.clientWmsInNum'),
                    visible: false},
                { field: 'gw', 
                    width: 70,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'GW', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'vol', 
                    width: 70,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'VOL', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'pkgNum', 
                    width: 70,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'PKG', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'gwForecast', 
                    width: 70,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'GW(E)', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'volForecast', 
                    width: 70,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'VOL(E)', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'pkgNumForecast', 
                    width: 70,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'PKG(E)', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'pallets', 
                    width: 100,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'PLT', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum}, 
                { field: 'shipmentType', 
                    displayName: 'TYPE',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.FMS_TYPES
                    },  
                    cellTemplate: '<span ng-if="row.entity.shipmentType" class="label label-info ml-5" translate="global.{{row.entity.shipmentType}}"></span>'},
                { field: 'deliveryTo', 
                    displayName: $translate.instant('autopsApp.guide.deliveryTo')},
                { field: 'shiptoCode', 
                    displayName: $translate.instant('autopsApp.guide.shipToParty')},                    
                { field: 'destName', 
                    displayName: $translate.instant('autopsApp.guide.destName')},
                { field: 'shiptoCountryCode', 
                    displayName: $translate.instant('autopsApp.guide.destCountryCode')}, 
                { field: 'shiptoZipCode', 
                    displayName: $translate.instant('autopsApp.guide.destPostCode')}, 
                { field: 'polName', 
                    displayName: $translate.instant('autopsApp.guide.polName')}, 
                { field: 'warehouseName', 
                    displayName: $translate.instant('autopsApp.guide.warehouse')}, 
                { field: 'cargoType', 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CARGOTYPES
                    },  
                    displayName: $translate.instant('autopsApp.guide.cargoType'),
                    cellTemplate: '<span class="label label-info ml-5">{{row.entity.cargoTypeLabel}}</span>'},
                { field: 'createdTime', 
                    displayName: $translate.instant('autopsApp.guide.home.createdTime'),
                    cellTemplate: '<span data-toggle="tooltip" data-placement="top" title="{{row.entity.createdTime | date:\'medium\'}}" class="ml-5">{{row.entity.createdTime | date:\'mediumDate\'}}</span>'},
                { field: 'cargoReadyDate', 
                    displayName: $translate.instant('autopsApp.guide.cargoReadyDate')},
                { field: 'bookingEtd', 
                    displayName: $translate.instant('autopsApp.guide.bookingEtd')},
                { field: 'wmsInTime', 
                    displayName: $translate.instant('autopsApp.guide.wmsInTime'),
                    cellTemplate: '<span data-toggle="tooltip" data-placement="top" title="{{row.entity.wmsInTime | date:\'medium\'}}" class="ml-5">{{row.entity.wmsInTime | date:\'mediumDate\'}}</span>'},
                { field: 'etd', 
                    displayName: 'ETD'},
                { field: 'customerName', 
                    displayName: $translate.instant('autopsApp.guide.bookingPartyName')},
                { field: 'shipperName', 
                    displayName: $translate.instant('autopsApp.guide.shipperName')},
                { field: 'productName', 
                    displayName: $translate.instant('autopsApp.guide.productName')},
                { field: 'deliveryChannel', 
                    displayName: $translate.instant('autopsApp.guide.deliveryChannel')},
                { field: 'salesName', 
                    displayName: $translate.instant('autopsApp.guide.salesName')},
                { field: 'opName', 
                    displayName: $translate.instant('autopsApp.guide.opName')},
                { field: 'docName', 
                    displayName: $translate.instant('autopsApp.guide.docName')},
                { field: 'cusName', 
                    displayName: $translate.instant('autopsApp.guide.cusName')},
                { field: 'vsl', 
                    displayName: 'Vessel'},
                { field: 'voy', 
                    displayName: 'Voy/Flight'},
                { field: 'porName', 
                    displayName: $translate.instant('autopsApp.guide.porName')},
                { field: 'memo', 
                    displayName: $translate.instant('autopsApp.guide.table.memo')},
                { field: 'cargoName', 
                    displayName: $translate.instant('autopsApp.guide.cargoName')},
                { field: 'innerRemark', 
                    displayName: $translate.instant('autopsApp.guide.remarks')},
                { field: 'bookingRemark', 
                    displayName: $translate.instant('autopsApp.booking.remarks')},
                { field: 'ownImporter', 
                    displayName: $translate.instant('autopsApp.guide.ownImporter'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.ownImporter"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.ownImporter"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'plConfirmed', 
                    displayName: $translate.instant('autopsApp.booking.plConfirmed'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.plConfirmed"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.plConfirmed"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'customsType', 
                    displayName: $translate.instant('autopsApp.guide.customsType')},
                { field: 'bizType',
                    displayName: $translate.instant('autopsApp.guide.bizType'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.BIZTYPES
                    },
                    cellTemplate: '<span class="grid-text">{{row.entity.bizTypeLabel}}</span>'},
                { field: 'internalType',
                    displayName: $translate.instant('autopsApp.guide.bizInternalType'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.INTERNALTYPES
                    },
                    cellTemplate: '<span class="grid-text">{{row.entity.internalTypeLabel}}</span>'},
                { field: 'deliveryWay', 
                    displayName: $translate.instant('autopsApp.booking.deliveryWay'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.DELIVERYTYPES
                    },       
                    minWidth:100,
                    cellTemplate: '<span class="ml-5" translate="autopsApp.booking.deliveryType.{{row.entity.deliveryWay}}" ng-if="row.entity.deliveryWay" class="label-info label"></span>'}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj_1.gridState = angular.toJson($scope.gridApi_1.saveState.save());
                    gridStateObj_1.userId = $rootScope.account.id;
                    gridStateObj_1.gridId = 99;
                    GridState.update(gridStateObj_1, function(result){
                        gridStateObj_1 = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj_1.gridState){
                        $scope.gridApi_1.saveState.restore($scope, angular.fromJson(gridStateObj_1.gridState));
                    }       
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi_1.saveState.restore($scope, gridStateCopy_1);                   
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions_1);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi_1.core.getVisibleRows($scope.gridApi_1.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi_1.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "consolidatedList";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions_1.exporterExcelFilename);                   
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions_1;
                    jsonObj.saveState = $scope.gridApi_1.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "consolidatedList";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions_1.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi_1 = gridApi;
            }
        }

        $scope.gridOptions_2 = {
            enableSorting: true,//排序
            useExternalSorting: false,      

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤            

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: 'unchoosed.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'], 
            minimumColumnSize: 80,
            columnDefs: [
                { field: 'id', 
                    width: 50,
                    displayName: "", 
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    cellTemplate: '<button type="button"ng-click="grid.appScope.delFromList(row.entity)"class="btn btn-danger btn-grid ml-5"><span class="glyphicon glyphicon-remove"></span></button>'},
                { field: 'jobNum', 
                    displayName: 'Job#',
                    width: 150,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<a href=""ng-click="grid.appScope.BIZPOP(row.entity.guideId)"class="ml-5"ng-if="row.entity.guideId">{{row.entity.jobNum}}</a><a href=""ng-click="grid.appScope.VIEWEXPRESS(row.entity.parcelId)"class="ml-5"ng-if="row.entity.parcelId">{{row.entity.jobNum}}</a><span class="glyphicon glyphicon-ok-circle ml-5 text-info"ng-if="row.entity.checkStatus == \'approved\'"data-toggle="tooltip"data-placement="top"title="{{\'autopsApp.guide.sts.approved\' | translate}}"></span><span class="glyphicon glyphicon-lock ml-5 text-danger"ng-if="row.entity.isClosed || row.entity.consolidated"data-toggle="tooltip"data-placement="top"title="{{row.entity.isClosed?\'Locked\':\'\'}} {{row.entity.consolidated?\'Consolidated\':\'\'}}"></span>'},
                { field: 'consolidated', 
                    width: 70,
                    displayName: $translate.instant('autopsApp.guide.consolidated'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.consolidated"class="label label-primary" ng-click="grid.appScope.setConsolidated(row.entity)"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.consolidated"class="label label-success" ng-click="grid.appScope.setConsolidated(row.entity)"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'checkStatus', 
                    displayName: $translate.instant('autopsApp.guide.checkStatus'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CHECK_STATUS
                    },
                    cellTemplate: '<span class="grid-text" ng-if="row.entity.checkStatus"><span class="label label-info" ng-if="row.entity.checkStatus == \'notChecked\'" translate="autopsApp.guide.sts.notChecked">status</span><span class="label label-success" ng-if="row.entity.checkStatus == \'approved\'" translate="autopsApp.guide.sts.approved">status</span><span class="label label-danger" ng-if="row.entity.checkStatus == \'unapproved\'" translate="autopsApp.guide.sts.unapproved">status</span></span>'},
                { field: 'onHold', 
                    displayName: $translate.instant('autopsApp.guide.onHold'), 
                    width: 50,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5" ng-click="grid.appScope.consoleOnHoldShipmentPop(row.entity)"><span ng-if="row.entity.onHold"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.onHold"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'soNum', 
                    displayName: 'SO#',
                    visible: false},
                { field: 'poNum', 
                    displayName: 'PO#',
                    visible: false},
                { field: 'mblNum',
                    visible: false,
                    cellTemplate: '<span class="ml-5">{{row.entity.mblNum}}<span class="glyphicon glyphicon-ok-circle ml-5 text-primary"data-toggle="tooltip"data-placement="top"title="MBL released: {{row.entity.mblReleasedTime | date:\'medium\'}}"ng-if="row.entity.mblReleasedTime"></span></span>',
                    displayName: 'MBL/Mawb#'},
                { field: 'hblNum',
                    visible: false,
                    cellTemplate: '<span class="ml-5">{{row.entity.hblNum}}<span class="glyphicon glyphicon-ok-circle ml-5 text-primary"data-toggle="tooltip"data-placement="top"title="HBL released: {{row.entity.hblReleasedTime | date:\'medium\'}}"ng-if="row.entity.hblReleasedTime"></span></span>',
                    displayName: 'HBL/Hawb#'},
                { field: 'refNum', 
                    displayName: 'EDI Ref#',
                    visible: false},
                { field: 'thirdpartyPlatformRef', 
                    displayName: $translate.instant('autopsApp.guide.thirdpartyPlatformRef'),
                    visible: false},
                { field: 'clientSoNum', 
                    displayName: $translate.instant('autopsApp.guide.clientWmsInNum'),
                    visible: false},
                { field: 'gw', 
                    width: 70,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'GW', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'vol', 
                    width: 70,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'VOL', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'pkgNum', 
                    width: 70,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'PKG', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'gwForecast', 
                    width: 70,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'GW(E)', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'volForecast', 
                    width: 70,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'VOL(E)', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'pkgNumForecast', 
                    width: 70,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'PKG(E)', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'pallets', 
                    width: 70,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'PLT', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum}, 
                { field: 'shipmentType', 
                    displayName: 'TYPE',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.FMS_TYPES
                    },  
                    cellTemplate: '<span ng-if="row.entity.shipmentType" class="label label-info ml-5" translate="global.{{row.entity.shipmentType}}"></span>'},
                { field: 'deliveryTo', 
                    displayName: $translate.instant('autopsApp.guide.deliveryTo')},
                { field: 'shiptoCode', 
                    displayName: $translate.instant('autopsApp.guide.shipToParty')},                    
                { field: 'destName', 
                    displayName: $translate.instant('autopsApp.guide.destName')},
                { field: 'shiptoCountryCode', 
                    displayName: $translate.instant('autopsApp.guide.destCountryCode')}, 
                { field: 'shiptoZipCode', 
                    displayName: $translate.instant('autopsApp.guide.destPostCode')}, 
                { field: 'polName', 
                    displayName: $translate.instant('autopsApp.guide.polName')}, 
                { field: 'warehouseName', 
                    displayName: $translate.instant('autopsApp.guide.warehouse')}, 
                { field: 'cargoType', 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CARGOTYPES
                    },  
                    displayName: $translate.instant('autopsApp.guide.cargoType'),
                    cellTemplate: '<span class="label label-info ml-5">{{row.entity.cargoTypeLabel}}</span>'},
                { field: 'createdTime', 
                    displayName: $translate.instant('autopsApp.guide.home.createdTime'),
                    cellTemplate: '<span data-toggle="tooltip" data-placement="top" title="{{row.entity.createdTime | date:\'medium\'}}" class="ml-5">{{row.entity.createdTime | date:\'mediumDate\'}}</span>'},
                { field: 'cargoReadyDate', 
                    displayName: $translate.instant('autopsApp.guide.cargoReadyDate')},
                { field: 'bookingEtd', 
                    displayName: $translate.instant('autopsApp.guide.bookingEtd')},
                { field: 'wmsInTime', 
                    displayName: $translate.instant('autopsApp.guide.wmsInTime'),
                    cellTemplate: '<span data-toggle="tooltip" data-placement="top" title="{{row.entity.wmsInTime | date:\'medium\'}}" class="ml-5">{{row.entity.wmsInTime | date:\'mediumDate\'}}</span>'},
                { field: 'etd', 
                    displayName: 'ETD'},
                { field: 'customerName', 
                    displayName: $translate.instant('autopsApp.guide.bookingPartyName')},
                { field: 'shipperName', 
                    displayName: $translate.instant('autopsApp.guide.shipperName')},
                { field: 'productName', 
                    displayName: $translate.instant('autopsApp.guide.productName')},
                { field: 'deliveryChannel', 
                    displayName: $translate.instant('autopsApp.guide.deliveryChannel')},
                { field: 'salesName', 
                    displayName: $translate.instant('autopsApp.guide.salesName')},
                { field: 'opName', 
                    displayName: $translate.instant('autopsApp.guide.opName')},
                { field: 'docName', 
                    displayName: $translate.instant('autopsApp.guide.docName')},
                { field: 'cusName', 
                    displayName: $translate.instant('autopsApp.guide.cusName')},
                { field: 'vsl', 
                    displayName: 'Vessel'},
                { field: 'voy', 
                    displayName: 'Voy/Flight'},
                { field: 'porName', 
                    displayName: $translate.instant('autopsApp.guide.porName')},
                { field: 'memo', 
                    displayName: $translate.instant('autopsApp.guide.table.memo')},
                { field: 'cargoName', 
                    displayName: $translate.instant('autopsApp.guide.cargoName')},
                { field: 'innerRemark', 
                    displayName: $translate.instant('autopsApp.guide.remarks')},
                { field: 'bookingRemark', 
                    displayName: $translate.instant('autopsApp.booking.remarks')},
                { field: 'ownImporter', 
                    displayName: $translate.instant('autopsApp.guide.ownImporter'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.ownImporter"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.ownImporter"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'plConfirmed', 
                    displayName: $translate.instant('autopsApp.booking.plConfirmed'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.plConfirmed"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.plConfirmed"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'customsType', 
                    displayName: $translate.instant('autopsApp.guide.customsType')},
                { field: 'bizType',
                    displayName: $translate.instant('autopsApp.guide.bizType'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.BIZTYPES
                    },
                    cellTemplate: '<span class="grid-text">{{row.entity.bizTypeLabel}}</span>'},
                { field: 'internalType',
                    displayName: $translate.instant('autopsApp.guide.bizInternalType'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.INTERNALTYPES
                    },
                    cellTemplate: '<span class="grid-text">{{row.entity.internalTypeLabel}}</span>'},
                { field: 'deliveryWay', 
                    displayName: $translate.instant('autopsApp.booking.deliveryWay'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.DELIVERYTYPES
                    },       
                    minWidth:100,
                    cellTemplate: '<span class="ml-5" translate="autopsApp.booking.deliveryType.{{row.entity.deliveryWay}}" ng-if="row.entity.deliveryWay" class="label-info label"></span>'}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj_2.gridState = angular.toJson($scope.gridApi_2.saveState.save());
                    gridStateObj_2.userId = $rootScope.account.id;
                    gridStateObj_2.gridId = 100;
                    GridState.update(gridStateObj_2, function(result){
                        gridStateObj_2 = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj_2.gridState){
                        $scope.gridApi_2.saveState.restore($scope, angular.fromJson(gridStateObj_2.gridState));
                    }       
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi_2.saveState.restore($scope, gridStateCopy_2);                   
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions_2);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi_2.core.getVisibleRows($scope.gridApi_2.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi_2.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "consolidatedList";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions_2.exporterExcelFilename);                   
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions_2;
                    jsonObj.saveState = $scope.gridApi_2.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "consolidatedList";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions_2.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi_2 = gridApi;
            }
        }


        $scope.gridOptions_3 = {
            enableSorting: true,//排序
            useExternalSorting: false,      

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤           

            enableRowHeaderSelection : true,  //行选中
            enableRowSelection: true, //行选中
            enableSelectAll: false, //全部选中
            enableFullRowSelection : false, //行任意位置选中
            multiSelect: false,//多行选中

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: 'unchoosed.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'], 
            minimumColumnSize: 80,
            columnDefs: [
                { field: 'jobNum', 
                    displayName: 'Job#',
                    width: 150,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<span class="ml-5"><a href=""ng-click="grid.appScope.BIZPOP(row.entity.guideId)"ng-if="row.entity.guideId">{{row.entity.jobNum}}</a><a href=""ng-click="grid.appScope.VIEWEXPRESS(row.entity.parcelId)"ng-if="row.entity.parcelId">{{row.entity.jobNum}}</a><span class="glyphicon glyphicon-ok-circle ml-5 text-info"ng-if="row.entity.id==grid.appScope.choosedId"></span></span>'},
                { field: 'consolidated', 
                    width: 70,
                    displayName: $translate.instant('autopsApp.guide.consolidated'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.consolidated"class="label label-primary" ng-click="grid.appScope.setConsolidated(row.entity)"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.consolidated"class="label label-success" ng-click="grid.appScope.setConsolidated(row.entity)"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'checkStatus', 
                    displayName: $translate.instant('autopsApp.guide.checkStatus'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CHECK_STATUS
                    },
                    cellTemplate: '<span class="grid-text" ng-if="row.entity.checkStatus"><span class="label label-info" ng-if="row.entity.checkStatus == \'notChecked\'" translate="autopsApp.guide.sts.notChecked">status</span><span class="label label-success" ng-if="row.entity.checkStatus == \'approved\'" translate="autopsApp.guide.sts.approved">status</span><span class="label label-danger" ng-if="row.entity.checkStatus == \'unapproved\'" translate="autopsApp.guide.sts.unapproved">status</span></span>'},
                { field: 'onHold', 
                    displayName: $translate.instant('autopsApp.guide.onHold'), 
                    width: 50,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5" ng-click="grid.appScope.consoleOnHoldShipmentPop(row.entity)"><span ng-if="row.entity.onHold"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.onHold"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'volumes', 
                    displayName: $translate.instant('autopsApp.guide.volumes')},
                { field: 'utilization', 
                    type: 'number', 
                    displayName: $translate.instant('autopsApp.guide.utilization'),
                    aggregationType: uiGridConstants.aggregationTypes.avg,
                    cellTemplate: '<strong class="ml-5 text-danger" ng-if="row.entity.utilization">{{row.entity.utilization}}%</strong>'},                
                { field: 'soNum', 
                    displayName: 'SO#',
                    visible: false},
                { field: 'poNum', 
                    displayName: 'PO#',
                    visible: false},
                { field: 'mblNum',
                    visible: false,
                    cellTemplate: '<span class="ml-5">{{row.entity.mblNum}}<span class="glyphicon glyphicon-ok-circle ml-5 text-primary"data-toggle="tooltip"data-placement="top"title="MBL released: {{row.entity.mblReleasedTime | date:\'medium\'}}"ng-if="row.entity.mblReleasedTime"></span></span>',
                    displayName: 'MBL/Mawb#'},
                { field: 'hblNum',
                    visible: false,
                    cellTemplate: '<span class="ml-5">{{row.entity.hblNum}}<span class="glyphicon glyphicon-ok-circle ml-5 text-primary"data-toggle="tooltip"data-placement="top"title="HBL released: {{row.entity.hblReleasedTime | date:\'medium\'}}"ng-if="row.entity.hblReleasedTime"></span></span>',
                    displayName: 'HBL/Hawb#'},
                { field: 'refNum', 
                    displayName: 'EDI Ref#',
                    visible: false},
                { field: 'thirdpartyPlatformRef', 
                    displayName: $translate.instant('autopsApp.guide.thirdpartyPlatformRef'),
                    visible: false},
                { field: 'clientSoNum', 
                    displayName: $translate.instant('autopsApp.guide.clientWmsInNum'),
                    visible: false},
                { field: 'gw', 
                    width: 100,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'GW', 
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'vol', 
                    width: 100,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'VOL', 
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'pkgNum', 
                    width: 100,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'PKGS', 
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'pallets', 
                    width: 100,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'Pallets', 
                    aggregationType: uiGridConstants.aggregationTypes.sum}, 
                { field: 'shipmentType', 
                    displayName: 'TYPE',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.FMS_TYPES
                    },  
                    cellTemplate: '<span ng-if="row.entity.shipmentType" class="label label-info ml-5" translate="global.{{row.entity.shipmentType}}"></span>'},
                { field: 'deliveryTo', 
                    displayName: $translate.instant('autopsApp.guide.deliveryTo')},
                { field: 'shiptoCode', 
                    displayName: $translate.instant('autopsApp.guide.shipToParty')},                    
                { field: 'destName', 
                    displayName: $translate.instant('autopsApp.guide.destName')},
                { field: 'shiptoCountryCode', 
                    displayName: $translate.instant('autopsApp.guide.destCountryCode')}, 
                { field: 'shiptoZipCode', 
                    displayName: $translate.instant('autopsApp.guide.destPostCode')}, 
                { field: 'polName', 
                    displayName: $translate.instant('autopsApp.guide.polName')}, 
                { field: 'warehouseName', 
                    displayName: $translate.instant('autopsApp.guide.warehouse')}, 
                { field: 'cargoType', 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CARGOTYPES
                    },  
                    displayName: $translate.instant('autopsApp.guide.cargoType'),
                    cellTemplate: '<span class="label label-info ml-5">{{row.entity.cargoTypeLabel}}</span>'},
                { field: 'createdTime', 
                    displayName: $translate.instant('autopsApp.guide.home.createdTime'),
                    cellTemplate: '<span data-toggle="tooltip" data-placement="top" title="{{row.entity.createdTime | date:\'medium\'}}" class="ml-5">{{row.entity.createdTime | date:\'mediumDate\'}}</span>'},
                { field: 'cargoReadyDate', 
                    displayName: $translate.instant('autopsApp.guide.cargoReadyDate')},
                { field: 'bookingEtd', 
                    displayName: $translate.instant('autopsApp.guide.bookingEtd')},
                { field: 'wmsInTime', 
                    displayName: $translate.instant('autopsApp.guide.wmsInTime'),
                    cellTemplate: '<span data-toggle="tooltip" data-placement="top" title="{{row.entity.wmsInTime | date:\'medium\'}}" class="ml-5">{{row.entity.wmsInTime | date:\'mediumDate\'}}</span>'},
                { field: 'etd', 
                    displayName: 'ETD'},
                { field: 'customerName', 
                    displayName: $translate.instant('autopsApp.guide.bookingPartyName')},
                { field: 'shipperName', 
                    displayName: $translate.instant('autopsApp.guide.shipperName')},
                { field: 'productName', 
                    displayName: $translate.instant('autopsApp.guide.productName')},
                { field: 'deliveryChannel', 
                    displayName: $translate.instant('autopsApp.guide.deliveryChannel')},
                { field: 'salesName', 
                    displayName: $translate.instant('autopsApp.guide.salesName')},
                { field: 'opName', 
                    displayName: $translate.instant('autopsApp.guide.opName')},
                { field: 'docName', 
                    displayName: $translate.instant('autopsApp.guide.docName')},
                { field: 'cusName', 
                    displayName: $translate.instant('autopsApp.guide.cusName')},
                { field: 'vsl', 
                    displayName: 'Vessel'},
                { field: 'voy', 
                    displayName: 'Voy/Flight'},
                { field: 'porName', 
                    displayName: $translate.instant('autopsApp.guide.porName')},
                { field: 'memo', 
                    displayName: $translate.instant('autopsApp.guide.table.memo')},
                { field: 'cargoName', 
                    displayName: $translate.instant('autopsApp.guide.cargoName')},
                { field: 'innerRemark', 
                    displayName: $translate.instant('autopsApp.guide.remarks')},
                { field: 'bookingRemark', 
                    displayName: $translate.instant('autopsApp.booking.remarks')},
                { field: 'ownImporter', 
                    displayName: $translate.instant('autopsApp.guide.ownImporter'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.ownImporter"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.ownImporter"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'plConfirmed', 
                    displayName: $translate.instant('autopsApp.booking.plConfirmed'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.plConfirmed"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.plConfirmed"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'customsType', 
                    displayName: $translate.instant('autopsApp.guide.customsType')},
                { field: 'bizType',
                    displayName: $translate.instant('autopsApp.guide.bizType'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.BIZTYPES
                    },
                    cellTemplate: '<span class="grid-text">{{row.entity.bizTypeLabel}}</span>'},
                { field: 'internalType',
                    displayName: $translate.instant('autopsApp.guide.bizInternalType'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.INTERNALTYPES
                    },
                    cellTemplate: '<span class="grid-text">{{row.entity.internalTypeLabel}}</span>'},
                { field: 'deliveryWay', 
                    displayName: $translate.instant('autopsApp.booking.deliveryWay'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.DELIVERYTYPES
                    },       
                    minWidth:100,
                    cellTemplate: '<span class="ml-5" translate="autopsApp.booking.deliveryType.{{row.entity.deliveryWay}}" ng-if="row.entity.deliveryWay" class="label-info label"></span>'}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj_3.gridState = angular.toJson($scope.gridApi_3.saveState.save());
                    gridStateObj_3.userId = $rootScope.account.id;
                    gridStateObj_3.gridId = 101;
                    GridState.update(gridStateObj_3, function(result){
                        gridStateObj_3 = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj_3.gridState){
                        $scope.gridApi_3.saveState.restore($scope, angular.fromJson(gridStateObj_3.gridState));
                    }       
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi_3.saveState.restore($scope, gridStateCopy_3);                   
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions_3);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi_3.core.getVisibleRows($scope.gridApi_3.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi_3.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "consolidatedList";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions_3.exporterExcelFilename);                   
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions_3;
                    jsonObj.saveState = $scope.gridApi_3.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "consolidatedList";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions_3.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi_3 = gridApi;
                gridApi.selection.on.rowSelectionChanged($scope,function(row){
                    if(row.isSelected){
                        chooseMbl(row.entity);
                    }
                    else{
                        $scope.choosedId = null;
                        $scope.gridOptions_2.data = [];
                    }
                });
            }
        }

        var gridStateObj_1 = {};
        var gridStateCopy_1 = null;
        var gridStateObj_2 = {};
        var gridStateCopy_2 = null;
        var gridStateObj_3 = {};
        var gridStateCopy_3 = null;
        $timeout(function (){
            gridStateCopy_1 = $scope.gridApi_1.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 99
            }, function(result){
                gridStateObj_1 = result;
                if(!gridStateObj_1 || !gridStateObj_1.id){
                    $scope.gridApi_1.saveState.restore($scope, gridStateCopy_1);
                    return;
                }   
                $scope.gridApi_1.saveState.restore($scope, angular.fromJson(gridStateObj_1.gridState));
            })

            gridStateCopy_2 = $scope.gridApi_2.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 100
            }, function(result){
                gridStateObj_2 = result;
                if(!gridStateObj_2 || !gridStateObj_2.id){
                    $scope.gridApi_2.saveState.restore($scope, gridStateCopy_2);
                    return;
                }   
                $scope.gridApi_2.saveState.restore($scope, angular.fromJson(gridStateObj_2.gridState));
            })
            gridStateCopy_3 = $scope.gridApi_3.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 101
            }, function(result){
                gridStateObj_3 = result;
                if(!gridStateObj_1 || !gridStateObj_3.id){
                    $scope.gridApi_3.saveState.restore($scope, gridStateCopy_3);
                    return;
                }   
                $scope.gridApi_3.saveState.restore($scope, angular.fromJson(gridStateObj_3.gridState));
            })
            
        });

    }    
})();