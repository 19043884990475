(function() {
    'use strict';

    angular
        .module('autopsApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here       
            'ui.grid',     
            'ui.grid.saveState',//\ 158 159 自动对账 160  子系统 全部订单 161 162parcl 子系统统计 163 打板 165 子系统跟踪报表 166  168
            'ui.grid.selection',
            'ui.grid.moveColumns',
            'ui.grid.resizeColumns',
            'ui.grid.exporter',
            'ui.grid.pinning',
            'ui.grid.grouping',
            'ui.grid.cellNav', 
            'ui.grid.autoResize',  
            'ui.grid.edit',          
            'ui.grid.pagination',
            'angular-loading-bar',
            'ngDraggable'
        ])
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler'];

    function run(stateHandler, translationHandler) {
        stateHandler.initialize();
        translationHandler.initialize();
    }
})();