(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('EdiDataDialogController', EdiDataDialogController);

    EdiDataDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$translate', '$uibModalInstance', '$http',
    'OpsCarrier', 'EdiData', 'OpsPorts', 'entity', 'GuideFclCtn',
    'Guide', 'GuideCtns', 'OpsReport', 'ReportTemplate', 'DataUtils'];

    function EdiDataDialogController($timeout, $scope, $rootScope, $translate, $uibModalInstance, $http,
        OpsCarrier, EdiData, OpsPorts, entity, GuideFclCtn,
       Guide, GuideCtns, OpsReport, ReportTemplate, DataUtils) {
        var vm = this;
        $scope.isSaving = false;

        $scope.guide = angular.copy(entity.guide);
        $scope.ediData = {};
        var guideCopy;
        var ediDataCopy;
        var refreshRequired = false;

        $timeout(function (){            
            EdiData.getByGuideId({guideId: $scope.guide.id}, function(result){
                $scope.ediData = result;
                initEdiDate();
            })
        });

        var initEdiDate = function() {
            if (!$scope.ediData){
                $scope.ediData = {};
            }
            if (!$scope.ediData.shCity){
                $scope.ediData.shCity = $scope.guide.polName;
            }
            if (!$scope.ediData.shCountryCode && $scope.guide.polId){
                $scope.ediData.shCountryCode = $scope.guide.polId.substring(0,2);
            }
            if (!$scope.ediData.cnCity){
                $scope.ediData.cnCity = $scope.guide.destName;
            }
            if (!$scope.ediData.cnCountryCode && $scope.guide.destId){
                $scope.ediData.cnCountryCode = $scope.guide.destId.substring(0,2);
            }
            if (!$scope.guide.contractCargoName && $scope.guide.goodsDiscription){
                $scope.guide.contractCargoName = $scope.guide.goodsDiscription.substring(0,60);
            }
            if (!$scope.guide.contractCargoName && $scope.guide.cargoName){
                $scope.guide.contractCargoName = $scope.guide.cargoName.substring(0,60);
            }

            if (!$scope.ediData.shName && $scope.guide.guideShipperBl){
                var strs = $scope.guide.guideShipperBl.split("\n");
                $scope.ediData.shName = strs[0];                
                if (strs.length > 1 && !$scope.ediData.shAddress){
                    $scope.ediData.shAddress = "";
                    for (var i = 1; i < strs.length; i++){
                        $scope.ediData.shAddress = $scope.ediData.shAddress + strs[i] + "\n";                        
                    }
                }
            }
            if (!$scope.ediData.cnName && $scope.guide.deliveryBl){
                var strs = $scope.guide.deliveryBl.split("\n");
                $scope.ediData.cnName = strs[0];                
                if (strs.length > 1 && !$scope.ediData.cnAddress){
                    $scope.ediData.cnAddress = "";
                    for (var i = 1; i < strs.length; i++){
                        $scope.ediData.cnAddress = $scope.ediData.cnAddress + strs[i] + "\n";                        
                    }
                }
            }
            // if (!$scope.ediData.npName && $scope.guide.guideNotifyBl){
            //     var strs = $scope.guide.guideNotifyBl.split("\n");
            //     $scope.ediData.npName = strs[0];                
            //     if (strs.length > 1 && !$scope.ediData.npAddress){
            //         $scope.ediData.npAddress = "";
            //         for (var i = 1; i < strs.length; i++){
            //             $scope.ediData.npAddress = $scope.ediData.npAddress + strs[i] + "\n";                        
            //         }
            //     }
            // }
            if ($scope.guide.booking.hsCode && $scope.guide.booking.hsCode.length > 6){
                $scope.guide.booking.hsCode =$scope.guide.booking.hsCode.replace(/[^0-9]*/g,"");
                $scope.guide.booking.hsCode = $scope.guide.booking.hsCode.substring(0, 6);
            }
            $http.get('i18n/csPortsData.json').success(function(result) {
                $scope.portList = result.ports;
                var pol, pod, por, dest;
                angular.forEach($scope.portList, function(data){
                    if(data.code == $scope.guide.porId){
                        por = true;
                    }
                    if(data.code == $scope.guide.polId){
                        pol = true;
                    }
                    if(data.code == $scope.guide.podId){
                        pod = true;
                    }
                    if(data.code == $scope.guide.destId){
                        dest = true;
                    }
                })
                if(!por){
                    $scope.guide.porId = null;
                    $scope.guide.porName = null;
                }
                if(!pol){
                    $scope.guide.polId = null;
                    $scope.guide.polName = null;
                }
                if(!pod){
                    $scope.guide.podId = null;
                    $scope.guide.podName = null;
                }
                if(!dest){
                    $scope.guide.destId = null;
                    $scope.guide.destName = null;
                }
            });

            guideCopy = angular.copy($scope.guide);
            ediDataCopy = angular.copy($scope.ediData);
            
            $scope.updatePkgsAndType();
            $scope.updateGwVol();
            angular.forEach($scope.guide.guideFclCtns, function(data){
                if (data.ctnType != "20GP" && data.ctnType != "40GP" && data.ctnType != "40HQ" && data.ctnType != "45HQ"){
                    data.ctnType = null;
                }
            });
            $scope.uppperCase();
            if (!$scope.ediData.carrier){
                $scope.ediData.carrier = $scope.guide.carrier;
            }
            if (!$scope.ediData.carrierCode){
                $scope.ediData.carrierCode = $scope.guide.booking.carrierCode;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg("Data updated according EDI criterion, pls check carefully before save.");
            };    
        }

        $scope.onCarrierSelected = function(carrier) {       
            $scope.ediData.carrierCode = carrier.code;
        }

        $scope.clear = function () {
            $uibModalInstance.close(refreshRequired);
        }

        var brUpdForbidden = function(code) {
            if (code != "OOLU" && code != "YMLU" && code != "EGLV" && code != "KKLU" 
                && code != "WHLC" && code != "MAEU" && code != "SAFM" && code != "APLU"
                 && code != "CMDU" && code != "MSCU" && code != "SMLU"){
                return true;
            }
            return false;
        }

        $scope.cargoSmartBr = function(type) {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if (!$scope.guide.porId){
                layer.msg('The code of Place of Receipt is null');
                return;
            }
            if (!$scope.guide.polId){
                layer.msg('The code of Port of Load is null');
                return;
            }
            if (!$scope.guide.podId){
                layer.msg('The code of Port of Discharge is null');
                return;
            }
            if (!$scope.guide.destId){
                layer.msg('The code of Final destination is null');
                return;
            }
            if (!$scope.ediData.carrierCode){
                layer.msg('The code of carrier is null');
                return;
            }
            if (type == 'UPD' && brUpdForbidden($scope.ediData.carrierCode)){
                layer.msg($translate.instant('autopsApp.guide.brUpdForbidden'));
                return;
            }
            $scope.isSaving = true;
            $scope.ediData.brActionType = type;
            $scope.ediData.brUpdatedTime = new Date();
            var ediDataDTO = {};
            ediDataDTO.guide = $scope.guide;
            ediDataDTO.ediData = $scope.ediData;
       
            EdiData.cargoSmartBr(ediDataDTO, onSendSuccess, onSendError);     
        }

        var onSendSuccess = function(result) {
            $scope.isSaving = false;
            $uibModalInstance.close(refreshRequired);
            layer.msg($translate.instant('global.send_success'));
        };
        var onSendError = function(result) {
            $scope.ediData.brActionType = ediDataCopy.brActionType;
            $scope.isSaving = false;
            if (result.status == 403){
                $rootScope.OPS_ALERT($translate.instant('global.chineseContainedAlert'));
            }
            else if (result.status == 406){                
                layer.msg($translate.instant('autopsApp.guide.notAvailableAlert'));
            } else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        };
        $scope.save = function() {
            $scope.isSaving = true;
            $scope.ediData.guideId = $scope.guide.id;
            if (!angular.equals($scope.ediData, ediDataCopy)){
                if ($scope.ediData.id !== null) {
                    EdiData.update($scope.ediData, onSaveEdiSuccess, onSaveError);
                } else {
                    EdiData.save($scope.ediData, onSaveEdiSuccess, onSaveError);
                }
            }
            else {
                if (angular.equals($scope.guide, guideCopy)) {
                    $scope.isSaving = false;
                    layer.msg($translate.instant('global.no_amendmend'));
                    return;
                };
                Guide.update($scope.guide, onSaveGuideSuccess, onSaveError); 
            }
        };

        var onSaveEdiSuccess = function(result) {
            $scope.ediData = result;
            ediDataCopy = angular.copy($scope.ediData); 
            if (angular.equals($scope.guide, guideCopy)) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
                return;
            };
            Guide.update($scope.guide, onSaveGuideSuccess, onSaveError);  
        };

        var onSaveGuideSuccess = function(result) {
            refreshRequired = true;
            $scope.isSaving = false;
            $scope.guide = result;     
            guideCopy = angular.copy($scope.guide);       
            layer.msg($translate.instant('global.messages.submitted'));
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
            if (result.status == 403){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.lockedAlert'));
            }
            else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        };

        $scope.portList = new Array();
        $scope.loadPorts = function() {
            if ($rootScope.OPSPORTS.airports && $rootScope.OPSPORTS.airports.length > 0){
                return;
            }
            OpsPorts.getAll(function(result){
                $rootScope.OPSPORTS.airports = [];
                $rootScope.OPSPORTS.seaports = [];
                angular.forEach(result, function(data){
                    if(data.type == 1){
                        $rootScope.OPSPORTS.seaports.push(data);
                    }
                    else {
                        $rootScope.OPSPORTS.airports.push(data);
                    }
                })
                if ($scope.guide.shipmentType == "AIR" || $scope.guide.shipmentType == "EXPRESS") {
                    $scope.portList = $rootScope.OPSPORTS.airports;         
                } else {
                    $scope.portList = $rootScope.OPSPORTS.seaports;
                }
            })
        };

        $scope._selected = function($item, which) {
            switch (which) {
                case "gporChoose":
                    $scope.guide.porId = $item.code;
                    break;
                case "gpolChoose":
                    $scope.guide.polId = $item.code;
                    break;
                case "gpodChoose":
                    $scope.guide.podId = $item.code;
                    break;
                case "gdestChoose":
                    $scope.guide.destId = $item.code;
                    break;
                case "bpCityChoose":
                    $scope.ediData.bpCountryCode = $item.code.substring(0, 2);
                    break;
                case "shCityChoose":
                    $scope.ediData.shCountryCode = $item.code.substring(0, 2);
                    break;
                case "cnCityChoose":
                    $scope.ediData.cnCountryCode = $item.code.substring(0, 2);
                    break;
            }
        }

        $scope.uppperCase = function() {           
            $scope.ediData.shName = angular.uppercase(DataUtils.checkStrLen($scope.ediData.shName, 35));
            $scope.ediData.shAddress = angular.uppercase(DataUtils.checkStrLen($scope.ediData.shAddress, 35));
            $scope.ediData.cnName = angular.uppercase(DataUtils.checkStrLen($scope.ediData.cnName, 35));
            $scope.ediData.cnAddress = angular.uppercase(DataUtils.checkStrLen($scope.ediData.cnAddress, 35));
            $scope.ediData.bpName = angular.uppercase(DataUtils.checkStrLen($scope.ediData.bpName, 35));
            $scope.ediData.bpAddress = angular.uppercase(DataUtils.checkStrLen($scope.ediData.bpAddress, 35));
        };
        $scope.uppercaseVsl = function(str) {
            $scope.guide.vsl = angular.uppercase(str);
        }
        $scope.uppercaseVoy = function(str) {
            $scope.guide.voy = angular.uppercase(str);
        }
        $scope.datePickerForEtd = {};
        $scope.datePickerForEtd.status = {
            opened: false
        };
        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };

        $scope.addCtn = function() {
            $scope.guide.guideFclCtns.push({});
        };
        $scope.updatePkgsAndType = function() {
            if ($scope.guide.pkgNum && $scope.guide.booking.pkgType) {
                $scope.guide.booking.pkgsAndType = $scope.guide.pkgNum.toString() + " " + $scope.guide.booking.pkgType;
                $scope.guide.pkgsAndType = $scope.guide.pkgNum.toString() + " " + $scope.guide.booking.pkgType;
            }
        };

        $scope.deleteCtn = function(bookingCtn) {
            if($scope.guide.isClosed){return;}
            if (bookingCtn.id != null) {
                GuideFclCtn.delete({
                    id: bookingCtn.id
                }, function(){
                    var index = $scope.guide.guideFclCtns.indexOf(bookingCtn);
                    if (index > -1) {
                        $scope.guide.guideFclCtns.splice(index, 1);
                    };
                }, function(result){
                    if(result.status == 409){
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.cargoIsOutAlert'), {icon:0});                        
                    }
                    else {
                        layer.msg($translate.instant('global.delete_fail'));
                    }
                });
            }
            else {
                var index = $scope.guide.guideFclCtns.indexOf(bookingCtn);
                if (index > -1) {
                    $scope.guide.guideFclCtns.splice(index, 1);
                };
            }
        };

        $scope.updateGwVol = function() {
            if ($scope.guide.gw && $scope.guide.vol) {
                $scope.guide.gwVol = $scope.guide.gw.toFixed(2) + "KGS" + "\n" + $scope.guide.vol.toFixed(3) + "CBM";
                $scope.guide.booking.gwVol = $scope.guide.gw.toFixed(2) + "KGS" + "\n" + $scope.guide.vol.toFixed(3) + "CBM";
            } else if ($scope.guide.vol) {
                $scope.guide.gwVol = $scope.guide.vol.toFixed(3) + "CBM";
                $scope.guide.booking.gwVol = $scope.guide.vol.toFixed(3) + "CBM";

            } else if ($scope.guide.gw) {
                $scope.guide.gwVol = $scope.guide.gw.toFixed(2) + "KGS";
                $scope.guide.booking.gwVol = $scope.guide.gw.toFixed(2) + "KGS";
            }
            if ($scope.guide.booking.divVolBy){
                $scope.guide.chargeWeight = $scope.guide.vol * 1000000 / $scope.guide.booking.divVolBy;
            }
            if($scope.guide.chargeWeight < $scope.guide.gw){
                $scope.guide.chargeWeight = $scope.guide.gw;
            }
            if ($scope.guide.chargeWeight){
                $scope.guide.chargeWeight = $scope.guide.chargeWeight.toFixed(2) - 0;
                $scope.guide.awbWeight = $scope.guide.chargeWeight.toFixed(2) - 0;
            }   
        };

        $scope.checkRemarksLen = function() {       
            $scope.ediData.siRemarks = DataUtils.checkStrLen($scope.ediData.siRemarks, 240);
        }

    }
})();
