(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountAssistBalanceController', AccountAssistBalanceController);

    AccountAssistBalanceController.$inject = ['$scope', '$rootScope', '$translate', '$uibModal',
    'Principal', 'VoucherContent', 'OpsFiles', 'AccountTitle', 'DataUtils', 'JhiLanguageService', 'User', 'OpsVender', 'AccountGeneral'];

    function AccountAssistBalanceController($scope, $rootScope, $translate, $uibModal,
        Principal, VoucherContent, OpsFiles, AccountTitle, DataUtils, JhiLanguageService, User, OpsVender, AccountGeneral) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        }

        $scope.isForeign = false;

        $scope.format = 1;
        $scope.reportavailalbe = false;
        $scope.fileName = "";
        var params = [];
        $scope.showType = "";
        $scope.classText = $translate.instant('autopsApp.accountTitle.class');
        var lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            lang = language;
        });

        $scope.ledgers = [];
        $scope.getLedgers = function() {
            if($scope.ledgers.length > 0){
                return;
            }
            var loadIndex = layer.load(1, {shade: 0.3});
            AccountTitle.getAll({
                type: "all"
            }, function(result) {
                layer.close(loadIndex);
                $scope.ledgers = DataUtils.generateShowLedger(result, false, lang);
            });
        }

        $scope.datePickerForEtd = {};
        $scope.datePickerForEtd.status = {
            opened: false
        };

        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };

        $scope.datePickerForCargoReadyDate = {};

        $scope.datePickerForCargoReadyDate.status = {
            opened: false
        };

        $scope.datePickerForCargoReadyDateOpen = function($event) {
            $scope.datePickerForCargoReadyDate.status.opened = true;
        };

        $scope.filterDTO = {subsidiaryType:'vender'};
        $scope.isSaving = false;
        $scope.voucherContents = [];
        var dataCopy;
        var initCContent = function() {
            $scope.voucherContents = angular.copy(dataCopy);
            if (!$scope.voucherContents || $scope.voucherContents.length ==0) {
                $scope.voucherContents = [];
                for (var j = 0; j <= 20; j++) {
                    $scope.voucherContents.push({});
                }
            }
            if($scope.showType){
                for(var i = $scope.voucherContents.length - 1; i >= 0; i--){
                    if($scope.voucherContents[i].level && $scope.voucherContents[i].level > $scope.showType){
                        $scope.voucherContents.splice(i, 1);
                    }
                }
            }
        }

        initCContent();
        $scope.getContetns = function() {
            if (!$scope.filterDTO.dateFrom || !$scope.filterDTO.dateTo) {
                layer.msg($translate.instant('autopsApp.accountTitle.nullFilterAlert'));
                return;
            }
            if ($scope.filterDTO.dateFrom > $scope.filterDTO.dateTo) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;
            }
            if(!$scope.filterDTO.vender){
                $scope.filterDTO.venderId = null;
            }
            if(!$scope.filterDTO.clerkName){
                $scope.filterDTO.clerkId = null;
            }
            if(!$scope.filterDTO.ledger){
                $scope.filterDTO.ledgerId = null;
                $scope.filterDTO.ledgerCode = null;
            }
            if($scope.filterDTO.subsidiaryType == "vender"){
                if(!$scope.filterDTO.venderId){
                    $scope.filterDTO.opsVenders = $rootScope.OPSVENDERS;
                }
            }
            else if($scope.filterDTO.subsidiaryType == "clerkName"){
                if(!$scope.filterDTO.clerkId){
                    $scope.filterDTO.clerks = $rootScope.clerks;
                }
            }
            else if($scope.filterDTO.subsidiaryType == "team"){
                if(!$scope.filterDTO.team){
                    $scope.filterDTO.teams = $rootScope.allDeps;
                }
            }
            else if($scope.filterDTO.subsidiaryType == "branch"){
                if(!$scope.filterDTO.branch){
                    $scope.filterDTO.branchs = $rootScope.allBranchs;
                }
            }
            $scope.filterDTO.companyId = $rootScope.account.companyId;
            $scope.isSaving = true;
            $scope.voucherContents = [];
            var loadIndex = layer.load(1, {shade: 0.3});
            VoucherContent.getAssistAccountBalance($scope.filterDTO, function(result) {
                layer.close(loadIndex);
                $scope.isSaving = false;
                dataCopy = result;
                if (!dataCopy || dataCopy.length == 0) {
                    layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                    return;
                }
                $scope.reportavailalbe = true;
                initCContent();
            }, function(response) {
                layer.close(loadIndex);
                if (response.status == 406){
                    layer.msg($translate.instant('autopsApp.accountTitle.lastYearNotAccountedAlert'));
                }
                $scope.isSaving = false;
                initCContent();
            });
        }
        $scope.exportAccountBalance = function() {
            if(!$scope.voucherContents){
                return;
            }

            var title = $translate.instant('global.menu.entities.assistAccountingBalance');
            var fileName = title + ".xlsx";
            OpsFiles.assistAccountBalance({
                title: title,
                notAssist: false
            }, $scope.voucherContents, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }
        $scope.fixAssistAccountBal = function() {
            layer.confirm($translate.instant('autopsApp.accountTitle.fixAssistAccountBalAlert'), {
                icon: 3,
                title: "Alert",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                layer.load(1, { shade: 0.3 });
                AccountGeneral.fixAssistAccountBal(function(result) {
                    layer.closeAll();
                    $rootScope.OPS_ALERT($translate.instant('global.update_success'), {icon: 1});
                }, function(result) {
                    layer.closeAll();
                    $rootScope.OPS_ALERT($translate.instant('global.update_fail'));
                })
            }, function() {});
        }

        $scope._onLedgerChoosed = function(data) {
            $scope.filterDTO.ledgerId = data.id;
            $scope.filterDTO.ledgerCode = data.code;
        }

        $scope.updateSettlementObj = function(item) {
            $scope.filterDTO.venderId = item.id;
        }
        $rootScope.LOADALLVENDERS();

        $scope.onClerkSelected = function(item) {
            $scope.filterDTO.clerkId = item.id;
        };
        $scope.subsidiaryTypeChange = function(item) {
            if($scope.filterDTO.subsidiaryType == "vender"){
                $rootScope.LOADALLVENDERS();
            }
            else if($scope.filterDTO.subsidiaryType == "clerkName"){
                $rootScope.loadClerks();
            }
            else if($scope.filterDTO.subsidiaryType == "team"){
                $rootScope.loadDeps();
            }
            else if($scope.filterDTO.subsidiaryType == "branch"){
                $rootScope.loadBranchs();
            }
        };

        $scope.showDetails = function(content) {
            if(!content.venderId && !content.clerkId && !content.branch && !content.team){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var filterCopy = angular.copy($scope.filterDTO);
            filterCopy.ledgerCode = content.code;
            filterCopy.venderId = content.venderId;
            filterCopy.clerkId = content.clerkId;
            filterCopy.branch = content.branch;
            filterCopy.team = content.team;
            $uibModal.open({
                templateUrl: 'app/entities/voucherContent/voucherContent-dialog.html',
                controller: 'VoucherContentDialogController',
                size: 'xxxl',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountVoucher');
                        return $translate.refresh();
                    }],
                    entity: ['VoucherContent', function(VoucherContent) {
                        return VoucherContent.getSubsidiaryAccountDetails(filterCopy).$promise;
                    }]
                }
            }).result.then(function(result) {
            }, function() {
            })
        }

    }
})();
